import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { PerfilAcessoService } from 'src/app/services/perfil-acesso.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-usuario-edicao',
  templateUrl: './usuario-edicao.component.html',
  styleUrls: ['./usuario-edicao.component.scss']
})
export class UsuarioEdicaoComponent implements OnInit {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  perfisAcesso: any[] = [];
  tipo = 0;

  get ativo() { return this.form.get('ativo')!; }
  get nomeCompleto() { return this.form.get('nomeCompleto')!; }
  get email() { return this.form.get('email')!; }
  get perfilAcessoId() { return this.form.get('perfilAcessoId')!; }
  get fornecedorCnpj() { return this.form.get('fornecedorCnpj')!; }
  get fornecedorRazaoSocial() { return this.form.get('fornecedorRazaoSocial')!; }
  get observacoes() { return this.form.get('observacoes')!; }
  get celular() { return this.form.get('celular')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private usuarioService: UsuarioService,
    private perfilAcessoService: PerfilAcessoService,
  ) {
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      ativo: new FormControl(true),
      nomeCompleto: new FormControl(
        '', [
        Validators.required,
        Validators.minLength(4)
      ]),
      email: new FormControl('', [
        Validators.email,
        Validators.required
      ]),
      perfilAcessoId: new FormControl('', [
        Validators.required
      ]),
      fornecedorCnpj: new FormControl('', []),
      fornecedorRazaoSocial: new FormControl('', []),
      observacoes: new FormControl('', []),
      celular: new FormControl('', []),
      tipo: new FormControl(1, []),
    });

    this.perfilAcessoService
      .listar({
        skip: 0,
        take: 100
      })
      .subscribe(response => {
        this.perfisAcesso = response.data!.registros;
      });
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
    this.form.reset();
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
    this.form.reset();
    this.form.setValue({
      id: registro.id,
      ativo: registro.ativo,
      nomeCompleto: registro.nomeCompleto,
      email: registro.email,
      perfilAcessoId: registro.perfilAcessoId,
      fornecedorCnpj: registro.fornecedorCnpj,
      fornecedorRazaoSocial: registro.fornecedorRazaoSocial,
      observacoes: registro.observacoes,
      celular: registro.celular,
      tipo: 1,
    });
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    const registro = cloneDeep(this.form.value);

    this.usuarioService
      .salvar(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.fechar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }
}
