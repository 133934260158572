import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pergunta-foto',
  templateUrl: './pergunta-foto.component.html',
  styleUrls: ['./pergunta-foto.component.scss']
})
export class PerguntaFotoComponent implements OnInit {

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private agendamentoService: AgendamentoService) { }

  urlUpload: string = '';

  _pergunta: any = {};
  get pergunta(): any {
    return this._pergunta;
  }
  @Input()
  set pergunta(value: any) {
    if (value !== this._pergunta) {
      this._pergunta = value;
      this._pergunta.arquivos = this._pergunta.arquivos || [];
    }
  }

  _habilitado: boolean = false;
  get habilitado(): boolean {
    return this._habilitado;
  }
  @Input()
  set habilitado(value: boolean) {
    if (value !== this._habilitado) {
      this._habilitado = value;
    }
  }

  ngOnInit(): void {
    this.atualizarUrlUpload();
  }

  onUpload(event: any) {
    for (const arquivo of event.originalEvent.body.data.arquivos) {
      this._pergunta.arquivos.push(arquivo);
    }

    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  }

  onBasicUpload() {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
  }

  atualizarUrlUpload() {
    this.urlUpload = `${environment.apiUrl}/movimentacao/agendamento/upload?PerguntaId=${this._pergunta?.id}`;
  }

  confirmarExclusao(event: Event, registro: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Deseja realmente excluir o registro?',
      header: 'Confirmação de exclusão',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        this._pergunta.arquivos.splice(this._pergunta.arquivos.indexOf(registro), 1);
      },
      reject: () => {
      }
    });
  }
}
