import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDto } from './dtos/response-dto';

@Injectable({
  providedIn: 'root'
})
export class RedeAutorizadaService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/rede-autorizada`;
  }

  listartecnicos(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/listartecnicos`, args)
      .pipe();
  }
}
