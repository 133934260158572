import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concatMap, delay, retry, retryWhen, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDto } from './dtos/response-dto';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/movimentacao/agendamento`;
  }

  listar(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/listar`, args)
      .pipe();
  }

  obter(id: number): Observable<ResponseDto<any>> {
    return this.httpClient
      .get<ResponseDto<any>>(`${this.baseUrl}/obter/${id}`)
      .pipe();
  }

  excluir(id: number): Observable<ResponseDto<any>> {
    return this.httpClient
      .delete<ResponseDto<object>>(`${this.baseUrl}/excluir/${id}`)
      .pipe();
  }

  salvar(registro: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/salvar`, registro)
      .pipe();
  }

  novo(registro: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/novo`, registro)
      .pipe();
  }

  excluirArquivo(id: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .delete<ResponseDto<any>>(`${this.baseUrl}/excluir-arquivo/${id}`)
      .pipe();
  }

  iniciar(id: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/iniciar/${id}`, {})
      .pipe();
  }

  aprovar(id: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/aprovar/${id}`, {})
      .pipe();
  }

  reprovar(id: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/reprovar/${id}`, {})
      .pipe();
  }

  enviarAprovacao(id: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/enviar-aprovacao/${id}`, {})
      .pipe();
  }

  salvarResposta(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/salvar-resposta`, args)
      .pipe(retry(3));
  }

  salvarCampo(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/salvar-campo`, args)
      .pipe(retry(3));
  }
}
