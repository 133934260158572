<div class="flex justify-content-between flex-wrap">
    <div class="my-2">
        <button pButton class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" label="Voltar"
            (click)="voltar()"></button>
    </div>
    <div class="my-2">
        <button pButton class="p-button-primary p-button-sm" icon="pi pi-save" label="Salvar"
            (click)="criarServico()"></button>
    </div>
</div>

<p-card styleClass="card-ag">
    <app-validacoes [mensagens]="validacoes"></app-validacoes>

    <form [formGroup]="form" autocomplete="off">

        <input autocomplete="false" name="hidden" type="text" class="hidden">

        <p-fieldset styleClass="field-set-sm">

            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'wrench']" class="mr-2"></fa-icon>
                    <span class="font-bold text-lg">Informações do Serviço</span>
                </div>
            </ng-template>

            <div class="p-fluid p-formgrid grid field-sm">

                <div class="field col-4">
                    <label for="descricaoServico">Descrição</label>
                    <input type="text" pInputText id="descricaoServico" formControlName="descricaoServico" autofocus
                        [maxLength]="100" />
                    <small class="ng-dirty ng-invalid"
                        *ngIf="descricaoServico.invalid && (descricaoServico.dirty || descricaoServico.touched)">
                        Preencha a Descrição do Serviço</small>
                </div>

                <div class="field col-4">
                    <label for="valorCobranca">Valor da Cobrança</label>
                    <p-inputNumber formControlName="valorCobranca" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2"></p-inputNumber>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="valorCobranca.invalid && (valorCobranca.dirty || valorCobranca.touched)">
                        Preencha o Valor de Cobrança</small>
                </div>

                <div class="field col-4">
                    <label for="tipo">Tipos de Serviços</label>
                    <p-dropdown formControlName="tipo" [options]="servicos" optionLabel="nome"
                        optionValue="id"></p-dropdown>
                    <small class="ng-dirty ng-invalid" *ngIf="tipo.invalid && (tipo.dirty || tipo.touched)">
                        Selecione o Tipo de Serviço</small>
                </div>
            </div>

        </p-fieldset>

        <div style="margin-top: 20px; margin-bottom: 20px;"></div>

        <p-fieldset styleClass="field-set-sm">

            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'building-user']" class="mr-2"></fa-icon>
                    <span class="font-bold text-lg">Cobrança Específica por Cliente</span>
                </div>
            </ng-template>

            <div class="p-fluid p-formgrid grid field-sm">

                <p-table [value]="servicoCliente" responsiveLayout="scroll" class="field col-12">

                    <ng-template pTemplate="caption">
                        <div class="flex justify-content-end flex-wrap">
                            <div>
                                <button pButton class="p-button-primary p-button-sm" icon="pi pi-plus"
                                    label="Nova Cobrança" size="small" (click)="novaCobranca('Cliente')"></button>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;">Cliente</th>
                            <th style="text-align: center;">Valor Cobrança</th>
                            <th style="width: 100px"></th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-registro>
                        <tr>
                            <td>
                                <span class="p-column-title">Cliente</span>
                                {{registro.razaoSocial}}
                            </td>
                            <td>
                                <span class="p-column-title">Valor Cobrança</span>
                                {{registro.valorCobranca}}
                            </td>

                            <!-- <td>
                                <div class="flex">
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-sm p-button-primary mr-2"
                                        (click)="abrirEdicaoContato(registro)"></button>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-sm p-button-danger "
                                        (click)="excluirContato(registro)"></button>
                                </div>
                            </td> -->
                        </tr>

                    </ng-template>

                </p-table>

            </div>

        </p-fieldset>

        <div style="margin-top: 20px; margin-bottom: 20px;"></div>

        <p-fieldset styleClass="field-set-sm">

            <ng-template pTemplate="header">

                <div class="flex align-items-center text-primary">

                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'building-user']" class="mr-2"></fa-icon>

                    <span class="font-bold text-lg">Cobrança Específica por Técnico</span>

                </div>

            </ng-template>

            <div class="p-fluid p-formgrid grid field-sm">

                <p-table [value]="servicoTecnico" responsiveLayout="scroll" class="field col-12">

                    <ng-template pTemplate="caption">

                        <div class="flex justify-content-end flex-wrap">

                            <div>

                                <button pButton class="p-button-primary p-button-sm" icon="pi pi-plus"
                                    label="Nova Cobrança" size="small" (click)="novaCobranca('Técnico')"></button>

                            </div>

                        </div>

                    </ng-template>

                    <ng-template pTemplate="header">

                        <tr>
                            <th style="text-align: center;">Técnico</th>
                            <th style="text-align: center;">Valor Cobrança</th>
                            <th style="width: 100px"></th>
                        </tr>

                    </ng-template>

                    <ng-template pTemplate="body" let-registro>
                        <tr>

                            <td>
                                <span class="p-column-title">Técnico</span>
                                {{registro.razaoSocial}}
                            </td>

                            <td>
                                <span class="p-column-title">Valor Cobrança</span>
                                {{registro.valorCobranca}}
                            </td>

                            <!-- <td>
                                <div class="flex">
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-sm p-button-primary mr-2"
                                        (click)="abrirEdicaoContato(registro)"></button>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-sm p-button-danger "
                                        (click)="excluirContato(registro)"></button>
                                </div>
                            </td> -->

                        </tr>
                    </ng-template>

                </p-table>

            </div>

        </p-fieldset>

    </form>

</p-card>

<app-servico-cobranca-edit #edicaoCobranca (onSucesso)="cobrancaAlterada($event)"></app-servico-cobranca-edit>