import { SignaturePadComponent, NgSignaturePadOptions } from '@almothafar/angular-signature-pad';
import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
declare let trimCanvas: any;

@Component({
  selector: 'app-pergunta-assinatura-popup',
  templateUrl: './pergunta-assinatura-popup.component.html',
  styleUrls: ['./pergunta-assinatura-popup.component.scss']
})
export class PerguntaAssinaturaPopupComponent {
  visivel: boolean = false;
  registro: any = {};

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  fechar() {
    this.visivel = false;
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
  }

  limpar() {
    this.signaturePad.clear();
  }

  confirmar() {
    this.visivel = false;
    trimCanvas.default(this.signaturePad.getCanvas());
    this.registro.resposta.imagemUrl = this.signaturePad.toDataURL();
    this.registro.resposta.imagem = this.registro.resposta.imagemUrl.substring(this.registro.resposta.imagemUrl.indexOf(',')+1);
    this.onSucesso.emit(this.registro.resposta.imagem);
  }

  @ViewChild('signature')
  public signaturePad!: SignaturePadComponent;

  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: window.innerWidth - 10,
    canvasHeight: window.innerHeight - 70,
    backgroundColor: '#FFFFFF'
  };

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    console.log(this.signaturePad.toDataURL());
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  onResize(event: any) {
    if (this.signaturePad) {
      this.signaturePad.set('canvasWidth', (event.target.innerWidth - 10));
      this.signaturePad.set('canvasHeight', (event.target.innerHeight - 70));
      this.signaturePad.fromData(this.signaturePad.toData())
    }
  }
}

