import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { TokenStorageService } from 'src/app/services/autorizacao/token-storage.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { AgendaService } from 'src/app/services/agenda.service';

@Component({
  selector: 'app-agendamento-novo',
  templateUrl: './agendamento-novo.component.html',
  styleUrls: ['./agendamento-novo.component.scss']
})
export class AgendamentoNovoComponent implements OnInit {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  tecnicos: any[] = [];
  clientes: any[] = [];
  usuarioId: number;
  usuarioTipo: number;
  agendamentos: any[] = [{
    id: 1,
    nome: 'Instalação'
  }, {
    id: 2,
    nome: 'Manutenção'
  }, {
    id: 3,
    nome: 'Desinstalação'
  }];

  get clienteId() { return this.form.get('clienteId')!; }
  get responsavelId() { return this.form.get('responsavelId')!; }
  get tipo() { return this.form.get('tipo')!; }
  get dataHora() { return this.form.get('dataHora')!; }
  get descricao() { return this.form.get('descricao')!; }
  get dataHoraTermino() { return this.form.get('dataHoraTermino')!; }
  get enderecoAtendimento() { return this.form.get('enderecoAtendimento')!; }
  get responsavelLocal() { return this.form.get('responsavalLocal')!; }
  get placa() { return this.form.get('placa')!; }
  get sintoma() { return this.form.get('sintoma')!; }
  get chamado() { return this.form.get('chamado')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private agendamentoService: AgendamentoService,
    private agendaService: AgendaService,
    private clienteService: ClienteService,
    private tokenStorage: TokenStorageService) {
    this.usuarioTipo = this.tokenStorage.getTokenDetalhes()?.usuarioTipo;
    this.usuarioId = this.tokenStorage.getTokenDetalhes()?.usuarioId;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      clienteId: new FormControl('', [
        Validators.required]),
      responsavelId: new FormControl('', [
        Validators.required]),
      tipo: new FormControl('', [
        Validators.required]),
      dataHora: new FormControl(),
      descricao: new FormControl(),
      dataHoraTermino: new FormControl(),
      enderecoAtendimento: new FormControl('', []),
      responsavelLocal: new FormControl('', []),
      placa: new FormControl('', []),
      sintoma: new FormControl('', []),
      chamado: new FormControl('', []),
    });
  }

  carregarDependencias() {
    this.agendaService
      .tecnicos({
        skip: 0,
        take: 1000,
        tipoUsuario: 2
      })
      .subscribe(response => {
        this.tecnicos = response.data!.registros;
      });

    this.clienteService
      .listar({
        skip: 0,
        take: 1000
      })
      .subscribe(response => {
        this.clientes = response.data!.registros;
      });
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
    this.form.reset();
  }

  abrir(registro: any) {
    this.carregarDependencias();
    this.visivel = true;
    this.registro = registro;
    this.form.reset();
    this.form.setValue({
      clienteId: registro.clienteId,
      responsavelId: this.usuarioTipo == 2 ? this.usuarioId : registro.responsavelId,
      tipo: registro.tipo ?? '',
      dataHora: registro.dataHora,
      descricao: registro.descricao,
      dataHoraTermino: registro.dataHoraTermino,
      enderecoAtendimento: registro.enderecoAtendimento ?? '',
      responsavelLocal: registro.responsavelLocal ?? '',
      placa: registro.placa ?? '',
      sintoma: registro.sintoma ?? '',
      chamado: registro.chamado ?? '',
    });
  }

  confirmar() {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control!.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.app.bloquearTela();
    this.validacoes = [];

    const registro = cloneDeep(this.form.value);

    switch (registro.tipo) {
      case 1:
        registro.tarefaId = 5;
        break;
      case 2:
        registro.tarefaId = 2;
        break;
      case 3:
        registro.tarefaId = 4;
        break;
    }

    this.agendamentoService
      .novo(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.fechar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }

  onClienteChange(event: any) {
    this.agendaService
      .tecnicos({
        skip: 0,
        take: 1000,
        clienteId: event.value
      })
      .subscribe(response => {
        this.tecnicos = response.data!.registros;
      });
  }

}