import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AgendamentoNovoComponent } from '../components/agendamentos/agendamento-novo/agendamento-novo.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {
    novoAgendamentoVisivel: boolean = false;
    validacoes: string[] = [];

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('popupNovoAgendamento') popupNovoAgendamento?: AgendamentoNovoComponent;

    constructor(
        public layoutService: LayoutService,
        private router: Router) { }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    abrirPopupNovoAgendamento() {
        this.popupNovoAgendamento?.abrir({
            clienteId: null,
            responsavelId: null,
            tarefaId: null,
            dataHora: null,
            descricao: null,
            dataHoraTermino: null,
        });
    }

    onNovo(registro: any) {
        this.router.navigate(['/agendamentos', registro.id]);
    }
}