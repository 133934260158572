import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { TokenStorageService } from 'src/app/services/autorizacao/token-storage.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    constructor(public layoutService: LayoutService,
        public tokenStorageService: TokenStorageService,
        private router: Router) { }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    sair() {
        this.visible = false;
        this.tokenStorageService.clearToken();
        this.router.navigate(['/auth/login']);
    }
}