<div class="field col-12">
    <label>{{pergunta.detalhesTitulo}}</label>

    <p-fileUpload name="demo[]" [url]="urlUpload" (onUpload)="onUpload($event)" [multiple]="true"
        accept=".pbm,.jpg,.jpeg,.gif,.tiff,.webp,.qoi,.png,.tga" [maxFileSize]="1000000" chooseLabel="Procurar imagens"
        [disabled]="!habilitado" [auto]="true">
        <ng-template pTemplate="content">
            <div class="flex overflow-hidden" *ngFor="let arquivo of pergunta.arquivos">
                <div class="flex-none flex align-items-center justify-content-center  font-bold m-2 border-round"
                    *ngIf="arquivo.arquivoThumbnail">
                    <img [src]="arquivo.arquivoThumbnail?.arquivoUrlPublica" />
                </div>
                <div class="flex-none flex align-items-center justify-content-center  font-bold m-2  border-round">
                    <a [href]="arquivo.arquivoUrlPublica" target="_blank"> {{arquivo.arquivoNome}} </a>
                    <button pButton pRipple icon="pi pi-trash" (click)="confirmarExclusao($event,arquivo)"
                        class="p-button-rounded p-button-danger p-button-sm ml-2" *ngIf="habilitado"></button>
                </div>
            </div>
        </ng-template>
    </p-fileUpload>
</div>