<p-dialog #edicao [(visible)]="visivel" [style]="{width: '50vw'}" header="Novo agendamento" [modal]="true"
    class="p-fluid" position="top">
    <ng-template pTemplate="content">
        <app-validacoes [mensagens]="validacoes"></app-validacoes>

        <form [formGroup]="form" autocomplete="off">
            <div class="grid field-sm mt-2">
                <div class="field col-12 md:col-4">
                    <label for="clienteId">Cliente</label>
                    <p-dropdown inputId="clienteId" [options]="clientes" optionValue="id" optionLabel="razaoSocial"
                        formControlName="clienteId" appendTo="body" [autoDisplayFirst]="false" [filter]="true"
                        filterBy="razaoSocial" (onChange)="onClienteChange($event)"></p-dropdown>
                </div>
                <div class="field col-12 md:col-4  " *ngIf="usuarioTipo == 1">
                    <label for="responsavelId">Responsável</label>
                    <p-dropdown inputId="responsavelId" [options]="tecnicos" optionValue="usuarioTecnicoId" optionLabel="razaoSocial"
                        formControlName="responsavelId" appendTo="body" [autoDisplayFirst]="false" [filter]="true"
                        filterBy="razaoSocial"></p-dropdown>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="tipo">Tipo Agendamento</label>
                    <p-dropdown [options]="agendamentos" optionValue="id" optionLabel="nome"
                        formControlName="tipo" appendTo="body" [autoDisplayFirst]="false"
                        [filter]="false"></p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="dataHora">Data/hora</label>
                    <p-calendar inputId="dataHora" formControlName="dataHora" [showTime]="true" [showSeconds]="false"
                        appendTo="body"></p-calendar>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="dataHoraTermino">Previsão de término</label>
                    <p-calendar inputId="dataHoraTermino" formControlName="dataHoraTermino" [showTime]="true"
                        [showSeconds]="false" appendTo="body"></p-calendar>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="enderecoAtendimento">Endereço de Atendimento</label>
                    <input type="text" pInputText inputId="enderecoAtendimento" formControlName="enderecoAtendimento"
                        appendTo="body" />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="responsavelLocal">Responsável no Local</label>
                    <input type="text" pInputText inputId="responsavelLocal" formControlName="responsavelLocal"
                        appendTo="body" />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="placa">Placa</label>
                    <input type="text" pInputText inputId="placa" formControlName="placa" appendTo="body" />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="chamado">Chamado</label>
                    <input type="text" pInputText inputId="chamado" formControlName="chamado" appendTo="body" />
                </div>
                <div class="field col-12">
                    <label for="sintoma">Sintoma</label>
                    <textarea pInputTextarea id="sintoma" formControlName="sintoma" rows="3"
                        [autoResize]="true"></textarea>
                </div>
                <div class="field col-12">
                    <label for="descricao">Descrição</label>
                    <textarea pInputTextarea formControlName="descricao" id="descricao" rows="3" [autoResize]="true">
                    </textarea>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-between flex-wrap">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-warning p-button-text"
                (click)="fechar()"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-primary p-button-text"
                (click)="confirmar()"></button>
        </div>
    </ng-template>
</p-dialog>