<p-dialog #edicao [(visible)]="visivel" [style]="{width: '50vw'}" header="Cobrança" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <app-validacoes [mensagens]="validacoes"></app-validacoes>

        <form [formGroup]="form" autocomplete="off" class="mt-2">
            <input autocomplete="false" name="hidden" type="text" class="hidden">

            <div class="p-fluid p-formgrid grid field-sm">

                <div class="field col-6">
                    <label for="pessoaId">{{registro.tipoPessoa}}</label>
                    <p-dropdown [options]="pessoas" optionValue="id" optionLabel="razaoSocial"
                        formControlName="pessoaId" appendTo="body" [autoDisplayFirst]="false"
                        [filter]="true" (onChange)="buscaRazaoSocial($event)"></p-dropdown>
                    <small class="ng-dirty ng-invalid" *ngIf="pessoaId.invalid && (pessoaId.dirty || pessoaId.touched)">
                        Selecione o {{registro.tipoPessoa}}</small>
                </div>
                <div class="field col-6">
                    <label for="valorCobranca">Valor do Serviço</label>
                    <p-inputNumber formControlName="valorCobranca" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2"></p-inputNumber>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="valorCobranca.invalid && (valorCobranca.dirty || valorCobranca.touched)">
                        Selecione o Serviço</small>
                </div>
            </div>

        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-between flex-wrap">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-warning p-button-text"
                (click)="fechar()"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-primary p-button-text"
                (click)="confirmar()" [disabled]="!form.valid"></button>
        </div>
    </ng-template>

</p-dialog>