<div class="flex justify-content-between flex-wrap">
    <div class="my-2">
        <button pButton class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" label="Voltar"
            (click)="voltar()"></button>
    </div>
    <div class="my-2">
        <button pButton class="p-button-primary p-button-sm" icon="pi pi-save" label="Salvar questionário"
            (click)="confirmar()" [disabled]="!form.valid"></button>
    </div>
</div>

<div class="p-fluid">
    <app-validacoes [mensagens]="validacoes"></app-validacoes>

    <form [formGroup]="form" autocomplete="off">
        <p-fieldset styleClass="field-set-sm">
            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'clipboard-question']" class="mr-2"></fa-icon>
                    <span class="font-bold text-lg">Questionário</span>
                </div>
            </ng-template>

            <div class="p-fluid field-sm ">
                <div class="field field-checkbox">
                    <p-inputSwitch inputId="ativo" formControlName="ativo"></p-inputSwitch>
                    <label for="ativo">Ativo</label>
                </div>

                <div class="field field-checkbox">
                    <p-inputSwitch inputId="exibirRelatorioCliente"
                        formControlName="exibirRelatorioCliente"></p-inputSwitch>
                    <label for="exibirRelatorioCliente">Exibir no relatório do cliente?</label>
                </div>

                <div class="field ">
                    <label for="nome">Nome</label>
                    <input type="text" pInputText id="nome" formControlName="nome" autofocus />
                    <small class="ng-dirty ng-invalid" *ngIf="nome.invalid && (nome.dirty || nome.touched)">
                        Nome deve ter pelo menos 4 caracteres</small>
                </div>

                <div class="field field-checkbox">
                    <p-inputSwitch inputId="incluirCabecalho" formControlName="incluirCabecalho"></p-inputSwitch>
                    <label for="incluirCabecalho">Exibir texto no cabeçalho do questionário</label>
                </div>

                <div class="field " [hidden]="!incluirCabecalho.value">
                    <label>Cabeçalho</label>
                    <p-editor id="mensagemCabecalho" formControlName="mensagemCabecalho"
                        [style]="{ height: '120px' }"></p-editor>
                </div>

                <div class="field field-checkbox">
                    <p-inputSwitch inputId="incluirRodape" formControlName="incluirRodape"></p-inputSwitch>
                    <label for="incluirRodape">Exibir texto no rodapé do questionário</label>
                </div>
                <div class="field" [hidden]="!incluirRodape.value">
                    <label>Rodapé</label>
                    <p-editor id="mensagemRodape" formControlName="mensagemRodape"
                        [style]="{ height: '120px' }"></p-editor>
                </div>
            </div>

        </p-fieldset>

        <p-fieldset styleClass="field-set-sm mt-2 fieldset-perguntas">
            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'clipboard-question']" class="mr-2"></fa-icon>
                    <span class="font-bold text-lg">Perguntas</span>
                </div>
            </ng-template>

            <div class="flex justify-content-end flex-wrap">
                <div class="my-2">
                    <button pButton class="p-button-primary p-button-sm" icon="pi pi-plus" label="Adicionar pergunta"
                        (click)="adicionarPergunta()"></button>
                </div>
            </div>

            <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div cdkDrag *ngFor="let item of perguntas.controls; let i = index;" class="my-2"
                    formArrayName="perguntas">
                    <p-card styleClass="card-pergunta">
                        <ng-template pTemplate="header">
                            <div class="flex justify-content-between flex-wrap pt-3 pl-3 pr-3">
                                <div class="p-card-title"> Pergunta {{i+1}}</div>
                                <div>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-sm p-button-danger "
                                        (click)="removerPergunta(i)"></button>
                                </div>
                            </div>
                        </ng-template>
                        <div [formGroupName]="i">
                            <div class="flex justify-content-between flex-wrap">
                                <div class="flex-grow-1">
                                    <div class="mt-3">
                                        <div class="p-fluid p-formgrid grid field-sm">
                                            <div class="field col-6">
                                                <label for="titulo">Título da pergunta</label>
                                                <input type="text" pInputText id="titulo" formControlName="titulo"
                                                    autofocus />
                                            </div>
                                            <div class="field col-6">
                                                <label for="tipo">Tipo da resposta</label>
                                                <p-dropdown inputId="tipo" [options]="tiposResposta" optionValue="key"
                                                    optionLabel="value" formControlName="tipo"
                                                    appendTo="body"></p-dropdown>
                                            </div>
                                            <div class="field col-12"
                                                *ngIf="form.get('perguntas')!.value[i].tipo == 9 || form.get('perguntas')!.value[i].tipo == 10">
                                                <label for="opcoes">Opções</label>
                                                <p-chips type="text" inputId="opcoes" formControlName="opcoes"
                                                    [addOnTab]="true" [addOnBlur]="true"></p-chips>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="flex justify-content-start flex-wrap mt-2">
                                        <div class="field-checkbox">
                                            <p-inputSwitch inputId="obrigatorio"
                                                formControlName="obrigatorio"></p-inputSwitch>
                                            <label for="obrigatorio">Obrigatório?</label>
                                        </div>
                                        <div class="field-checkbox ml-2">
                                            <p-inputSwitch inputId="mostrarDescricao"
                                                formControlName="mostrarDescricao"></p-inputSwitch>
                                            <label for="mostrarDescricao">Mostrar descrição?</label>
                                        </div>
                                    </div>

                                    <div class="p-fluid p-formgrid grid field-sm"
                                        [hidden]="!item.get('mostrarDescricao')!.value">
                                        <div class="field col-12">
                                            <label>Descrição</label>
                                            <div>
                                                <p-editor #dialog id="descricao" formControlName="descricao"
                                                    [style]="{ height: '120px' }"
                                                    (mousedown)="$event.stopPropagation()"></p-editor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </p-fieldset>
    </form>
</div>