import { Component, EventEmitter, Output } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-usuario-exclusao',
  templateUrl: './usuario-exclusao.component.html',
  styleUrls: ['./usuario-exclusao.component.scss']
})
export class UsuarioExclusaoComponent {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private usuarioService: UsuarioService) {
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    this.usuarioService
      .excluir(this.registro.id)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.onSucesso.emit(this.registro);
          this.fechar();
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }
}