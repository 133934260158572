import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDto } from '../dtos/response-dto';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/autorizacao/token`;
  }

  autenticar(email: string, senha: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(this.baseUrl, {
        email: email,
        senha: senha,
      })
      .pipe();
  }

  refresh(): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/refresh`, null)
      .pipe();
  }

  obter(): Observable<ResponseDto<any>> {
    return this.httpClient
      .get<ResponseDto<any>>(this.baseUrl)
      .pipe();
  }

  desbloquearAcesso(email: string, senhaAtual: string, novaSenha: string): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/desbloquear-acesso`, {
        email: email,
        senhaAtual: senhaAtual,
        novaSenha
      })
      .pipe();
  }

}
