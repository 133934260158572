<div class="grid">
    <div class="col-12">
        <div class="card px-5 py-5">
            <p-table #dt [value]="registros" [lazy]="true" (onLazyLoad)="carregarRegistros($event)"
                [totalRecords]="totalRegistros" [loading]="carregando" reponsiveLayout="scroll"
                [globalFilterFields]="['descricaoServico', 'valorCobranca']" [rows]="10" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} de {totalRecords} registros" [(selection)]="selecionados"
                selectionMode="single" [rowHover]="true" dataKey="id" styleClass="p-datable-sm">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <a (click)="forcarAtualizacao()" pButton class="p-button-outlined" icon="pi pi-refresh"
                                label="Atualizar"></a>
                        </span>
                        <div class="my-2">
                            <button [routerLink]="['/cadastros/servicos/novo']" pButton class="p-button-outlined "
                                icon="pi pi-plus" label="Novo Serviço"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">Descrição</th>
                        <th class="text-center">Valor de Cobrança</th>
                        <th class="text-center">Tipo de Serviço</th>
                        <th style="width: 120px"></th>
                    </tr>
                    <tr>
                        <th>
                            <p-columnFilter type="text" field="descricaoServico" matchMode="contains"
                                [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="valorCobranca" matchMode="equals" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="tipo" matchMode="contains" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="tiposAgendamentos" optionValue="id" optionLabel="nome"
                                        appendTo="body" [autoDisplayFirst]="true" [filter]="false"
                                        (onChange)="filter($event.value)"></p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registro>
                    <tr>
                        <td class="text-center">
                            <span class="p-column-title">Descrição do Serviço</span>
                            {{registro.descricaoServico}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Valor de Cobrança</span>
                            {{registro.valorCobranca | currency }}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Tipo de Serviço</span>
                            {{registro.tipoDescricao}}
                        </td>


                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mr-2 p-button-sm"
                                    [routerLink]="['/cadastros/servicos/', registro.id]"></button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger p-button-sm"
                                    (click)="abrirPopupExclusao(registro)"></button>
                            </div>
                        </td>

                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-servico-exclusao #exclusao (onSucesso)="registroAlterado($event)"></app-servico-exclusao>