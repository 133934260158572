import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDto } from './dtos/response-dto';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/cadastro/cliente`;
  }

  listar(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/listar`, args)
      .pipe();
  }

  obter(id: number): Observable<ResponseDto<any>> {
    return this.httpClient
      .get<ResponseDto<any>>(`${this.baseUrl}/obter/${id}`)
      .pipe();
  }

  excluir(id: number): Observable<ResponseDto<any>> {
    return this.httpClient
      .delete<ResponseDto<object>>(`${this.baseUrl}/excluir/${id}`)
      .pipe();
  }

  salvar(registro: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/salvar`, registro)
      .pipe();
  }
}
