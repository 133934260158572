<div class="grid">
    <div class="col-12">
        <div class="card px-5 py-5">
            <p-table #dt [value]="registros" [lazy]="true" (onLazyLoad)="carregarRegistros($event)"
                [totalRecords]="totalRegistros" [loading]="carregando" reponsiveLayout="scroll"
                [globalFilterFields]="['razaoSocial', 'emails']" [rows]="10" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} de {totalRecords} registros" [(selection)]="selecionados"
                selectionMode="single" [rowHover]="true" dataKey="id" styleClass="p-datable-sm">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <a (click)="forcarAtualizacao()" pButton class="p-button-outlined" icon="pi pi-refresh"
                                label="Atualizar"></a>
                        </span>
                        <div class="my-2">
                            <button [routerLink]="['/cadastros/tecnicos/novo']" pButton class="p-button-outlined "
                                icon="pi pi-plus" label="Novo Técnico"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">Ativo</th>
                        <th class="text-center">Exibe agenda?</th>
                        <th class="text-center">Razão Social</th>
                        <th class="text-center">CNPJ</th>
                        <th class="text-center">E-mail</th>
                        <th class="text-center">Celular</th>
                        <th class="text-center">Obs.</th>
                        <th style="width: 120px"></th>
                    </tr>
                    <tr>
                        <th class="text-center">
                            <p-columnFilter type="boolean" field="ativo" matchMode="equals" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th class="text-center">
                            <p-columnFilter type="boolean" field="mostrarFiltroAgenda" matchMode="equals"
                                [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="razaoSocial" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="cnpj" matchMode="contains"
                                [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="emails" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>

                        <th>
                            <p-columnFilter type="text" field="celular" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="2">
                            <p-columnFilter type="text" field="observacoes" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registro>
                    <tr>
                        <td class="text-center">
                            <span class="p-column-title ">Ativo</span>
                            <p-inputSwitch [(ngModel)]="registro.ativo" [readonly]="true"></p-inputSwitch>
                        </td>
                        <td class="text-center">
                            <span class="p-column-title ">Exibe</span>
                            <p-inputSwitch [(ngModel)]="registro.mostrarFiltroAgenda" [readonly]="true"></p-inputSwitch>
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Razão Social</span>
                            {{registro.razaoSocial}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">CNPJ</span>
                            {{registro.cnpj}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">E-mail</span>
                            {{registro.email}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Celular</span>
                            {{registro.celular}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Observações</span>
                            {{registro.observacoes}}
                        </td>

                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mr-2 p-button-sm"
                                    [routerLink]="['/cadastros/tecnicos/', registro.id]"></button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger p-button-sm"
                                    (click)="abrirPopupExclusao(registro)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-tecnico-exclusao #exclusao (onSucesso)="registroAlterado($event)"></app-tecnico-exclusao>