import { Component, OnInit, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { LocaleInput } from '@fullcalendar/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AgendaService } from 'src/app/services/agenda.service';
import { ContextMenu } from 'primeng/contextmenu';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { AgendamentoNovoComponent } from '../agendamento-novo/agendamento-novo.component';

@Component({
  selector: 'app-agendamentos-dashboard-view',
  templateUrl: './agendamentos-dashboard-view.component.html',
  styleUrls: ['./agendamentos-dashboard-view.component.scss']
})
export class AgendamentosDashboardViewComponent implements OnInit {
  calendarOptions: any = {
    initialView: 'timeGridWeek'
  };
  today: string = '';
  locales: LocaleInput[] = [ptBrLocale];
  events: any[] = [];
  items: MenuItem[] = [];
  configurado: boolean = false;
  agendamentoSelecionadoId: any = null;
  cores: string[] = [
    '#311b92',
    '#b71c1c',
    '#880e4f',
    '#4a148c',
    '#626fcb',
    '#3c1415',
    '#c4b34f',
    '#19200b',
    '#b43caa',
    '#1a237e',
    '#794928',
    '#32977e',
    '#0d47a1',
    '#323a97',
    '#3968aa',
    '#1c1e55',
  ];

  todosTecnicos: boolean = false;
  tecnicos: any[] = [];
  tecnicosSelecionados: any[] = [];
  itemTrocarTecnico: any = null;

  @ViewChild(ContextMenu) cm?: ContextMenu;
  @ViewChild(FullCalendarComponent) fc?: FullCalendarComponent;
  @ViewChild(AgendamentoNovoComponent) popupNovo?: AgendamentoNovoComponent;

  constructor(
    private app: AppComponent,
    private router: Router,
    private agendaService: AgendaService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.itemTrocarTecnico = {
      label: 'Trocar técnico',
      icon: 'pi pi-user',
      items: []
    };

    this.items = [
      {
        label: 'Alterar',
        icon: 'pi pi-file-edit',
        command: this.alterarAgendamento.bind(this)
      },
      {
        label: 'Cancelar',
        icon: 'pi pi-times',
        command: this.cancelarAgendamento.bind(this)
      },
      {
        separator: true
      },
      this.itemTrocarTecnico
    ];

    this.today = moment(new Date()).format('YYYY-MM-DD')

    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      height: 720,
      initialDate: this.today,
      headerToolbar: {
        left: 'atualizar prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      locale: ptBrLocale,
      deepChangeDetection: true,
      customButtons: {
        atualizar: {
          text: 'Atualizar',
          click: this.calendarioAtualizar.bind(this)
        }
      },
      eventClick: this.calendarioClick.bind(this),
      eventResize: this.calendarioEventResize.bind(this),
      eventDrop: this.calendarioEventDrop.bind(this),
      eventDidMount: this.calendarioEventDidMount.bind(this),
      select: this.calendarioSelect.bind(this),
      datesSet: this.calendarioDatesSet.bind(this)
    };

    const params = this.activatedRoute.snapshot.queryParams
    if (params['viewType']) {
      this.calendarOptions.initialView = params['viewType'];
    }

    if (params['start']) {
      const start = Date.parse(params['start']);
      if (start) {
        this.calendarOptions.initialDate = start;
      }
    }

    if (params['tecnicos']) {
      for (let tecnico of params['tecnicos'].split(',').map((x: string) => Number.parseInt(x))) {
        if (tecnico) {
          this.tecnicosSelecionados.push(tecnico);
        }
      }
    }

    this.app.bloquearTela();

    this.agendaService
      .tecnicos({
        skip: 0,
        take: 1000,
        tipoUsuario: 2
      })
      .subscribe(response => {
        this.configurarTecnicos(response.data!.registros!);
        this.calendarOptions = { ...this.calendarOptions, ...{ events: this.eventos.bind(this) } };
        this.configurado = true;
      });
  }

  cancelarAgendamento() {
    this.confirmationService.confirm({
      message: 'Deseja realmente cancelar o atendimento?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.app.bloquearTela();

        this.agendaService
          .cancelarAgendamento(this.agendamentoSelecionadoId)
          .subscribe(response => {
            this.fc!.getApi().refetchEvents();
            this.app.desbloquearTela();
          });
      },
      reject: () => {
      }
    });
  }

  configurarTecnicos(registros: any[]) {
    var corIndex = 0;

    for (let tecnico of registros) {
      if (corIndex >= this.cores.length) {
        corIndex = 0;
      }

      this.tecnicos = [...this.tecnicos, {
        id: tecnico.usuarioTecnicoId,
        nome: tecnico.razaoSocial,
        cor: tecnico.corTecnico || this.cores[corIndex++]
      }];
    }

    for (let tecnico of this.tecnicos) {
      this.itemTrocarTecnico.items = [...this.itemTrocarTecnico.items!, {
        label: tecnico.nome,
        icon: 'pi pi-user',
        state: tecnico,
        command: this.trocarTecnico.bind(this)
      }]
    }
  }

  trocarTecnico(args: any) {
    this.app.bloquearTela();

    this.agendaService
      .trocarTecnico({
        agendamentoId: this.agendamentoSelecionadoId,
        novoTecnicoId: args.item.state.id
      })
      .subscribe(response => {
        this.fc!.getApi().refetchEvents();
        this.app.desbloquearTela();
      });
  }

  calendarioDatesSet(args: any) {
    const queryParams: Params = {
      viewType: args.view.type,
      start: args.start?.toISOString(),
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: "merge"
      }
    );
  }

  eventos(range: any, success: any, failure: any) {
    this.app.bloquearTela();

    var i = 0;
    this.agendaService.listar({
      dataInicial: range.start,
      dataFinal: range.end,
      tecnicos: this.tecnicosSelecionados.join(',')
    }).subscribe(response => {
      var events = [];

      for (var e of response.data!) {
        if (i >= this.tecnicos.length) {
          i = 0;
        }

        var tecnico = this.tecnicos.filter(x => x.id == e.responsavelId).at(0);

        events.push({
          id: e.id,
          title: `${e.clienteDescricao} - ${e.tipoDescricao}`,
          start: e.dataHora,
          end: e.dataHoraTermino,
          color: tecnico?.cor || this.cores[this.cores.length - 1],
          responsavelId: e.responsavelId,
        });
      }

      success(events);

      this.app.desbloquearTela();
    });
  }

  calendarioSelect(args: any) {
    args.view.calendar.unselect();

    this.popupNovo?.abrir({
      dataHora: args.start,
      dataHoraTermino: args.end,
      responsavelId: this.tecnicosSelecionados.length == 1 ? this.tecnicosSelecionados[0] : null,
      clienteId: null,
      tarefaId: null,
      descricao: null,
    });
  }

  calendarioEventDidMount(args: any) {
    const eventId = args.event.id
    args.el.addEventListener("contextmenu", (jsEvent: any) => {
      console.log("contextMenu", eventId)
      jsEvent.preventDefault()

      for (let menu of this.itemTrocarTecnico.items!) {
        menu.disabled = menu.state!['id'] == args.event.extendedProps.responsavelId;
      }

      this.agendamentoSelecionadoId = args.event.id;
      this.cm!.target = jsEvent.currentTarget;
      this.cm!.show(jsEvent);
    })
  }

  calendarioClick(args: any) {
    console.log('click', args);
    if (args.jsEvent.detail > 1) {
      this.irParaEdicaoAgendamento(args.event.id);
    }
  }

  irParaEdicaoAgendamento(agendamentoId: any) {
    this.router.navigate(['agendamentos/', agendamentoId], {
      queryParams: {
        returnRoute: '/',
        returnQueryParams: JSON.stringify(this.activatedRoute.snapshot.queryParams),
      }
    });
  }

  calendarioEventResize(args: any) {
    this.agendaService
      .alterarDataHora({
        id: args.event.id,
        dataHora: args.event.start,
        dataHoraTermino: args.event.end,
      })
      .subscribe(response => {
        args.view.calendar.refetchEvents();
      });
  }

  calendarioEventDrop(args: any) {
    this.agendaService
      .alterarDataHora({
        id: args.event.id,
        dataHora: args.event.start,
        dataHoraTermino: args.event.end,
      })
      .subscribe(response => {
        args.view.calendar.refetchEvents();
      });
  }

  onTecnicosChange(event: any) {
    var selecionados = '';
    if (this.tecnicosSelecionados.length > 0) {
      selecionados = this.tecnicosSelecionados.join(',');
    }

    const queryParams: Params = {
      tecnicos: selecionados,
    };

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: "merge"
      }
    );

    this.fc!.getApi().refetchEvents();
  }

  calendarioAtualizar() {
    this.fc!.getApi().refetchEvents();
  }

  alterarAgendamento(args: any) {
    this.irParaEdicaoAgendamento(this.agendamentoSelecionadoId);
  }

  onNovo(args: any) {
    this.fc!.getApi().unselect();
    this.fc!.getApi().refetchEvents();
  }
}
