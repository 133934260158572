import { Component, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { TecnicoService } from 'src/app/services/tecnico.service';
import { AppComponent } from 'src/app/app.component';
import { TecnicoExclusaoComponent } from '../tecnico-exclusao/tecnico-exclusao.component';

@Component({
  selector: 'app-tecnico-view',
  templateUrl: './tecnico-view.component.html',
  styleUrls: ['./tecnico-view.component.scss']
})
export class TecnicoViewComponent {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;

  @ViewChild(Table) dt?: Table;
  @ViewChild('exclusao') popupExclusao?: TecnicoExclusaoComponent;

  constructor(
    private app: AppComponent,
    private tecnicoService: TecnicoService,) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.tecnicoService
      .listar({
        skip: event.first,
        take: event.rows,
        filtros: JSON.parse(JSON.stringify(event?.filters || this.dt?.filters || '{}'))
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });      
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupExclusao(registro: any) {
     this.popupExclusao?.abrir(registro);
  }
  
  forcarAtualizacao() {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }
}
