import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, of, tap, throwError } from "rxjs";
import { TokenStorageService } from "../autorizacao/token-storage.service";

@Injectable()
export class ResponseDateParserHttpInterceptor implements HttpInterceptor {
    private dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;
    private dateTimeOffsetRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)-(\d{2}):(\d{2})$/;
    private utcDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

    constructor(
        private router: Router,
        private tokenStorage: TokenStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap({
                    next: (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.convertDates(event.body);
                        }
                    }
                }),
                catchError(x => this.handleAuthError(x)));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        debugger
        if (err.status === 401 || err.status === 403) {
            this.tokenStorage.clearToken(); 
            this.router.navigate(['/auth/login'], {
                queryParams: {
                    returnUrl: location.hash ? location.hash : null
                }
            });
            return of(err.message);
        }
        return throwError(err);
    }

    private convertDates(object: Object) {
        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.convertDates(item);
            }
        }

        const casted = object as { [key: string]: unknown };

        for (const key of Object.keys(casted)) {
            const value = casted[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.convertDates(item);
                }
            }

            if (value instanceof Object) {
                this.convertDates(value);
            }

            if (typeof value === 'string' &&
                (this.dateTimeRegex.test(value) ||
                    this.dateTimeOffsetRegex.test(value) ||
                    this.utcDateRegex.test(value))) {
                casted[key] = new Date(value);
            }
        }
    }
}