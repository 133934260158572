import { Component, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ServicoService } from 'src/app/services/servico.service';
import { AppComponent } from 'src/app/app.component';
import { ServicoExclusaoComponent } from '../servico-exclusao/servico-exclusao.component';

@Component({
  selector: 'app-servico-view',
  templateUrl: './servico-view.component.html',
  styleUrls: ['./servico-view.component.scss']
})
export class ServicoViewComponent {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;
  tiposAgendamentos: any[] = [{
    id: 0,
    nome: ''
  }, {
    id: 1,
    nome: 'Instalação'
  }, {
    id: 2,
    nome: 'Manutenção'
  }, {
    id: 3,
    nome: 'Desinstalação'
  }];

  @ViewChild(Table) dt?: Table;
  @ViewChild('exclusao') popupExclusao?: ServicoExclusaoComponent;

  constructor(
    private app: AppComponent,
    private servicoService: ServicoService,) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.servicoService
      .listar({
        skip: event.first,
        take: event.rows,
        filtros: JSON.parse(JSON.stringify(event?.filters || this.dt?.filters || '{}'))
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  forcarAtualizacao() {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupExclusao(registro: any) {
    this.popupExclusao?.abrir(registro);
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }
}
