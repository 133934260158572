<div class="p-fluid p-formgrid grid field-sm">
    <div class="field col-11">
        <app-busca-endereco (onEnderecoSelecionado)="onSelecao($event)"
            (onLimparBusca)="limpaBusca()"></app-busca-endereco>
    </div>
    <div style="margin-top: 30px;">
        <a target="_blank" (click)="centralizar()" pButton class="p-button" icon="pi pi-map-marker"
            label="Centralizar"></a>
    </div>
</div>
<div style="flex-grow: 1; margin-top: 20px; height: 786px;" leaflet [leafletOptions]="options" [leafletLayers]="markers"
    (leafletMapReady)="onMapaPronto($event)">
</div>