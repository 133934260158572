import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { ServicoService } from 'src/app/services/servico.service';
import { ServicoCobrancaEditComponent } from '../servico-cobranca-edit/servico-cobranca-edit.component';

@Component({
  selector: 'app-servico-edit',
  templateUrl: './servico-edit.component.html',
  styleUrls: ['./servico-edit.component.scss']
})
export class ServicoEditComponent {
  validacoes: string[] = [];
  registro: any = {};
  form!: FormGroup;
  servicoCliente: any[] = [];
  servicoTecnico: any[] = [];
  servicos: any[] = [{
    id: 1,
    nome: 'Instalação'
  }, {
    id: 2,
    nome: 'Manutenção'
  }, {
    id: 3,
    nome: 'Desinstalação'
  }];

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('edicaoCobranca') edicaoCobranca?: ServicoCobrancaEditComponent;

  constructor(
    private router: Router,
    private servicoService: ServicoService,
    private app: AppComponent,
    private route: ActivatedRoute,
  ) { }

  get descricaoServico() { return this.form.get('descricaoServico')!; };
  get valorCobranca() { return this.form.get('valorCobranca')!; };
  get tipo() { return this.form.get('tipo')!; };

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      descricaoServico: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      valorCobranca: new FormControl(
        '', [
        Validators.required
      ]),
      tipo: new FormControl('', [
        Validators.required
      ]),
    });

    if (this.route.snapshot.paramMap.get('id')) {
      this.servicoService
        .obter(Number(this.route.snapshot.paramMap.get('id')))
        .subscribe(response => {
          this.abrir(response.data!);
        });
    } else {
      this.abrir({});
    }
  }

  abrir(registro: any) {
    this.registro = registro;
    this.form.reset();
    this.form.setValue({
      id: registro.id ?? null,
      descricaoServico: registro.descricaoServico ?? '',
      valorCobranca: registro.valorCobranca ?? '',
      tipo: registro.tipo ?? 1,
    });
  }

  voltar() {
    this.router.navigate(['/cadastros/servicos']);
  }

  criarServico() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.app.bloquearTela();
    this.validacoes = [];

    const registro = cloneDeep(this.form.value)
    this.servicoService
      .salvar(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.voltar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }

      });
  }

  novaCobranca(tipoPessoa: any) {
    this.abrirEdicaoCobranca({
      id: null,
      pessoaId: null,
      razaoSocial: '',
      tipoPessoa: tipoPessoa,
      valorCobranca: '',
    });
  }

  abrirEdicaoCobranca(registro: any) {
    this.edicaoCobranca?.abrir(registro);
  }

  cobrancaAlterada(registro: any) {
    switch (registro.tipoPessoa) {
      case "Cliente":

        if (this.servicoCliente.indexOf(registro) == -1) {
          this.servicoCliente = [...this.servicoCliente, registro];
        }

        break;
      case "Técnico":

        if (this.servicoTecnico.indexOf(registro) == -1) {
          this.servicoTecnico = [...this.servicoTecnico, registro];
        }

        break;
    }
  }
}
