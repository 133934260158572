import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bloqueio-tela',
  templateUrl: './bloqueio-tela.component.html',
  styleUrls: ['./bloqueio-tela.component.scss']
})
export class BloqueioTelaComponent {
  @Input() telaBloqueada!: boolean;
  @Output() telaBloqueadaChange = new EventEmitter<boolean>();
}
