import { Component, OnInit, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { AppComponent } from 'src/app/app.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuarioEdicaoComponent } from '../usuario-edicao/usuario-edicao.component';
import { UsuarioExclusaoComponent } from '../usuario-exclusao/usuario-exclusao.component';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-usuario-view',
  templateUrl: './usuario-view.component.html',
  styleUrls: ['./usuario-view.component.scss']
})
export class UsuarioViewComponent implements OnInit {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;
  tipoUsuario: any[] = [{
    id: 0,
    nome: ''
  }, {
    id: 1,
    nome: 'Analista'
  },{
    id: 2,
    nome: 'Técnico'
  }];

  @ViewChild(Table) dt?: Table;
  @ViewChild('edicao') popupEdicao?: UsuarioEdicaoComponent;
  @ViewChild('exclusao') popupExclusao?: UsuarioExclusaoComponent;

  constructor(
    private app: AppComponent,
    private usuarioService: UsuarioService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.usuarioService
      .listar({
        skip: event.first,
        take: event.rows,
        filtros: JSON.parse(JSON.stringify(event?.filters || this.dt?.filters || '{}'))
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  imprimir() {
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupNovo() {
    this.abrirPopupEdicao({
      id: null,
      ativo: true,
      nomeCompleto: '',
      email: '',
      perfilAcessoId: null,
      tipo: '',
      fornecedorCnpj: '',
      fornecedorRazaoSocial: '',
      observacoes: '',
      celular: '',
      clientes: [],
      tipoUsuario: [{
        id: 0,
        nome: ''
      }, {
        id: 1,
        nome: 'Analista'
      },{
        id: 2,
        nome: 'Técnico'
      }],      
    })
  }

  abrirPopupEdicao(registro: any) {
    if (registro.id) {
      this.app.bloquearTela();

      this.usuarioService
        .obter(registro.id)
        .subscribe(response => {
          this.app.desbloquearTela();
          this.popupEdicao?.abrir(response.data);
        });
    } else {
      this.popupEdicao?.abrir(registro);
    }
  }

  abrirPopupExclusao(registro: any) {
    this.popupExclusao?.abrir(registro);
  }

  resetarSenha(event: Event, registro: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Deseja resetar a senha de acesso do usuário para 123@mudar?',
      header: 'Confirmar reset de senha',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        this.app.bloquearTela();

        this.usuarioService
          .resetarSenha(registro.id)
          .subscribe(response => {
            this.app.desbloquearTela();
            this.app.notificacaoSucesso('Senha do usuário alterada com sucesso');
          });
      },
      reject: () => {
      }
    });
  }
  
  forcarAtualizacao() {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }
}
