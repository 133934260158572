import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  decodedToken: any = null;

  constructor() { }

  getManterConectado() {
    return localStorage.getItem('manterConectado') == '1';
  }

  getBearerToken() {
    return localStorage.getItem('jwtToken');
  }

  clearToken() {
    this.decodedToken = null;
    localStorage.setItem('jwtToken', '');
  }

  setToken(token: string, manterConectado: boolean) {
    if (token) {
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('manterConectado', manterConectado ? '1' : '0');
      this.decodedToken = jwtDecode(token);
    }
  }

  decodeToken() {
    if (localStorage.getItem('jwtToken')) {
      this.decodedToken = jwtDecode(localStorage.getItem('jwtToken')!);
    }
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.nome : null;
  }

  getEmailId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.email : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  getAdmin() {
    this.decodeToken();
    return this.decodedToken.admin ? this.decodedToken.admin : null;
  }

  isTokenExpiredOrInvalid(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return true;
    }
  }

  setTokenDetalhes(detalhes: any) {
    localStorage.setItem('jwtDetail', JSON.stringify(detalhes));
  }

  getTokenDetalhes() {
    return JSON.parse(localStorage.getItem('jwtDetail')!);
  }
}

