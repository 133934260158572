import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { PerfilAcessoService } from 'src/app/services/perfil-acesso.service';
import { PerfilAcessoEdicaoComponent } from '../perfil-acesso-edicao/perfil-acesso-edicao.component';
import { AppComponent } from 'src/app/app.component';
import { PerfilAcessoExclusaoComponent } from '../perfil-acesso-exclusao/perfil-acesso-exclusao.component';

@Component({
  selector: 'app-perfil-acesso-view',
  templateUrl: './perfil-acesso-view.component.html',
  styleUrls: ['./perfil-acesso-view.component.scss']
})
export class PerfilAcessoViewComponent implements OnInit {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;

  @ViewChild(Table) dt?: Table;
  @ViewChild('edicao') popupEdicao?: PerfilAcessoEdicaoComponent;
  @ViewChild('exclusao') popupExclusao?: PerfilAcessoExclusaoComponent;


  constructor(
    private app: AppComponent,
    private perfilAcessoService: PerfilAcessoService) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.perfilAcessoService
      .listar({
        skip: event.first,
        take: event.rows,
        filtro: event.globalFilter,
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  imprimir() {
  }

  onPesquisar(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupNovo() {
    this.abrirPopupEdicao({
      id: null,
      nome: '',
      acessoColaboradores: [],
      acessoPerfisAcesso: [],
      acessoClientes: [],
      acessoQuestionarios: [],
      acessoTiposAgendamento: [],
      acessoListas: [],
      acessoAgendamento: []
    })
  }

  abrirPopupEdicao(registro: any) {

    if (registro.id) {
      this.app.bloquearTela();

      this.perfilAcessoService
        .obter(registro.id)
        .subscribe(response => {
          this.app.desbloquearTela();
          this.popupEdicao?.abrir(response.data);
        });
    } else {
      this.popupEdicao?.abrir(registro);
    }
  }

  abrirPopupExclusao(registro: any) {
    this.popupExclusao?.abrir(registro);
  }
}
