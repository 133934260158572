import { Injectable } from '@angular/core';
import { ResponseDto } from './dtos/response-dto';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { flatMap } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/movimentacao/agenda`;
  }

  listar(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/listar`, args)
      .pipe();
  }

  alterarDataHora(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/alterar-data-hora`, args)
      .pipe();
  }

  tecnicos(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/tecnicos`, args)
      .pipe();
  }

  trocarTecnico(args: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/trocar-tecnico`, args)
      .pipe();
  }

  cancelarAgendamento(agendamentoId: any): Observable<ResponseDto<any>> {
    return this.httpClient
      .post<ResponseDto<any>>(`${this.baseUrl}/cancelar/${agendamentoId}`, agendamentoId)
      .pipe();
  }
}
