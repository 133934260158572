<p-dialog #edicao [(visible)]="visivel" [style]="{width: '75vw'}" header="Usuário" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <app-validacoes [mensagens]="validacoes"></app-validacoes>

        <form [formGroup]="form" autocomplete="off">
            <input autocomplete="false" name="hidden" type="text" class="hidden">

            <div class="field-checkbox">
                <p-inputSwitch id="ativo" formControlName="ativo"></p-inputSwitch>
                <label for="ativo">Ativo</label>
            </div>

            <div class="field">
                <label for="nomeCompleto">Nome</label>
                <input type="text" pInputText id="nomeCompleto" formControlName="nomeCompleto" autofocus />
                <small class="ng-dirty ng-invalid"
                    *ngIf="nomeCompleto.invalid && (nomeCompleto.dirty || nomeCompleto.touched)">
                    Nome deve ter pelo menos 4 caracteres</small>
            </div>

            <div class="field">
                <label for="email">E-mail</label>
                <input type="email" pInputText id="email" formControlName="email" required autocomplete="off"
                    [readonly]="form.controls['id'].value">
                <small class="ng-dirty ng-invalid" *ngIf="email.invalid && (email.dirty || email.touched)">
                    E-mail inválido</small>
            </div>

            <div class="field">
                <label for="perfilAcessoId">Perfil de acesso</label>
                <p-dropdown id="perfilAcessoId" [options]="perfisAcesso" optionValue="id" optionLabel="nome"
                    formControlName="perfilAcessoId" appendTo="body" [autoDisplayFirst]="false"></p-dropdown>
                <small class="ng-dirty ng-invalid"
                    *ngIf="perfilAcessoId.invalid && (perfilAcessoId.dirty || perfilAcessoId.touched)">
                    Perfil de acesso inválido</small>
            </div>

            <div class="field">
                <label for="celular">Celular</label>
                <p-inputMask mask="99999999999" inputId="celular" formControlName="celular"
                    placeholder="99999999999"></p-inputMask>
            </div>            

            <div class="field">
                <label for="observacoes">Observação</label>
                <textarea pInputTextarea inputId="observacoes" rows="3" formControlName="observacoes"
                    autofocus></textarea>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-between flex-wrap">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-warning p-button-text"
                (click)="fechar()"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-primary p-button-text"
                (click)="confirmar()" [disabled]="!form.valid"></button>
        </div>
    </ng-template>
</p-dialog>