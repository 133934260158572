import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDto } from './dtos/response-dto';

@Injectable({
  providedIn: 'root'
})
export class NominatimService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.nominatimUrl}`;
  }

  obterEnderecoPorLatLong(lat: any, lon: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.baseUrl}/reverse?lat=${lat}&lon=${lon}&format=json`)
      .pipe();
  }

  obterEnderecoPorDescricao(enderecoDescricao: string): Observable<any[]> {
    return this.httpClient
      .get<any>(`${this.baseUrl}/search.php?q=${enderecoDescricao}&format=json&addressdetails=1&dedupe=1&layer=address&featureType=state,city,county,street&countrycodes=br`)
      .pipe();
  }
}