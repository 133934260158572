import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-pergunta',
  templateUrl: './pergunta.component.html',
  styleUrls: ['./pergunta.component.scss']
})
export class PerguntaComponent implements OnInit, OnDestroy {
  private respostaChanged: Subject<any> = new Subject<any>();
  private respostaChangedDelay: number = 500;

  _pergunta: any = {};
  get pergunta(): any {
    return this._pergunta;
  }
  @Input()
  set pergunta(value: any) {
    if (value !== this._pergunta) {
      this._pergunta = value;
    }
  }

  _habilitado: boolean = false;
  get habilitado(): boolean {
    return this._habilitado;
  }
  @Input()
  set habilitado(value: boolean) {
    if (value !== this._habilitado) {
      this._habilitado = value;
    }
  }

  @Output()
  onAlteracao: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.respostaChanged
      .pipe(
        debounceTime(this.respostaChangedDelay),
      )
      .subscribe(data => {
        this.onAlteracao.emit(data);
      });
  }

  ngOnDestroy() {
    this.respostaChanged.complete()
  }

  onRespostaAlterada(resposta: any) {
    this.respostaChanged.next(resposta);
  }
}
