<div class="flex justify-content-between flex-wrap">
    <div class="my-2">
        <button pButton class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" label="Voltar"
            (click)="voltar()"></button>
    </div>
    <div class="my-2">
        <button pButton class="p-button-primary p-button-sm" icon="pi pi-save" label="Salvar cliente"
            (click)="confirmar()" [disabled]="!form.valid"></button>
    </div>
</div>

<div class="p-fluid">
    <app-validacoes [mensagens]="validacoes"></app-validacoes>

    <form [formGroup]="form" autocomplete="off">
        <input autocomplete="false" name="hidden" type="text" class="hidden">


        <p-fieldset styleClass="field-set-sm">
            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'building-user']" class="mr-2"></fa-icon>
                    <span class="font-bold text-lg">Cliente</span>
                </div>
            </ng-template>

            <div class="field-checkbox">
                <p-inputSwitch inputId="ativo" formControlName="ativo"></p-inputSwitch>
                <label for="ativo">Ativo</label>
            </div>

            <div class="p-fluid p-formgrid grid field-sm">
                <div class="field col-4">
                    <label for="razaoSocial">Razão social</label>
                    <input type="text" pInputText id="razaoSocial" formControlName="razaoSocial" autofocus />
                    <small class="ng-dirty ng-invalid"
                        *ngIf="razaoSocial.invalid && (razaoSocial.dirty || razaoSocial.touched)">
                        Razão social deve ter pelo menos 4 caracteres</small>
                </div>
                <div class="field col-4">
                    <label for="nomeFantasia">Nome fantasia</label>
                    <input type="text" pInputText id="nomeFantasia" formControlName="nomeFantasia" />
                    <small class="ng-dirty ng-invalid"
                        *ngIf="nomeFantasia.invalid && (nomeFantasia.dirty || nomeFantasia.touched)">
                        Razão social deve ter pelo menos 4 caracteres</small>
                </div>
                <div class="field col-4">
                    <label for="cnpj">CNPJ</label>
                    <p-inputMask mask="99.999.999/9999-99" id="cnpj" formControlName="cnpj"
                        placeholder="99.999.999/9999-99"></p-inputMask>
                </div>
                <div class="field col-4">
                    <label for="responsaveis">Responsáveis</label>
                    <p-chips type="text" inputId="responsaveis" formControlName="responsaveis" [addOnTab]="true"
                        [addOnBlur]="true"></p-chips>
                </div>
                <div class="field col-4">
                    <label for="telefones">Telefones</label>
                    <p-chips type="text" inputId="telefones" formControlName="telefones" [addOnTab]="true"
                        [addOnBlur]="true"></p-chips>
                </div>
                <div class="field col-4">
                    <label for="emails">E-mails</label>
                    <p-chips type="text" inputId="emails" formControlName="emails" [addOnTab]="true"
                        [addOnBlur]="true"></p-chips>
                </div>
            </div>

        </p-fieldset>

        <div class="p-fluid p-formgrid grid grid-permissoes">
            <div class=" col-6">
                <p-fieldset styleClass="field-set-sm mt-3">
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center text-primary">
                            <fa-icon [fixedWidth]="true" [icon]="['fas', 'address-book']" class="mr-2"></fa-icon>
                            <span class="font-bold text-lg">Contatos</span>
                        </div>
                    </ng-template>


                    <p-table [value]="contatos" responsiveLayout="scroll" styleClass="p-datatable-sm">
                        <ng-template pTemplate="caption">

                            <div class="flex justify-content-end flex-wrap">
                                <div>
                                    <button pButton class="p-button-primary p-button-sm" icon="pi pi-plus"
                                        label="Novo contato" size="small" (click)="novoContato()"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Nome</th>
                                <th>Cargo</th>
                                <th>Telefone</th>
                                <th>E-mail</th>
                                <th style="width: 100px"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-registro>
                            <tr>
                                <td>
                                    <span class="p-column-title">Nome</span>
                                    {{registro.nome}}
                                </td>
                                <td>
                                    <span class="p-column-title">Cargo</span>
                                    {{registro.cargo}}
                                </td>
                                <td>
                                    <span class="p-column-title">Telefone</span>
                                    {{registro.telefone}}
                                </td>
                                <td>
                                    <span class="p-column-title">E-mail</span>
                                    {{registro.email}}
                                </td>
                                <td>
                                    <div class="flex">
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-sm p-button-primary mr-2"
                                            (click)="abrirEdicaoContato(registro)"></button>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-sm p-button-danger "
                                            (click)="excluirContato(registro)"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-fieldset>
            </div>
            <div class=" col-6">
                <p-fieldset styleClass="field-set-sm mt-3">
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center text-primary">
                            <fa-icon [fixedWidth]="true" [icon]="['fas', 'map-location-dot']" class="mr-2"></fa-icon>
                            <span class="font-bold text-lg">Endereço</span>
                        </div>
                    </ng-template>

                    <div class="p-fluid p-formgrid grid field-sm">
                        <div class="field col-12">
                            <label for="enderecoDescricao">Endereço</label>
                            <input type="text" pInputText id="enderecoDescricao" formControlName="enderecoDescricao" />
                        </div>
                        <div class="field col-4">
                            <label for="enderecoComplemento">Complemento</label>
                            <input type="text" pInputText id="enderecoComplemento"
                                formControlName="enderecoComplemento" />
                        </div>
                        <div class="field col-4">
                            <label for="enderecoLatitude">Latitude</label>
                            <p-inputNumber formControlName="enderecoLatitude" inputId="enderecoLatitude" mode="decimal"
                                [minFractionDigits]="2" [maxFractionDigits]="6" [min]="-90" [max]="90"> </p-inputNumber>
                        </div>
                        <div class="field col-4">
                            <label for="enderecoLongitude">Longitude</label>
                            <p-inputNumber formControlName="enderecoLongitude" inputId="enderecoLongitude"
                                mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="6" [min]="-180" [max]="180">
                            </p-inputNumber>
                        </div>
                    </div>
                </p-fieldset>
            </div>
        </div>
    </form>
</div>

<app-cliente-edicao-contato #edicaoContato (onSucesso)="contatoAlterado($event)"></app-cliente-edicao-contato>