import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RedirecionarNaoAutorizadoService {
  constructor(
    private router: Router,
    private tokenStorage: TokenStorageService) {
  }

  resolve(): void {
    if (this.tokenStorage.isTokenExpiredOrInvalid()) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          returnUrl: location.hash ? location.hash : null
        }
      });
    }
  }
}
