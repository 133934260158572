import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agendamentoSituacao'
})
export class AgendamentoSituacaoPipe implements PipeTransform {

  transform(value: Number, ...args: unknown[]): string {
    switch (value) {
      case 1:
        return 'Pendente';
      case 2:
        return 'Em andamento';
      case 3:
        return 'Aprovado';
      case 4:
        return 'Reprovado';
      case 5:
        return 'Aguardando aprovação';
      case 6:
        return 'Cancelado';
    }

    return '';
  }
}
