<div class="flex justify-content-between flex-wrap">
    <div class="my-2">
        <button pButton class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" label="Voltar"
            (click)="voltar()"></button>
    </div>
    <div class="my-2">
        <button pButton class="p-button-primary p-button-sm" icon="pi pi-save" label="Salvar tarefa"
            (click)="confirmar()" [disabled]="!form.valid"></button>
    </div>
</div>

<div class="p-fluid">
    <app-validacoes [mensagens]="validacoes"></app-validacoes>


    <form [formGroup]="form" autocomplete="off">
        <p-fieldset styleClass="field-set-sm">
            <ng-template pTemplate="header">
                <div class="flex align-items-center text-primary">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-days']" class="mr-2"></fa-icon>
                    <span class="font-bold text-lg">Tarefa</span>
                </div>
            </ng-template>

            <div class="field-checkbox">
                <p-inputSwitch id="ativo" formControlName="ativo"></p-inputSwitch>
                <label for="ativo">Ativo</label>
            </div>

            <div class="field">
                <label for="nome">Nome</label>
                <input type="text" pInputText id="nome" formControlName="nome" autofocus />
                <small class="ng-dirty ng-invalid" *ngIf="nome.invalid && (nome.dirty || nome.touched)">
                    Nome deve ter pelo menos 4 caracteres</small>
            </div>
        </p-fieldset>
    </form>

    <p-fieldset styleClass="field-set-sm mt-2">
        <ng-template pTemplate="header">
            <div class="flex align-items-center text-primary">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-days']" class="mr-2"></fa-icon>
                <span class="font-bold text-lg">Questionários p/ preenchimento técnico</span>
            </div>
        </ng-template>

        <p-pickList [source]="questionariosDisponiveisExterno" [target]="questionariosSelecionadosExterno"
            sourceHeader="Questionários" targetHeader="Selecionados" [dragdrop]="true" [responsive]="true"
            [sourceStyle]="{ height: '20rem' }" [targetStyle]="{ height: '20rem' }" breakpoint="1000px"
            styleClass="selecao-questionario">
            <ng-template let-item pTemplate="item">
                <div class="flex flex-wrap p-2 align-items-center gap-3">
                    <div class="flex-1 flex flex-column gap-2">
                        <span class="font-bold">{{ item.nome }}</span>
                    </div>
                </div>
            </ng-template>
        </p-pickList>
    </p-fieldset>

    <p-fieldset styleClass="field-set-sm mt-2">
        <ng-template pTemplate="header">
            <div class="flex align-items-center text-primary">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-days']" class="mr-2"></fa-icon>
                <span class="font-bold text-lg">Questionários p/ preenchimento interno</span>
            </div>
        </ng-template>

        <p-pickList [source]="questionariosDisponiveisInterno" [target]="questionariosSelecionadosInterno"
            sourceHeader="Questionários" targetHeader="Selecionados" [dragdrop]="true" [responsive]="true"
            [sourceStyle]="{ height: '20rem' }" [targetStyle]="{ height: '20rem' }" breakpoint="1000px"
            styleClass="selecao-questionario">
            <ng-template let-item pTemplate="item">
                <div class="flex flex-wrap p-2 align-items-center gap-3">
                    <div class="flex-1 flex flex-column gap-2">
                        <span class="font-bold">{{ item.nome }}</span>
                    </div>
                </div>
            </ng-template>
        </p-pickList>
    </p-fieldset>
</div>