<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar *ngIf="!layoutService.esconderTodosMenus"></app-sidebar>
    <div class="layout-content-wrapper">
        <app-topbar *ngIf="!layoutService.esconderTodosMenus"></app-topbar>
        <app-breadcrumb class="content-breadcrumb" *ngIf="!layoutService.esconderTodosMenus"></app-breadcrumb>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu *ngIf="!layoutService.esconderTodosMenus"></app-profilemenu>
    <div class="layout-mask" *ngIf="!layoutService.esconderTodosMenus"></div>
    <p-confirmDialog></p-confirmDialog>
</div>