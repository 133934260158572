import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { ClienteService } from 'src/app/services/cliente.service';
import { ClienteEdicaoContatoComponent } from '../cliente-edicao-contato/cliente-edicao-contato.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cliente-edicao',
  templateUrl: './cliente-edicao.component.html',
  styleUrls: ['./cliente-edicao.component.scss']
})
export class ClienteEdicaoComponent implements OnInit {
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  contatos: any[] = [];

  @ViewChild('edicaoContato') edicaoContato?: ClienteEdicaoContatoComponent;

  get ativo() { return this.form.get('ativo')!; }
  get razaoSocial() { return this.form.get('razaoSocial')!; }
  get nomeFantasia() { return this.form.get('nomeFantasia')!; }
  get cnpj() { return this.form.get('cnpj')!; }
  get responsaveis() { return this.form.get('responsaveis')!; }
  get telefones() { return this.form.get('telefones')!; }
  get emails() { return this.form.get('emails')!; }
  get enderecoDescricao() { return this.form.get('enderecoDescricao')!; }
  get enderecoComplemento() { return this.form.get('enderecoComplemento')!; }
  get enderecoLatitude() { return this.form.get('enderecoLatitude')!; }
  get enderecoLongitude() { return this.form.get('enderecoLongitude')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private clienteService: ClienteService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      ativo: new FormControl(true),
      razaoSocial: new FormControl(
        '', [
        Validators.required,
        Validators.minLength(4)
      ]),
      nomeFantasia: new FormControl(
        '', [
        Validators.required,
      ]),
      cnpj: new FormControl(
        '', [
        Validators.required,
      ]),
      responsaveis: new FormControl(),
      telefones: new FormControl(),
      emails: new FormControl(),
      enderecoDescricao: new FormControl(),
      enderecoComplemento: new FormControl(),
      enderecoLatitude: new FormControl(),
      enderecoLongitude: new FormControl(),
    });

    if (this.route.snapshot.paramMap.get('id')) {
      this.clienteService
        .obter(Number(this.route.snapshot.paramMap.get('id')))
        .subscribe(response => {
          this.abrir(response.data!);
        });
    }
  }

  voltar() {
    this.router.navigate(['/cadastros/clientes']);
  }

  abrir(registro: any) {
    this.registro = registro;
    this.contatos = registro.contatos;
    this.form.reset();
    this.form.setValue({
      id: registro.id,
      ativo: registro.ativo,
      razaoSocial: registro.razaoSocial,
      nomeFantasia: registro.nomeFantasia,
      cnpj: registro.cnpj,
      responsaveis: registro.responsaveis || [],
      telefones: registro.telefones || [],
      emails: registro.emails || [],
      enderecoDescricao: registro.enderecoDescricao,
      enderecoComplemento: registro.enderecoComplemento,
      enderecoLatitude: registro.enderecoLatitude,
      enderecoLongitude: registro.enderecoLongitude,
    });
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    const registro = cloneDeep(this.form.value);

    registro.contatos = this.contatos;

    this.clienteService
      .salvar(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.voltar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }

  novoContato() {
    this.abrirEdicaoContato({
      id: null,
      nome: '',
      email: '',
      telefone: '',
      cargo: '',
    });
  }

  abrirEdicaoContato(contato: any) {
    this.edicaoContato?.abrir(contato);
  }

  contatoAlterado(registro: any) {
    if (this.contatos.indexOf(registro) == -1) {
      this.contatos = [...this.contatos, registro];
    }
  }

  excluirContato(registro: any) {
    this.contatos.splice(this.contatos.indexOf(registro), 1);
  }
}
