import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { TokenService } from "../autorizacao/token.service";
import { TokenStorageService } from "../autorizacao/token-storage.service";
import * as moment from "moment";

@Injectable()
export class JwtRequestHeaderHttpInterceptor implements HttpInterceptor {
    atualizandoRefreshToken: boolean = false;

    constructor(
        private tokenService: TokenService,
        private tokenStorage: TokenStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.tokenStorage.isTokenExpiredOrInvalid()) {
            return next.handle(request);
        }

        const dataExpiracao = new Date(this.tokenStorage.decodedToken.vencimento);

        const tempoParaExpiracaoToken = moment(dataExpiracao).diff(moment(), 'days');
        var tempoParaExpiracaoAtingido = tempoParaExpiracaoToken <= 1 && tempoParaExpiracaoToken > 0;

        if (this.tokenStorage.getManterConectado() &&
            !this.atualizandoRefreshToken &&
            tempoParaExpiracaoAtingido) {
            this.atualizandoRefreshToken = true;
            this.tokenService
                .refresh()
                .subscribe(response => {
                    if (response.sucesso) {
                        this.tokenStorage.setToken(response.data!.bearerToken!, true);
                    }
                    this.atualizandoRefreshToken = false;
                });
        }

        const clonedRequest = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.tokenStorage.getBearerToken()}`
            }
        });

        return next.handle(clonedRequest);
    }
}