<div class="flex justify-content-between flex-wrap">
    <div class="my-2">
        <button pButton class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" label="Voltar"
            (click)="voltar()"></button>
    </div>
    <div class="my-2">
        <button pButton class="p-button-primary p-button-sm" icon="pi pi-save" label="Salvar técnico"
            (click)="confirmar()"></button>
    </div>
</div>

<div class="card" style="margin-top: 20px;">
    <form [formGroup]="form" autocomplete="off">
        <app-validacoes [mensagens]="validacoes"></app-validacoes>
        <input autocomplete="false" name="hidden" type="text" class="hidden">
        <p-tabView>
            <p-tabPanel header="Tecnico">
                <ng-template pTemplate="header">
                    <i class="pi pi-briefcase"></i>
                    <span style="margin-left: 10px;">Técnico</span>
                </ng-template>
                <div class="p-fluid p-formgrid field-sm">
                    <div class="field-checkbox">
                        <p-inputSwitch inputId="ativo" formControlName="ativo"></p-inputSwitch>
                        <label for="ativo">Ativo</label>
                    </div>
                    <div class="field-checkbox ">
                        <p-inputSwitch inputId="mostrarFiltroAgenda"
                            formControlName="mostrarFiltroAgenda"></p-inputSwitch>
                        <label for="mostrarFiltroAgenda">Exibe no filtro Agenda?</label>
                    </div>
                    <div class="p-fluid p-formgrid grid field-sm">
                        <div class="field col-4">
                            <label for="razaoSocial">Razao Social</label>
                            <input type="text" pInputText id="razaoSocial" formControlName="razaoSocial" autofocus />
                            <small class="ng-dirty ng-invalid"
                                *ngIf="razaoSocial.invalid && (razaoSocial.dirty || razaoSocial.touched)">
                                Preencha a Razão Social</small>
                        </div>

                        <div class="field col-4">
                            <label for="cnpj">CNPJ</label>
                            <p-inputMask mask="99.999.999/9999-99" id="cnpj" formControlName="cnpj"
                                placeholder="99.999.999/9999-99"></p-inputMask>
                            <small class="ng-dirty ng-invalid" *ngIf="cnpj.invalid && (cnpj.dirty || cnpj.touched)">
                                Preencha o CNPJ</small>
                        </div>

                        <div class="field col-4">
                            <label for="celular">Celular</label>
                            <p-inputMask mask="(99) 99999-9999" inputId="celular" formControlName="celular"
                                placeholder="(99) 99999-9999"></p-inputMask>
                        </div>

                        <div class="field col-4">
                            <label for="email">E-mail</label>
                            <input type="email" pInputText id="email" formControlName="email" required
                                autocomplete="off" [readonly]="form.controls['id'].value">
                            <small class="ng-dirty ng-invalid" *ngIf="email.invalid && (email.dirty || email.touched)">
                                E-mail inválido</small>
                        </div>

                        <div class="field col-4">
                            <label for="codigoInterno">Código Interno</label>
                            <input type="text" pInputText id="codigoInterno" formControlName="codigoInterno"
                                autofocus />
                        </div>

                        <div class="field col-4">
                            <label for="observacoes">Observações</label>
                            <input type="text" pInputText id="observacoes" formControlName="observacoes" autofocus />
                        </div>

                        <div class="field col-12">
                            <label for="clientes">Clientes</label>
                            <p-multiSelect [options]="clientes" optionValue="id" optionLabel="razaoSocial"
                                formControlName="clientes"></p-multiSelect>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Contatos">
                <ng-template pTemplate="header">
                    <i class="pi pi-book"></i>
                    <span style="margin-left: 10px;">Contato</span>
                </ng-template>

                <p-table class="field col-12">
                    <ng-template pTemplate="caption">
                        <div class="flex justify-content-end flex-wrap">
                            <div>
                                <button pButton class="p-button-primary p-button-sm" icon="pi pi-plus"
                                    label="Novo contato" size="small" (click)="novoContato()"></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Nome</th>
                            <th>Cargo</th>
                            <th>Telefone</th>
                            <th>E-mail</th>
                            <th style="width: 100px"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-registro>
                        <tr>
                            <td>
                                <span class="p-column-title">Nome</span>
                                {{registro.nome}}
                            </td>
                            <td>
                                <span class="p-column-title">Cargo</span>
                                {{registro.cargo}}
                            </td>
                            <td>
                                <span class="p-column-title">Telefone</span>
                                {{registro.telefone}}
                            </td>
                            <td>
                                <span class="p-column-title">E-mail</span>
                                {{registro.email}}
                            </td>
                            <td>
                                <div class="flex">
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-sm p-button-primary mr-2"
                                        (click)="abrirEdicaoContato(registro)"></button>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-sm p-button-danger "
                                        (click)="excluirContato(registro)"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel header="Faturamento">
                <ng-template pTemplate="header">
                    <i class="pi pi-dollar"></i>
                    <span style="margin-left: 10px;">Faturamento</span>
                </ng-template>

                <div class="p-fluid p-formgrid grid field-sm">
                    <div class="field col-6">
                        <label for="valorPorKm">Valor por KM</label>
                        <p-inputNumber formControlName="valorPorKm" inputId="valorPorKm" mode="decimal"
                            [useGrouping]="false" autofocus>
                        </p-inputNumber>
                    </div>

                    <div class="field col-6">
                        <label for="raioAbrangencia">Raio de Abrangência</label>
                        <p-inputNumber formControlName="raioAbrangencia" inputId="raioAbrangencia" mode="decimal"
                            [useGrouping]="false" autofocus>
                        </p-inputNumber>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div class="p-fluid p-formgrid grid field-sm" style="margin-top: 20px;">
            <p-tabView [(activeIndex)]="abaSelecionada" (activeIndexChange)="onAbaSelecionada()">
                <p-tabPanel header="EnderecoPrincipal">
                    <ng-template pTemplate="header">
                        <i class="pi pi-map"></i>
                        <span style="margin-left: 10px;">Endereço Cadastro</span>
                    </ng-template>

                    <div formGroupName="enderecoPrincipal" class="p-fluid p-formgrid grid field-sm">

                        <div class="field col-4">
                            <label for="logradouro">Logradouro</label>
                            <input type="text" pInputText formControlName="logradouro" />
                        </div>

                        <div class="field col-4">
                            <label for="complemento">Complemento</label>
                            <input type="text" pInputText formControlName="complemento" />
                        </div>

                        <div class="field col-4">
                            <label for="numero">Número</label>
                            <input type="text" pInputText formControlName="numero" />
                        </div>

                        <div class="field col-4">
                            <label for="bairro">Bairro</label>
                            <input type="text" pInputText formControlName="bairro" />
                        </div>

                        <div class="field col-4">
                            <label for="cep">CEP</label>
                            <input type="text" pInputText formControlName="cep" />
                        </div>

                        <div class="field col-4">
                            <label for="estado">Estado</label>
                            <input type="text" pInputText formControlName="estado" />
                        </div>

                        <div class="field col-4">
                            <label for="cidade">Cidade</label>
                            <input type="text" pInputText formControlName="cidade" />
                        </div>

                        <div class="field col-4">
                            <label for="latitude">Latitude</label>
                            <p-inputNumber formControlName="latitude" mode="decimal" [minFractionDigits]="2"
                                [maxFractionDigits]="6" [min]="-90" [max]="90"> </p-inputNumber>
                        </div>

                        <div class="field col-4">
                            <label for="longitude">Longitude</label>
                            <p-inputNumber formControlName="longitude" mode="decimal" [minFractionDigits]="2"
                                [maxFractionDigits]="6" [min]="-180" [max]="180">
                            </p-inputNumber>
                        </div>

                        <div class="field col-4">
                            <label for="raioAtuacao">Raio de Atuação (KM)</label>
                            <p-inputNumber formControlName="raioAtuacao" inputId="raioAtuacao" [min]="0"
                                (onInput)="changeRaioAtuacao()"></p-inputNumber>
                        </div>

                        <div class="field col-4" style="margin-top: 35px;">
                            <label for="corTecnico" style="margin-right: 10px;">Cor </label>
                            <p-colorPicker formControlName="corTecnico" inputId="corTecnico"
                                format="hex"></p-colorPicker>
                        </div>

                        <div class="field col-12">
                            <app-busca-endereco (onEnderecoSelecionado)="onSelecao($event)"
                                (onLimparBusca)="limpaBusca()"></app-busca-endereco>
                        </div>

                    </div>

                    <div style="height: 600px; margin-top: 20px; width: 100%;" leaflet [leafletOptions]="optionsAddress"
                        [leafletLayers]="layersAddress" (leafletDoubleClick)="confirmaAddress($event)"
                        *ngIf="abaSelecionada == 0 && optionsAddress" (leafletMapReady)="onMapReady($event)">
                    </div>

                </p-tabPanel>
                <p-tabPanel header="EnderecoEntrega">
                    <ng-template pTemplate="header">
                        <i class="pi pi-map"></i>
                        <span style="margin-left: 10px;">Endereço Entrega</span>
                    </ng-template>

                    <div formGroupName="enderecoEntrega" class="p-fluid p-formgrid grid field-sm">

                        <div class="field col-4">
                            <label>Logradouro</label>
                            <input type="text" pInputText formControlName="logradouro" />
                        </div>

                        <div class="field col-4">
                            <label for="complemento">Complemento</label>
                            <input type="text" pInputText formControlName="complemento" />
                        </div>

                        <div class="field col-4">
                            <label for="numero">Número</label>
                            <input type="text" pInputText formControlName="numero" />
                        </div>

                        <div class="field col-4">
                            <label for="bairro">Bairro</label>
                            <input type="text" pInputText formControlName="bairro" />
                        </div>

                        <div class="field col-4">
                            <label for="cep">CEP</label>
                            <input type="text" pInputText formControlName="cep" />
                        </div>

                        <div class="field col-4">
                            <label for="estado">Estado</label>
                            <input type="text" pInputText formControlName="estado" />
                        </div>

                        <div class="field col-4">
                            <label for="cidade">Cidade</label>
                            <input type="text" pInputText formControlName="cidade" />
                        </div>

                        <div class="field col-4">
                            <label for="latitude">Latitude</label>
                            <p-inputNumber formControlName="latitude" mode="decimal" [minFractionDigits]="2"
                                [maxFractionDigits]="6" [min]="-90" [max]="90"> </p-inputNumber>
                        </div>

                        <div class="field col-4">
                            <label for="longitude">Longitude</label>
                            <p-inputNumber formControlName="longitude" mode="decimal" [minFractionDigits]="2"
                                [maxFractionDigits]="6" [min]="-180" [max]="180">
                            </p-inputNumber>
                        </div>

                        <div class="field col-12">
                            <app-busca-endereco (onEnderecoSelecionado)="onSelecao($event)"
                                (onLimparBusca)="limpaBusca()"></app-busca-endereco>
                        </div>

                    </div>

                    <div style="height: 600px; margin-top: 20px; width: 100%;" leaflet [leafletOptions]="optionsAddress"
                        [leafletLayers]="layersAddress" (leafletDoubleClick)="confirmaAddress($event)"
                        *ngIf="abaSelecionada == 1 && optionsAddress" (leafletMapReady)="onMapReady($event)">
                    </div>

                </p-tabPanel>
            </p-tabView>
        </div>
    </form>
</div>

<app-tecnico-edicao-contato #edicaoContato (onSucesso)="contatoAlterado($event)"></app-tecnico-edicao-contato>