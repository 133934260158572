<app-pergunta-texto *ngIf="pergunta?.detalhesTipo == 1" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-texto>

<app-pergunta-numerico *ngIf="pergunta?.detalhesTipo == 2" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-numerico>

<app-pergunta-verdadeiro-falso *ngIf="pergunta?.detalhesTipo == 3" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-verdadeiro-falso>

<app-pergunta-assinatura *ngIf="pergunta?.detalhesTipo == 4" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-assinatura>

<app-pergunta-foto *ngIf="pergunta?.detalhesTipo == 5" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-foto>

<app-pergunta-data *ngIf="pergunta?.detalhesTipo == 6" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-data>

<app-pergunta-hora *ngIf="pergunta?.detalhesTipo == 7" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-hora>

<app-pergunta-data-hora *ngIf="pergunta?.detalhesTipo == 8" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-data-hora>

<app-pergunta-opcoes-escolha-unica *ngIf="pergunta?.detalhesTipo == 9" [pergunta]="pergunta" [habilitado]="habilitado"
    (onAlteracao)="onRespostaAlterada($event)"></app-pergunta-opcoes-escolha-unica>

<app-pergunta-opcoes-escolha-multipla *ngIf="pergunta?.detalhesTipo == 10" [pergunta]="pergunta"
    [habilitado]="habilitado" (onAlteracao)="onRespostaAlterada($event)">
</app-pergunta-opcoes-escolha-multipla>