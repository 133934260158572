<div class="flex justify-content-between flex-wrap">
    <div class="my-2">
        <button pButton class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" label="Voltar"
            (click)="voltar()"></button>
    </div>
    <div class="my-2">
        <a pButton class="p-button-secondary p-button-sm" icon="pi pi-print" label="Imprimir" [href]="urlRelatorio"
            target="_blank"></a>
        <button pButton class="p-button-primary p-button-sm ml-2" icon="pi pi-file-edit" label="Alterar agendamento"
            (click)="iniciar()" *ngIf="!preenchendo"></button>
        <button pButton class="p-button-primary p-button-sm ml-2" icon="pi pi-save" label="Salvar Agendamento"
            (click)="salvar()" *ngIf="preenchendo"></button>
    </div>
</div>

<p-card styleClass="card-ag">
    <ng-template pTemplate="header">
        <div class="flex justify-content-between flex-wrap pt-3 pl-3 pr-3">
            <div class="p-card-title">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-check']" class="mr-2"></fa-icon>
                AGENDAMENTO #{{registro?.id}}
            </div>
            <div class="p-card-title">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'building-user']" class="mr-2"></fa-icon>
                {{registro.clienteDescricao}}
            </div>
            <div class="p-card-title">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'clock']" class="mr-2"></fa-icon>
                {{registro.dataHora | date:'medium'}}
            </div>
        </div>
    </ng-template>

    <div class="flex flex-column " *ngIf="!preenchendo">
        <div>
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'circle-info']" class="mr-2"></fa-icon>
            <strong class="mr-2">Status</strong>{{registro?.statusAgendamento}}
        </div>
        <div>
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'user']" class="mr-2"></fa-icon>
            <strong>Criado por</strong> {{registro?.criadoPorNomeCompleto}} em {{registro?.criadoEm | date:'short'}}
        </div>
        <div>
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'user']" class="mr-2"></fa-icon>
            <strong>Responsável técnico:</strong> {{registro?.responsavelNomeCompleto}}
        </div>
        <div>
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'list-check']" class="mr-2"></fa-icon>
            <strong>Tipo Agendamento:</strong> {{registro?.tipoDescricao}}
        </div>
        <div>
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'comments']" class="mr-2"></fa-icon>
            <strong>Observações:</strong> {{registro?.descricao}}

        </div>
    </div>

    <div class="p-fluid field-sm p-formgrid grid mt-2" *ngIf="preenchendo">
        <div class="field col-4">
            <label>
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'calendar-check']" class="mr-2"></fa-icon>
                Status:
            </label>
            <p-dropdown [options]="situacoes" optionValue="id" optionLabel="nome" [(ngModel)]="registro.status"
                appendTo="body" [autoDisplayFirst]="false" [filter]="false"></p-dropdown>
        </div>
        <div class="field col-4">
            <label>
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'clock']" class="mr-2"></fa-icon>
                Dt. agendamento:
            </label>
            <p-calendar [(ngModel)]="registro.dataHora" [showTime]="true" [showSeconds]="false"
                appendTo="body"></p-calendar>
        </div>
        <div class="field col-4">
            <label>
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'building-user']" class="mr-2"></fa-icon>
                Cliente:
            </label>
            <p-dropdown inputId="clienteId" [options]="clientes" optionValue="id" optionLabel="razaoSocial"
                [(ngModel)]="registro.clienteId" appendTo="body" [autoDisplayFirst]="false" [filter]="true"
                (ngModelChange)="alteraCliente('clienteId', registro.clienteId)" filterBy="razaoSocial"></p-dropdown>
        </div>
        <div class="field col-4">
            <label>
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'user']" class="mr-2"></fa-icon>
                Responsável técnico:
            </label>
            <p-dropdown inputId="responsavelId" [options]="usuarios" optionValue="id" optionLabel="nomeCompleto"
                [(ngModel)]="registro.responsavelId" appendTo="body" [autoDisplayFirst]="false" [filter]="true"
                filterBy="nomeCompleto"></p-dropdown>
        </div>
        <div class="field col-4">
            <label>
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'list-check']" class="mr-2"></fa-icon>
                Tipo Agendamento:
            </label>
            <p-dropdown [options]="agendamentos" optionValue="id" optionLabel="nome" [(ngModel)]="registro.tipo"
                appendTo="body" [autoDisplayFirst]="false" [filter]="true" [filter]="false"></p-dropdown>
        </div>
        <div class="field col-12">
            <label>
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'comments']" class="mr-2"></fa-icon>
                Observações:
            </label>
            <textarea pInputTextarea [(ngModel)]="registro.descricao" rows="3">
            </textarea>
        </div>
    </div>

    <div class="p-card-title mt-2">
        <fa-icon [fixedWidth]="true" [icon]="['fas', 'clipboard-question']" class="mr-2"></fa-icon>
        Informações do Serviço
    </div>
    <div class="p-fluid field-sm p-formgrid grid mt-1">
        <div class="field col-4">
            <label>
                Endereço de Atendimento:
            </label>
            <input type="text" pInputText [readonly]="!preenchendo"
                [(ngModel)]="registro.informacaoEnderecoAtendimento" />
        </div>
        <div class="field col-4">
            <label>
                Responsável no Local:
            </label>
            <input type="text" pInputText [readonly]="!preenchendo" [(ngModel)]="registro.informacaoResponsavelLocal" />
        </div>
        <div class="field col-4">
            <label>
                Placa:
            </label>
            <input type="text" pInputText [readonly]="!preenchendo" [(ngModel)]="registro.informacaoPlaca" />
        </div>
        <div class="field col-4">
            <label>
                Chamado:
            </label>
            <input type="text" pInputText [readonly]="!preenchendo" [(ngModel)]="registro.informacaoChamado" />
        </div>
        <div class="field col-12">
            <label>
                Sintoma:
            </label>
            <textarea pInputTextarea [readonly]="!preenchendo" [(ngModel)]="registro.informacaoSintoma" rows="3"
                [autoResize]="true">
            </textarea>
        </div>
    </div>

    <div class="p-card-title mt-2">
        <fa-icon [fixedWidth]="true" [icon]="['fas', 'money-bill-1']" class="mr-2"></fa-icon>
        Cobrança
    </div>
    <div class="p-fluid field-sm p-formgrid grid mt-1">
        <div class="field col-4">
            <label>
                Tipo de cobrança:
            </label>
            <p-dropdown [options]="tiposCobranca" optionValue="id" optionLabel="nome" [readonly]="!preenchendo"
                [(ngModel)]="registro.cobrancaTipoCobrancaId" appendTo="body" [autoDisplayFirst]="false" [filter]="true"
                filterBy="nome"></p-dropdown>
        </div>
        <div class="field col-4">
            <label>
                KM percorrido:
            </label>
            <p-inputNumber [(ngModel)]="registro.cobrancaKmPercorrido" [readonly]="!preenchendo"></p-inputNumber>
        </div>
        <div class="field col-4">
            <label>
                Valor de pedágio:
            </label>
            <p-inputNumber [(ngModel)]="registro.cobrancaValorPedagio" mode="decimal" [minFractionDigits]="2"
                [maxFractionDigits]="2" [readonly]="!preenchendo"></p-inputNumber>
        </div>
    </div>
    <div class="p-card-title">
        <fa-icon [fixedWidth]="true" [icon]="['fas', 'location-dot']" class="mr-2"></fa-icon>
        Localização
    </div>
    <div class="p-fluid field-sm p-formgrid grid mt-1">
        <div class="field col-4">
            <label>
                Latitude:
            </label>
            <input type="text" pInputText [readonly]="true" [(ngModel)]="registro.localizacaoLatitude" />
        </div>
        <div class="field col-4">
            <label>
                Longitude:
            </label>
            <input type="text" pInputText [readonly]="true" [(ngModel)]="registro.localizacaoLongitude" />
        </div>
        <div class="field col-4">
            <label>
                Cep:
            </label>
            <input type="text" pInputText [readonly]="true" [(ngModel)]="registro.localizacaoCep" />
        </div>
        <div class="field col-4">
            <label>
                Logradouro:
            </label>
            <input type="text" pInputText [readonly]="true" [(ngModel)]="registro.localizacaoLogradouro" />
        </div>
        <div class="field col-4">
            <label>
                Cidade:
            </label>
            <input type="text" pInputText [readonly]="true" [(ngModel)]="registro.localizacaoCidade" />
        </div>
        <div class="field col-4">
            <label>
                Estado:
            </label>
            <input type="text" pInputText [readonly]="true" [(ngModel)]="registro.localizacaoEstado" />
        </div>
    </div>
</p-card>

<p-card styleClass="card-ag mt-3" *ngFor="let item of registro?.questionarios;">
    <ng-template pTemplate="header">
        <div class="flex justify-content-between flex-wrap pt-3 pl-3 pr-3">
            <div class="p-card-title">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'clipboard-question']" class="mr-2"></fa-icon>
                {{item.questionarioNome}}
            </div>
        </div>
    </ng-template>

    <div class="p-fluid field-sm questionario-form">
        <app-pergunta *ngFor="let pergunta of item.perguntas" [pergunta]="pergunta" [habilitado]="preenchendo"
            (onAlteracao)="salvarResposta($event)"></app-pergunta>
    </div>
</p-card>