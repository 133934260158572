import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { TecnicoService } from 'src/app/services/tecnico.service';
import { TecnicoEdicaoContatoComponent } from '../tecnico-edicao-contato/tecnico-edicao-contato.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import * as L from "leaflet";
import { ConfirmationService } from 'primeng/api';
import { NominatimService } from 'src/app/services/nominatim.service';
import { LatLngExpression } from 'leaflet';

@Component({
  selector: 'app-tecnico-edit',
  templateUrl: './tecnico-edit.component.html',
  styleUrls: ['./tecnico-edit.component.scss']
})

export class TecnicoEditComponent implements OnInit {
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  contatos: any[] = [];
  clientes: any[] = [];
  abaSelecionada: number = 0;
  mapaAtual: L.Map | undefined;
  optionsAddress: any = {};
  layersAddress: any = [];

  @ViewChild('edicaoContato') edicaoContato?: TecnicoEdicaoContatoComponent;

  get ativo() { return this.form.get('ativo')!; }
  get razaoSocial() { return this.form.get('razaoSocial')!; }
  get cnpj() { return this.form.get('cnpj')!; }
  get celular() { return this.form.get('celular')!; }
  get email() { return this.form.get('email')!; }
  get mostrarFiltroAgenda() { return this.form.get('mostrarFiltroAgenda')!; }
  get observacoes() { return this.form.get('observacoes')!; }
  get codigoInterno() { return this.form.get('codigoInterno')!; }
  get raioAtuacao() { return this.form.get('raioAtuacao')!; }
  get corTecnico() { return this.form.get('corTecnico')!; }
  get valorPorKm() { return this.form.get('valorPorKm')!; }
  get raioAbrangencia() { return this.form.get('raioAbrangencia')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private tecnicoService: TecnicoService,
    private router: Router,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private confirmationService: ConfirmationService,
    private nominatimService: NominatimService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      ativo: new FormControl(true),
      razaoSocial: new FormControl(
        '', [
        Validators.required,
        Validators.minLength(4)
      ]),
      cnpj: new FormControl(
        '', [
        Validators.required,
      ]),
      celular: new FormControl('', [
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.required,
      ]),
      mostrarFiltroAgenda: new FormControl(true),
      observacoes: new FormControl(),
      codigoInterno: new FormControl(),
      clientes: new FormControl([], [
        Validators.required,
      ]),
      valorPorKm: new FormControl(0, [
        Validators.required,
      ]),
      raioAbrangencia: new FormControl(0, [
        Validators.required,
      ]),

      enderecoEntrega: new FormGroup({
        logradouro: new FormControl('', [
          Validators.required,
        ]),
        complemento: new FormControl('', [
          Validators.required,
        ]),
        numero: new FormControl('', [
          Validators.required,
        ]),
        bairro: new FormControl('', [
          Validators.required,
        ]),
        cep: new FormControl('', [
          Validators.required,
        ]),
        estado: new FormControl('', [
          Validators.required,
        ]),
        cidade: new FormControl('', [
          Validators.required,
        ]),
        latitude: new FormControl('', [
          Validators.required,
        ]),
        longitude: new FormControl('', [
          Validators.required,
        ]),
      }),

      enderecoPrincipal: new FormGroup({
        logradouro: new FormControl('', [
          Validators.required,
        ]),
        complemento: new FormControl('', [
          Validators.required,
        ]),
        numero: new FormControl('', [
          Validators.required,
        ]),
        bairro: new FormControl('', [
          Validators.required,
        ]),
        cep: new FormControl('', [
          Validators.required,
        ]),
        estado: new FormControl('', [
          Validators.required,
        ]),
        cidade: new FormControl('', [
          Validators.required,
        ]),
        latitude: new FormControl('', [
          Validators.required,
        ]),
        longitude: new FormControl('', [
          Validators.required,
        ]),
        raioAtuacao: new FormControl('', [
          Validators.required,
        ]),
        corTecnico: new FormControl([], [
          Validators.required,
        ]),
      }),
    });

    this.clienteService
      .listar({
        skip: 0,
        take: 1000
      })
      .subscribe(response => {
        this.clientes = response.data!.registros;
      });

    if (this.route.snapshot.paramMap.get('id')) {
      this.tecnicoService
        .obter(Number(this.route.snapshot.paramMap.get('id')))
        .subscribe(response => {
          this.abrir(response.data!);
        });
    } else {
      this.abrir({});
    }

    this.optionsAddress = null;
    this.layersAddress = null;
  }

  voltar() {
    this.router.navigate(['/cadastros/tecnicos']);
  }

  abrir(registro: any) {
    this.registro = registro;
    this.contatos = registro.contatos;

    this.form.reset();
    this.form.setValue({
      id: registro.id ?? null,
      ativo: registro.ativo ?? true,
      razaoSocial: registro.razaoSocial ?? '',
      cnpj: registro.cnpj ?? '',
      celular: registro.celular ?? '',
      email: registro.email ?? '',
      codigoInterno: registro.codigoInterno ?? '',
      observacoes: registro.observacoes ?? '',
      mostrarFiltroAgenda: registro.mostrarFiltroAgenda ?? true,
      clientes: registro.clientes || [],
      raioAbrangencia: registro.raioAbrangencia ?? 0,
      valorPorKm: registro.valorPorKm ?? 0,

      enderecoEntrega: {
        logradouro: registro.enderecoEntrega?.logradouro ?? '',
        complemento: registro.enderecoEntrega?.complemento ?? '',
        numero: registro.enderecoEntrega?.numero ?? '',
        bairro: registro.enderecoEntrega?.bairro ?? '',
        cep: registro.enderecoEntrega?.cep ?? '',
        estado: registro.enderecoEntrega?.estado ?? '',
        cidade: registro.enderecoEntrega?.cidade ?? '',
        latitude: registro.enderecoEntrega?.latitude ?? null,
        longitude: registro.enderecoEntrega?.longitude ?? null,
      },

      enderecoPrincipal: {
        logradouro: registro.enderecoPrincipal?.logradouro ?? '',
        complemento: registro.enderecoPrincipal?.complemento ?? '',
        numero: registro.enderecoPrincipal?.numero ?? '',
        bairro: registro.enderecoPrincipal?.bairro ?? '',
        cep: registro.enderecoPrincipal?.cep ?? '',
        estado: registro.enderecoPrincipal?.estado ?? '',
        cidade: registro.enderecoPrincipal?.cidade ?? '',
        latitude: registro.enderecoPrincipal?.latitude ?? null,
        longitude: registro.enderecoPrincipal?.longitude ?? null,
        raioAtuacao: registro.raioAtuacao ?? 0,
        corTecnico: registro.corTecnico ?? '',
      }
    });

    this.onAbaSelecionada();
  }

  confirmar() {
    this.validacoes = [];

    const registro = cloneDeep(this.form.value);
    registro.contatos = this.contatos;

    this.tecnicoService
      .salvar(registro)
      .subscribe(response => {

        if (response.sucesso) {
          this.voltar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }

  novoContato() {
    this.abrirEdicaoContato({
      id: null,
      nome: '',
      email: '',
      telefone: '',
      cargo: '',
    });
  }

  abrirEdicaoContato(contato: any) {
    this.edicaoContato?.abrir(contato);
  }

  contatoAlterado(registro: any) {
    if (this.contatos.indexOf(registro) == -1) {
      this.contatos = [...this.contatos, registro];
    }
  }

  excluirContato(registro: any) {
    this.contatos.splice(this.contatos.indexOf(registro), 1);
  }

  confirmaAddress(event: any) {
    this.confirmationService.confirm({
      message: 'Deseja confirmar o endereço selecionado?',
      header: 'Confirmar Endereço',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.abaSelecionada == 0) {
          this.nominatimService.obterEnderecoPorLatLong(event.latlng.lat, event.latlng.lng)
            .subscribe(response => {
              this.form.get('enderecoPrincipal.logradouro')?.setValue(response.address.road);
              this.form.get('enderecoPrincipal.complemento')?.setValue(response.address.region ?? response.address.neighbourhood);
              this.form.get('enderecoPrincipal.latitude')?.setValue(response.lat);
              this.form.get('enderecoPrincipal.longitude')?.setValue(response.lon);
              this.form.get('enderecoPrincipal.cep')?.setValue(response.address.postcode);
              this.form.get('enderecoPrincipal.estado')?.setValue(response.address.state);
              this.form.get('enderecoPrincipal.bairro')?.setValue(response.address.quarter ?? response.address.suburb);
              this.form.get('enderecoPrincipal.cidade')?.setValue(response.address.city ?? response.address.town ?? response.address.city_district ?? response.address.municipality);
              this.form.get('enderecoPrincipal.numero')?.setValue(response.address.house_number ?? "")

              this.app.notificacaoSucesso('Endereço cadastrado!');

              this.optionsAddress = {
                layers: [
                  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18,
                    attribution: '...'
                  })
                ],
                zoom: 12,
                center: L.latLng(response.lat, response.lon)
              }

              this.layersAddress = [
                L.marker([this.form.get('enderecoPrincipal.latitude')?.value, this.form.get('enderecoPrincipal.longitude')?.value], {
                  icon: L.icon({
                    ...L.Icon.Default.prototype.options,
                    iconUrl: 'assets/marker-icon.png',
                    iconRetinaUrl: 'assets/marker-icon-2x.png',
                    shadowUrl: 'assets/marker-shadow.png'
                  })
                }).bindPopup(`<strong>${response.address.road}, ${response.address.city ?? response.address.town ?? response.address.city_district} - ${response.address.state}</strong><br/>
                  <strong>Latitude:</strong> ${response.lat}<br/>
                  <strong>Longitude:</strong> ${response.lon}`),
                L.circle([response.lat, response.lon], { radius: this.form.get('enderecoPrincipal.raioAtuacao')?.value * 1000 })
              ];

            });

        } else {

          this.nominatimService.obterEnderecoPorLatLong(event.latlng.lat, event.latlng.lng)
            .subscribe(response => {
              this.form.get('enderecoEntrega.logradouro')?.setValue(response.address.road);
              this.form.get('enderecoEntrega.complemento')?.setValue(response.address.region ?? response.address.neighbourhood);
              this.form.get('enderecoEntrega.latitude')?.setValue(response.lat);
              this.form.get('enderecoEntrega.longitude')?.setValue(response.lon);
              this.form.get('enderecoEntrega.cep')?.setValue(response.address.postcode);
              this.form.get('enderecoEntrega.estado')?.setValue(response.address.state);
              this.form.get('enderecoEntrega.bairro')?.setValue(response.address.quarter ?? response.address.suburb);
              this.form.get('enderecoEntrega.cidade')?.setValue(response.address.city ?? response.address.town ?? response.address.city_district ?? response.address.municipality);
              this.form.get('enderecoEntrega.numero')?.setValue(response.address.house_number ?? "")

              this.app.notificacaoSucesso('Endereço cadastrado!');

              this.optionsAddress = {
                layers: [
                  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18,
                    attribution: '...'
                  })
                ],
                zoom: 12,
                center: L.latLng(response.lat, response.lon)
              }

              this.layersAddress = [
                L.marker([response.lat, response.lon], {
                  icon: L.icon({
                    ...L.Icon.Default.prototype.options,
                    iconUrl: 'assets/marker-icon.png',
                    iconRetinaUrl: 'assets/marker-icon-2x.png',
                    shadowUrl: 'assets/marker-shadow.png'
                  })
                }).bindPopup(`<strong>${response.address.road}, ${response.address.city ?? response.address.town ?? response.address.city_district} - ${response.address.state}</strong><br/>
                <strong>Latitude:</strong> ${response.lat}<br/>
                <strong>Longitude:</strong> ${response.lon}`)
              ];

            });
        }
      }
    });
  }

  onSelecao(event: any) {
    this.mapaAtual!.flyTo([event.latitude, event.longitude], 13, { duration: 1 });
  }

  onAbaSelecionada() {
    var logradouro = '';
    var cidade = '';
    var estado = '';
    var raio = 0;

    if (this.abaSelecionada == 0) {

      this.optionsAddress = null;
      this.layersAddress = null;

      if (this.form.get('enderecoPrincipal')?.value) {

        var lat = this.form.get('enderecoPrincipal.latitude')?.value;
        var lon = this.form.get('enderecoPrincipal.longitude')?.value;
        logradouro = this.form.get('enderecoPrincipal.logradouro')?.value;
        cidade = this.form.get('enderecoPrincipal.cidade')?.value;
        estado = this.form.get('enderecoPrincipal.estado')?.value;
        raio = this.form.get('enderecoPrincipal.raioAtuacao')?.value;

        setTimeout(() => {

          this.optionsAddress = {
            layers: [
              L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: '...'
              })
            ],
            zoom: lat ? 12 : 4.50,
            center: L.latLng(lat ? lat : -16, lon ? lon : -50)
          }

          if (lat != '' && lon != '') {

            this.layersAddress = [
              L.marker([lat, lon], {
                icon: L.icon({
                  ...L.Icon.Default.prototype.options,
                  iconUrl: 'assets/marker-icon.png',
                  iconRetinaUrl: 'assets/marker-icon-2x.png',
                  shadowUrl: 'assets/marker-shadow.png'
                }),
              }).bindPopup(`<strong>${logradouro}, ${cidade} - ${estado}</strong><br/>
                            <strong>Latitude:</strong> ${lat}<br/>
                            <strong>Longitude:</strong> ${lon}`),
              L.circle([lat, lon], { radius: raio * 1000 })
            ];
          }

        }, 500);

      }

    } else {

      var latP = this.form.get('enderecoPrincipal.latitude')?.value;
      var lonP = this.form.get('enderecoPrincipal.longitude')?.value;
      var lat = this.form.get('enderecoEntrega.latitude')?.value;
      var lon = this.form.get('enderecoEntrega.longitude')?.value;
      this.optionsAddress = null;
      this.layersAddress = null;

      if ((latP != '' && lonP != '') && (lat == '' && lon == '')) {

        this.confirmationService.confirm({
          message: 'Deseja cadastrar o mesmo endereço de Cadastro para Entrega?',
          header: 'Cadastrar Endereço de Entrega',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.form.get('enderecoEntrega.logradouro')?.setValue(this.form.get('enderecoPrincipal.logradouro')?.value);
            this.form.get('enderecoEntrega.complemento')?.setValue(this.form.get('enderecoPrincipal.complemento')?.value);
            this.form.get('enderecoEntrega.latitude')?.setValue(this.form.get('enderecoPrincipal.latitude')?.value);
            this.form.get('enderecoEntrega.longitude')?.setValue(this.form.get('enderecoPrincipal.longitude')?.value);
            this.form.get('enderecoEntrega.cep')?.setValue(this.form.get('enderecoPrincipal.cep')?.value);
            this.form.get('enderecoEntrega.estado')?.setValue(this.form.get('enderecoPrincipal.estado')?.value);
            this.form.get('enderecoEntrega.bairro')?.setValue(this.form.get('enderecoPrincipal.bairro')?.value);
            this.form.get('enderecoEntrega.cidade')?.setValue(this.form.get('enderecoPrincipal.cidade')?.value);
            this.form.get('enderecoEntrega.numero')?.setValue(this.form.get('enderecoPrincipal.numero')?.value)

            this.app.notificacaoSucesso('Endereço cadastrado!');

            this.optionsAddress = {
              layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                  maxZoom: 18,
                  attribution: '...'
                })
              ],
              zoom: 12,
              center: L.latLng(this.form.get('enderecoPrincipal.latitude')?.value, this.form.get('enderecoPrincipal.longitude')?.value)
            }

            this.layersAddress = [
              L.marker([this.form.get('enderecoPrincipal.latitude')?.value, this.form.get('enderecoPrincipal.longitude')?.value], {
                icon: L.icon({
                  ...L.Icon.Default.prototype.options,
                  iconUrl: 'assets/marker-icon.png',
                  iconRetinaUrl: 'assets/marker-icon-2x.png',
                  shadowUrl: 'assets/marker-shadow.png'
                })
              }).bindPopup(`<strong>${this.form.get('enderecoEntrega.logradouro')?.value}, ${this.form.get('enderecoEntrega.cidade')?.value} - ${this.form.get('enderecoEntrega.estado')?.value}</strong><br/>
                <strong>Latitude:</strong> ${lat}<br/>
                <strong>Longitude:</strong> ${lon}`)
            ];
          },
          reject: () => {
            this.optionsAddress = {
              layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                  maxZoom: 18,
                  attribution: '...'
                })
              ],
              zoom: 4.50,
              center: L.latLng(-16, -50)
            }
          }
        });
      } else {
        if (this.form.get('enderecoEntrega')?.value) {

          lat = this.form.get('enderecoEntrega.latitude')?.value;
          lon = this.form.get('enderecoEntrega.longitude')?.value;
          logradouro = this.form.get('enderecoEntrega.logradouro')?.value;
          cidade = this.form.get('enderecoEntrega.cidade')?.value;
          estado = this.form.get('enderecoEntrega.estado')?.value;
          raio = this.form.get('enderecoEntrega.raioAtuacao')?.value;

          setTimeout(() => {

            this.optionsAddress = {
              layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                  maxZoom: 18,
                  attribution: '...'
                })
              ],
              zoom: lat != '' ? 12 : 4.50,
              center: L.latLng(lat != '' ? lat : -16, lon != '' ? lon : -50)
            }

            if (lat != '' && lon != '') {

              this.layersAddress = [
                L.marker([lat, lon], {
                  icon: L.icon({
                    ...L.Icon.Default.prototype.options,
                    iconUrl: 'assets/marker-icon.png',
                    iconRetinaUrl: 'assets/marker-icon-2x.png',
                    shadowUrl: 'assets/marker-shadow.png'
                  }),
                }).bindPopup(`<strong>${logradouro}, ${cidade} - ${estado}</strong><br/>
                              <strong>Latitude:</strong> ${lat}<br/>
                              <strong>Longitude:</strong> ${lon}`)
              ];
            }
          }, 500);
        }
      }
    }
  }

  onMapReady(map: L.Map) {
    this.mapaAtual = map;
  }

  changeRaioAtuacao() {
    this.criarMarcadores();
  }

  criarMarcadores() {
    if (this.abaSelecionada == 0) {

      this.layersAddress = [];
      var lat = this.form.get('enderecoPrincipal.latitude')?.value;
      var lon = this.form.get('enderecoPrincipal.longitude')?.value

      if (lat != null && lon != null) {
        var latLong: LatLngExpression = [lat, lon];
        this.layersAddress = [
          L.marker(latLong, {
            icon: L.icon({
              ...L.Icon.Default.prototype.options,
              iconUrl: 'assets/marker-icon.png',
              iconRetinaUrl: 'assets/marker-icon-2x.png',
              shadowUrl: 'assets/marker-shadow.png'
            }),
          }).bindPopup(`<strong>${this.form.get('enderecoPrincipal.logradouro')?.value}, ${this.form.get('enderecoPrincipal.cidade')?.value} - ${this.form.get('enderecoPrincipal.estado')?.value}</strong><br/>
                        <strong>Latitude:</strong> ${lat}<br/>
                        <strong>Longitude:</strong> ${lon}`),
          L.circle(latLong, { radius: this.form.get('enderecoPrincipal.raioAtuacao')?.value * 1000 })
        ];
      }

    } else {
      this.layersAddress = [];
      var lat = this.form.get('enderecoEntrega.latitude')?.value;
      var lon = this.form.get('enderecoEntrega.longitude')?.value

      if (lat != null && lon != null) {
        var latLong: LatLngExpression = [lat, lon];
        this.layersAddress = [
          L.marker(latLong, {
            icon: L.icon({
              ...L.Icon.Default.prototype.options,
              iconUrl: 'assets/marker-icon.png',
              iconRetinaUrl: 'assets/marker-icon-2x.png',
              shadowUrl: 'assets/marker-shadow.png'
            }),
          }).bindPopup(`<strong>${this.form.get('enderecoEntrega.logradouro')?.value}, ${this.form.get('enderecoEntrega.cidade')?.value} - ${this.form.get('enderecoEntrega.estado')?.value}</strong><br/>
                        <strong>Latitude:</strong> ${lat}<br/>
                        <strong>Longitude:</strong> ${lon}`)
        ];
      }

    }
  }

  limpaBusca() {
    if (this.registro.enderecoPrincipalId) {
      if (this.abaSelecionada == 0) {
        this.mapaAtual!.setView(L.latLng(this.form.get('enderecoPrincipal.latitude')?.value, this.form.get('enderecoPrincipal.longitude')?.value), 12, { duration: 1 })
      } else {
        this.mapaAtual!.setView(L.latLng(this.form.get('enderecoEntrega.latitude')?.value, this.form.get('enderecoEntrega.longitude')?.value), 12, { duration: 1 })
      }
    } else {
      this.mapaAtual!.setView(L.latLng(-16, -50), 4.50, { duration: 1 })
    }
  }
}
