import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClienteService } from 'src/app/services/cliente.service';
import { TecnicoService } from 'src/app/services/tecnico.service';

@Component({
  selector: 'app-servico-cobranca-edit',
  templateUrl: './servico-cobranca-edit.component.html',
  styleUrls: ['./servico-cobranca-edit.component.scss']
})
export class ServicoCobrancaEditComponent implements OnInit {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  pessoas: any[] = [];

  constructor(
    private clienteService: ClienteService,
    private tecnicoService: TecnicoService,
  ) { }

  get id() { return this.form.get('id')!; }
  get pessoaId() { return this.form.get('pessoaId')!; }
  get valorCobranca() { return this.form.get('valorCobranca')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      pessoaId: new FormControl(null, []),
      razaoSocial: new FormControl('', []),
      valorCobranca: new FormControl(
        '', [
        Validators.required,
      ]),
      tipoPessoa: new FormControl('', [])
    });
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
    this.form.reset();
  }

  abrir(registro: any) {
    this.pessoas = [];
    this.visivel = true;
    this.registro = registro;

    switch (this.registro.tipoPessoa) {
      case "Cliente":
        this.clienteService
          .listar({
            skip: 0,
            take: 1000,
          }).subscribe(response => {
            this.pessoas = response.data!.registros;
          });
        break;
      case "Técnico":
        this.tecnicoService
          .listar({
            skip: 0,
            take: 1000,
          }).subscribe(response => {
            this.pessoas = response.data!.registros;
          });
        break;
    }

    this.form.reset();
    this.form.setValue(registro);

  }

  confirmar() {
    Object.assign(this.registro, this.form.value);
    this.onSucesso.emit(this.registro);
    this.fechar();
  }

  buscaRazaoSocial(event: any) {
    for(let pessoa of this.pessoas){
      if(pessoa.id == event.value){
        this.form.controls['razaoSocial'] = pessoa.razaoSocial;
      }
    }
  }

}
