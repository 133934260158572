import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/services/autorizacao/token-storage.service';
import { ConfirmationService } from 'primeng/api';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { TarefaService } from 'src/app/services/tarefa.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TipoCobrancaService } from 'src/app/services/tipo-cobranca.service';

@Component({
  selector: 'app-agendamento-edicao',
  templateUrl: './agendamento-edicao.component.html',
  styleUrls: ['./agendamento-edicao.component.scss']
})
export class AgendamentoEdicaoComponent implements OnInit {
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  urlRelatorio: string = '';
  usuarioTipo: number;
  preenchendo: boolean = false;
  usuarios: any[] = [];
  clientes: any[] = [];
  tarefas: any[] = [];
  tiposCobranca: any[] = [];
  returnRoute: string = '';
  returnQueryParams: any = {};
  situacoes: any[] = [{
    id: 1,
    nome: 'Pendente'
  }, {
    id: 2,
    nome: 'Em Andamento'
  }, {
    id: 3,
    nome: 'Agendado'
  }, {
    id: 4,
    nome: 'No-Show Técnico'
  }, {
    id: 5,
    nome: 'Aguardando Validação'
  }, {
    id: 6,
    nome: 'Cancelado'
  }, {
    id: 7,
    nome: 'No-Show Cliente'
  }, {
    id: 8,
    nome: 'Serviço Finalizado'
  }, {
    id: 9,
    nome: 'Finalizado'
  }];
  agendamentos: any[] = [{
    id: 1,
    nome: 'Instalação'
  }, {
    id: 2,
    nome: 'Manutenção'
  }, {
    id: 3,
    nome: 'Desinstalação'
  }];
  alterarCampoSubject: Subject<any> = new Subject<any>();


  constructor(
    private app: AppComponent,
    private route: ActivatedRoute,
    private agendamentoService: AgendamentoService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private confirmationService: ConfirmationService,
    private usuarioService: UsuarioService,
    private clienteService: ClienteService,
    private tarefaService: TarefaService,
    private tipoCobrancaService: TipoCobrancaService,
    private activatedRoute: ActivatedRoute) {
    this.usuarioTipo = this.tokenStorage.getTokenDetalhes()?.usuarioTipo;

    this.alterarCampoSubject
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.agendamentoService
          .salvarCampo(value)
          .subscribe(response => {
          })
      });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
    });

    this.agendamentoService
      .obter(Number(this.route.snapshot.paramMap.get('id')))
      .subscribe(response => {
        this.abrir(response.data!);
      });

    const queryParams = this.activatedRoute.snapshot.queryParams
    if (queryParams['returnRoute']) {
      this.returnRoute = queryParams['returnRoute'];
    }

    if (queryParams['returnQueryParams']) {
      this.returnQueryParams = JSON.parse(queryParams['returnQueryParams']);
    }
  }

  voltar() {
    if (!this.returnRoute) {
      this.router.navigate(['/agendamentos']);
    } else {
      this.router.navigate([this.returnRoute], { queryParams: this.returnQueryParams });
    }
  }

  abrir(registro: any) {
    this.registro = registro;
    this.registro.statusAgendamento = this.situacoes.find(x => x.id == this.registro.status).nome;
    this.urlRelatorio = `${environment.apiUrl}/movimentacao/agendamento/imprimir/${registro.token}/${registro.id}/Agendamento ${registro.id}`;
    this.carregarDependencias();
  }

  carregarDependencias() {
    this.usuarioService
      .listar({
        skip: 0,
        take: 1000
      })
      .subscribe(response => {
        this.usuarios = response.data!.registros;
      });

    this.clienteService
      .listar({
        skip: 0,
        take: 1000
      })
      .subscribe(response => {
        this.clientes = response.data!.registros;
      });

    this.tarefaService
      .listar({
        skip: 0,
        take: 1000
      })
      .subscribe(response => {
        this.tarefas = response.data!.registros;
      });

    this.tipoCobrancaService
      .listar()
      .subscribe(response => {
        this.tiposCobranca = response.data!;
      });
  }

  salvar() {
    this.app.bloquearTela();

    const dto: {
      id: any,
      questionarios: any,
      cobrancaTipoCobrancaId: any,
      localizacaoLatitude: any,
      localizacaoLongitude: any,
      localizacaoEstado: any,
      localizacaoCidade: any,
      localizacaoLogradouro: any,
      localizacaoCep: any,
      cobrancaKmPercorrido: any,
      cobrancaValorPedagio: any,
      informacaoEnderecoAtendimento: any,
      informacaoResponsavelLocal: any,
      informacaoPlaca: any,
      informacaoSintoma: any,
      informacaoChamado: any,
      status: any,
      dataHora: any,
      clienteId: any,
      responsavelId: any,
      tipo: any,
      descricao: any
    } = {
      id: this.registro.id,
      questionarios: [],
      cobrancaTipoCobrancaId: this.registro.cobrancaTipoCobrancaId,
      localizacaoLatitude: this.registro.localizacaoLatitude,
      localizacaoLongitude: this.registro.localizacaoLongitude,
      localizacaoEstado: this.registro.localizacaoEstado,
      localizacaoCidade: this.registro.localizacaoCidade,
      localizacaoLogradouro: this.registro.localizacaoLogradouro,
      localizacaoCep: this.registro.localizacaoCep,
      cobrancaKmPercorrido: this.registro.cobrancaKmPercorrido,
      cobrancaValorPedagio: this.registro.cobrancaValorPedagio,
      informacaoEnderecoAtendimento: this.registro.informacaoEnderecoAtendimento,
      informacaoResponsavelLocal: this.registro.informacaoResponsavelLocal,
      informacaoPlaca: this.registro.informacaoPlaca,
      informacaoSintoma: this.registro.informacaoSintoma,
      informacaoChamado: this.registro.informacaoChamado,
      status: this.registro.status,
      dataHora: this.registro.dataHora,
      clienteId: this.registro.clienteId,
      responsavelId: this.registro.responsavelId,
      tipo: this.registro.tipo,
      descricao: this.registro.descricao
    };

    for (const q of this.registro.questionarios) {
      const questionario: { id: any, perguntas: any } = {
        id: q.id,
        perguntas: []
      };

      dto.questionarios.push(questionario);

      for (const p of q.perguntas) {
        const pergunta: { id: any, resposta: any, arquivos: any[] } = {
          id: p.id,
          resposta: null,
          arquivos: []
        };

        for (const a of p.arquivos) {
          pergunta.arquivos.push({
            arquivoId: a.arquivoId
          });
        }

        questionario.perguntas.push(pergunta);

        if (p.resposta == null ||
          p.resposta == undefined) {
          p.resposta = null;
          continue;
        }

        switch (p.detalhesTipo) {
          case 1:
            pergunta.resposta = p.resposta;
            break;
          case 2:
            pergunta.resposta = p.resposta.toString();
            break;
          case 3:
            pergunta.resposta = p.resposta.toString();
            break;
          case 4:
            pergunta.resposta = JSON.stringify(p.resposta);
            break;
          case 5:
            pergunta.resposta = null;
            break;
          case 6:
            pergunta.resposta = p.resposta.toISOString();
            break;
          case 7:
            pergunta.resposta = p.resposta;
            break;
          case 8:
            pergunta.resposta = p.resposta.toISOString();
            break;
          case 9:
            pergunta.resposta = p.resposta;
            break;
          case 10:
            pergunta.resposta = JSON.stringify(p.resposta);
            break;
        }
      }
    }

    this.agendamentoService
      .salvar(dto)
      .subscribe(response => {
        this.app.desbloquearTela();

        this.voltar();
      });
  }

  iniciar() {
    this.preenchendo = true;
  }

  enviarAprovacao(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Deseja realmente finalizar o preenchimento?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        this.app.bloquearTela();

        this.agendamentoService
          .enviarAprovacao(this.registro.id)
          .subscribe(response => {
            this.app.desbloquearTela();
            this.abrir(response.data);
          });
      },
      reject: () => {
      }
    });
  }

  reprovar(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Deseja realmente reprovar o agendamento?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        this.app.bloquearTela();

        this.agendamentoService
          .reprovar(this.registro.id)
          .subscribe(response => {
            this.app.desbloquearTela();
            this.abrir(response.data);
          });
      },
      reject: () => {
      }
    });
  }

  aprovar(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Deseja realmente aprovar o agendamento?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        this.app.bloquearTela();

        this.agendamentoService
          .aprovar(this.registro.id)
          .subscribe(response => {
            this.app.desbloquearTela();
            this.abrir(response.data);
          });
      },
      reject: () => {
      }
    });
  }

  salvarResposta(pergunta: any) {
    this.agendamentoService
      .salvarResposta({
        questionarioPerguntaId: pergunta.id,
        resposta: pergunta.respostaText
      })
      .subscribe(response => {
      })
  }

  alteraCliente(campo: String, valor: any) {
    if (campo == 'clienteId') {
      this.clienteService
        .obter(Number(valor))
        .subscribe(response => {
          this.registro.clienteDescricao = response.data!.razaoSocial;
        })
    }

  }

}
