import { Component, OnInit, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ClienteEdicaoComponent } from '../cliente-edicao/cliente-edicao.component';
import { ClienteExclusaoComponent } from '../cliente-exclusao/cliente-exclusao.component';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-cliente-view',
  templateUrl: './cliente-view.component.html',
  styleUrls: ['./cliente-view.component.scss']
})
export class ClienteViewComponent implements OnInit {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;

  @ViewChild(Table) dt?: Table;
  @ViewChild('edicao') popupEdicao?: ClienteEdicaoComponent;
  @ViewChild('exclusao') popupExclusao?: ClienteExclusaoComponent;

  constructor(
    private clienteService: ClienteService) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.clienteService
      .listar({
        skip: event.first,
        take: event.rows,
        filtro: event.globalFilter,
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  imprimir() {
  }

  onPesquisar(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupExclusao(registro: any) {
    this.popupExclusao?.abrir(registro);
  }
}
