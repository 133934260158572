import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pergunta-data-hora',
  templateUrl: './pergunta-data-hora.component.html',
  styleUrls: ['./pergunta-data-hora.component.scss']
})
export class PerguntaDataHoraComponent {

  _pergunta: any = {};
  get pergunta(): any {
    return this._pergunta;
  }
  @Input()
  set pergunta(value: any) {
    if (value !== this._pergunta) {
      this._pergunta = value;
    }
  }

  _habilitado: boolean = false;
  get habilitado(): boolean {
    return this._habilitado;
  }
  @Input()
  set habilitado(value: boolean) {
    if (value !== this._habilitado) {
      this._habilitado = value;
    }
  }
  
  @Output()
  onAlteracao: EventEmitter<any> = new EventEmitter<any>();

  respostaAlterada() {
    this.onAlteracao.next({
      id: this.pergunta.id,
      respostaValue: this.pergunta.resposta,
      respostaText: this.pergunta.resposta?.toISOString(),
    });
  }
}
