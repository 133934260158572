import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BloqueioTelaModule } from './components/bloqueio-tela/bloqueio-tela.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtRequestHeaderHttpInterceptor } from 'src/app/services/http-interceptors/http-request-header-http-interceptor';
import { ResponseDateParserHttpInterceptor } from 'src/app/services/http-interceptors/response-date-parser-http-interceptor';
import localePtBr from '@angular/common/locales/pt';
import { CalendarModule } from 'primeng/calendar';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { PerfilAcessoViewComponent } from './components/configuracoes/perfil-acesso/perfil-acesso-view/perfil-acesso-view.component';
import { AgendamentosDashboardViewComponent } from './components/agendamentos/agendamentos-dashboard-view/agendamentos-dashboard-view.component';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { ValidacoesModule } from './components/validacoes/validacoes.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BlockUIModule } from 'primeng/blockui';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PerfilAcessoExclusaoComponent } from './components/configuracoes/perfil-acesso/perfil-acesso-exclusao/perfil-acesso-exclusao.component';
import { PerfilAcessoEdicaoComponent } from './components/configuracoes/perfil-acesso/perfil-acesso-edicao/perfil-acesso-edicao.component';
import { UsuarioViewComponent } from './components/configuracoes/usuario/usuario-view/usuario-view.component';
import { UsuarioEdicaoComponent } from './components/configuracoes/usuario/usuario-edicao/usuario-edicao.component';
import { UsuarioExclusaoComponent } from './components/configuracoes/usuario/usuario-exclusao/usuario-exclusao.component';
import { ClienteViewComponent } from './components/cadastros/cliente/cliente-view/cliente-view.component';
import { ClienteEdicaoComponent } from './components/cadastros/cliente/cliente-edicao/cliente-edicao.component';
import { ClienteExclusaoComponent } from './components/cadastros/cliente/cliente-exclusao/cliente-exclusao.component';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { ChipsModule } from 'primeng/chips';
import { InputNumberModule } from 'primeng/inputnumber';
import { ClienteEdicaoContatoComponent } from './components/cadastros/cliente/cliente-edicao-contato/cliente-edicao-contato.component';
import { QuestionarioViewComponent } from './components/cadastros/questionario/questionario-view/questionario-view.component';
import { QuestionarioEdicaoComponent } from './components/cadastros/questionario/questionario-edicao/questionario-edicao.component';
import { QuestionarioExclusaoComponent } from './components/cadastros/questionario/questionario-exclusao/questionario-exclusao.component';
import { EditorModule } from 'primeng/editor';
import { OrderListModule } from 'primeng/orderlist';
import { TagModule } from 'primeng/tag';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { AppBreadcrumbComponent } from './layout/app.breadcrumb.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AppMenuComponent } from './layout/app.menu.component';
import { AppMenuitemComponent } from './layout/app.menuitem.component';
import { AppProfileSidebarComponent } from './layout/app.profilesidebar.component';
import { AppSidebarComponent } from './layout/app.sidebar.component';
import { AppTopbarComponent } from './layout/app.topbar.component';
import { AppConfigModule } from './layout/config/app.config.module';
import { AgendamentoNovoComponent } from './components/agendamentos/agendamento-novo/agendamento-novo.component';
import { TarefaViewComponent } from './components/cadastros/tarefas/tarefa-view/tarefa-view.component';
import { TarefaEdicaoComponent } from './components/cadastros/tarefas/tarefa-edicao/tarefa-edicao.component';
import { TarefaExclusaoComponent } from './components/cadastros/tarefas/tarefa-exclusao/tarefa-exclusao.component';
import { PickListModule } from 'primeng/picklist';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AgendamentoViewComponent } from './components/agendamentos/agendamento-view/agendamento-view.component';
import { AgendamentoEdicaoComponent } from './components/agendamentos/agendamento-edicao/agendamento-edicao.component';
import { AgendamentoExclusaoComponent } from './components/agendamentos/agendamento-exclusao/agendamento-exclusao.component';
import { PerguntaComponent } from './components/agendamentos/controles/pergunta/pergunta.component';
import { PerguntaTextoComponent } from './components/agendamentos/controles/pergunta-texto/pergunta-texto.component';
import { PerguntaNumericoComponent } from './components/agendamentos/controles/pergunta-numerico/pergunta-numerico.component';
import { PerguntaVerdadeiroFalsoComponent } from './components/agendamentos/controles/pergunta-verdadeiro-falso/pergunta-verdadeiro-falso.component';
import { PerguntaAssinaturaComponent } from './components/agendamentos/controles/pergunta-assinatura/pergunta-assinatura.component';
import { PerguntaFotoComponent } from './components/agendamentos/controles/pergunta-foto/pergunta-foto.component';
import { PerguntaDataComponent } from './components/agendamentos/controles/pergunta-data/pergunta-data.component';
import { PerguntaHoraComponent } from './components/agendamentos/controles/pergunta-hora/pergunta-hora.component';
import { PerguntaDataHoraComponent } from './components/agendamentos/controles/pergunta-data-hora/pergunta-data-hora.component';
import { PerguntaOpcoesEscolhaUnicaComponent } from './components/agendamentos/controles/pergunta-opcoes-escolha-unica/pergunta-opcoes-escolha-unica.component';
import { PerguntaOpcoesEscolhaMultiplaComponent } from './components/agendamentos/controles/pergunta-opcoes-escolha-multipla/pergunta-opcoes-escolha-multipla.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { PerguntaAssinaturaPopupComponent } from './components/agendamentos/controles/pergunta-assinatura-popup/pergunta-assinatura-popup.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AgendamentoRptClienteComponent } from './components/agendamentos/agendamento-rpt-cliente/agendamento-rpt-cliente.component';
import { NotificacaoComponent } from './components/notificacao/notificacao.component';
import { AgendamentoSituacaoPipe } from './pipes/agendamento-situacao.pipe';
import { TagSituacaoAgendamentoComponent } from './components/agendamentos/controles/tag-situacao-agendamento/tag-situacao-agendamento.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ListboxModule } from 'primeng/listbox';
import { TecnicoViewComponent } from './components/cadastros/tecnicos/tecnico-view/tecnico-view.component';
import { TecnicoEditComponent } from './components/cadastros/tecnicos/tecnico-edit/tecnico-edit.component';
import { TecnicoEdicaoContatoComponent } from './components/cadastros/tecnicos/tecnico-edicao-contato/tecnico-edicao-contato.component';
import { TecnicoExclusaoComponent } from './components/cadastros/tecnicos/tecnico-exclusao/tecnico-exclusao.component';
import { UsuarioTipoPipe } from './pipes/usuario-tipo.pipe';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ColorPickerModule } from 'primeng/colorpicker';
import { RedeAutorizadaComponent } from './components/rede-autorizada/rede-autorizada.component';
import { TabViewModule } from 'primeng/tabview';
import { BuscaEnderecoComponent } from './components/busca-endereco/busca-endereco.component';
import { ServicoEditComponent } from './components/cadastros/servicos/servico-edit/servico-edit.component';
import { ServicoViewComponent } from './components/cadastros/servicos/servico-view/servico-view.component';
import { ServicoExclusaoComponent } from './components/cadastros/servicos/servico-exclusao/servico-exclusao.component';
import { ServicoCobrancaEditComponent } from './components/cadastros/servicos/servico-cobranca-edit/servico-cobranca-edit.component';

registerLocaleData(localePtBr);

@NgModule({
    declarations: [
        AppComponent,
        AgendamentosDashboardViewComponent,
        PerfilAcessoViewComponent,
        PerfilAcessoEdicaoComponent,
        PerfilAcessoExclusaoComponent,
        UsuarioViewComponent,
        UsuarioEdicaoComponent,
        UsuarioExclusaoComponent,
        ClienteViewComponent,
        ClienteEdicaoComponent,
        ClienteExclusaoComponent,
        ClienteEdicaoContatoComponent,
        QuestionarioViewComponent,
        QuestionarioEdicaoComponent,
        QuestionarioExclusaoComponent,
        AppLayoutComponent,
        AppBreadcrumbComponent,
        AppSidebarComponent,
        AppTopbarComponent,
        AppProfileSidebarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AgendamentoNovoComponent,
        TarefaViewComponent,
        TarefaEdicaoComponent,
        TarefaExclusaoComponent,
        AgendamentoViewComponent,
        AgendamentoEdicaoComponent,
        AgendamentoExclusaoComponent,
        PerguntaComponent,
        PerguntaTextoComponent,
        PerguntaNumericoComponent,
        PerguntaVerdadeiroFalsoComponent,
        PerguntaAssinaturaComponent,
        PerguntaFotoComponent,
        PerguntaDataComponent,
        PerguntaHoraComponent,
        PerguntaDataHoraComponent,
        PerguntaOpcoesEscolhaUnicaComponent,
        PerguntaOpcoesEscolhaMultiplaComponent,
        PerguntaAssinaturaPopupComponent,
        AgendamentoRptClienteComponent,
        NotificacaoComponent,
        AgendamentoSituacaoPipe,
        TagSituacaoAgendamentoComponent,
        TecnicoViewComponent,
        TecnicoEditComponent,
        TecnicoEdicaoContatoComponent,
        TecnicoExclusaoComponent,
        UsuarioTipoPipe,
        RedeAutorizadaComponent,
        BuscaEnderecoComponent,
        ServicoEditComponent,
        ServicoViewComponent,
        ServicoExclusaoComponent,
        ServicoCobrancaEditComponent
    ],
    imports: [
        AppRoutingModule,
        BloqueioTelaModule,
        CalendarModule,
        FullCalendarModule,
        FontAwesomeModule,
        ToastModule,
        ToolbarModule,
        InputSwitchModule,
        TableModule,
        FormsModule,
        MessagesModule,
        ValidacoesModule,
        InputTextModule,
        DialogModule,
        ReactiveFormsModule,
        BrowserModule,
        BlockUIModule,
        DividerModule,
        DropdownModule,
        MultiSelectModule,
        FieldsetModule,
        InputMaskModule,
        ChipsModule,
        InputNumberModule,
        EditorModule,
        OrderListModule,
        TagModule,
        CheckboxModule,
        RadioButtonModule,
        CardModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SidebarModule,
        BadgeModule,
        TooltipModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        PickListModule,
        InputTextareaModule,
        FileUploadModule,
        AngularSignaturePadModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        ContextMenuModule,
        ListboxModule,
        LeafletModule,
        AutoCompleteModule,
        ColorPickerModule,
        TabViewModule,
    ],
    providers: [
        MessageService,
        ConfirmationService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtRequestHeaderHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseDateParserHttpInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: "BRL" }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far);
        registerLocaleData(localePtBr);
    }
}
