import { Component, Input } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-validacoes',
  templateUrl: './validacoes.component.html',
  styleUrls: ['./validacoes.component.scss']
})
export class ValidacoesComponent {
  protected messages: Message[] = [];

  get styleClassCompleto(): string {
    if (this._styleClass) {
      return `${this._styleClass} validacoes`;
    }

    return 'validacoes';
  }
  
  _styleClass: string = '';
  get styleClass(): string {
    return this._styleClass;
  }
  @Input()
  set styleClass(value: string) {
    if (value !== this._styleClass) {
      this._styleClass = value;
    }
  }

  private _mensagens: string[] = [];
  get mensagens(): string[] {
    return this._mensagens;
  }
  @Input()
  set mensagens(value: string[]) {
    if (!value || value.length == 0) {
      this.messages = [];
      return;
    }

    if (value !== this.mensagens) {
      this._mensagens = value;
      this.messages = [];

      if (this.mensagens.length == 1) {
        this.messages.push({
          severity: 'error',
          summary: this.mensagens[0]
        });
      } else {
        const mensagemHtml = [];
        mensagemHtml.push('<ul>');
        for (const erro of this.mensagens ?? []) {
          mensagemHtml.push(`<li>${erro}</li>`)
        }
        mensagemHtml.push('</ul>');

        this.messages.push({
          severity: 'error',
          summary: 'Verifique as mensagens abaixo e tente novamente',
          detail: mensagemHtml.join('')
        });
      }
    }
  }

}
