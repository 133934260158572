<div class="grid">
    <div class="col-12">
        <div class="card px-5 py-5">
            <p-table #dt [value]="registros" [lazy]="true" (onLazyLoad)="carregarRegistros($event)"
                [totalRecords]="totalRegistros" [loading]="carregando" responsiveLayout="scroll"
                [globalFilterFields]="['emailUsername','nomeCompleto']" [rows]="10" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} de {totalRecords} registros" [(selection)]="selecionados"
                selectionMode="single" [rowHover]="true" dataKey="id" styleClass="p-datatable-sm">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onPesquisar(dt, $event)" placeholder="Pesquisar..."
                                class="w-full sm:w-auto" />
                        </span>
                        <div class="my-2">
                            <button (click)="abrirPopupNovo()" pButton class="p-button-outlined" icon="pi pi-plus"
                                label="Novo perfil de acesso"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Perfil de acesso </th>
                        <th style="width: 120px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registro>
                    <tr>
                        <td>
                            <span class="p-column-title">Perfil</span>
                            {{registro.nome}}
                        </td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mr-2 p-button-sm"
                                    (click)="abrirPopupEdicao(registro)"></button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger p-button-sm "
                                    (click)="abrirPopupExclusao(registro)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-perfil-acesso-edicao #edicao (onSucesso)="registroAlterado($event)"></app-perfil-acesso-edicao>
<app-perfil-acesso-exclusao #exclusao (onSucesso)="registroAlterado($event)"></app-perfil-acesso-exclusao>