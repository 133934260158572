import { Component, EventEmitter, Output } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AgendamentoService } from 'src/app/services/agendamento.service';

@Component({
  selector: 'app-agendamento-exclusao',
  templateUrl: './agendamento-exclusao.component.html',
  styleUrls: ['./agendamento-exclusao.component.scss']
})
export class AgendamentoExclusaoComponent {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private agendamentoService: AgendamentoService) {
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    this.agendamentoService
      .excluir(this.registro.id)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.onSucesso.emit(this.registro);
          this.fechar();
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }
}