<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="ml-3">
                <button pButton type="button" icon="pi pi-calendar-plus"
                    class="p-button-text p-button-secondary p-button-rounded flex-shrink-0"
                    (click)="abrirPopupNovoAgendamento()" label="Novo agendamento"></button>
            </li>
            <li class="ml-3">
                <button pButton type="button" icon="pi pi-cog"
                    class="p-button-text p-button-secondary p-button-rounded flex-shrink-0"
                    (click)="onProfileButtonClick()"></button>
            </li>
        </ul>
    </div>
</div>

<app-agendamento-novo #popupNovoAgendamento (onSucesso)="onNovo($event)"></app-agendamento-novo>