import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { PerfilAcessoService } from 'src/app/services/perfil-acesso.service';
import { cloneDeep } from "lodash";

@Component({
  selector: 'app-perfil-acesso-edicao',
  templateUrl: './perfil-acesso-edicao.component.html',
  styleUrls: ['./perfil-acesso-edicao.component.scss']
})
export class PerfilAcessoEdicaoComponent implements OnInit {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;

  permissoesPadrao: any[] = [
    {
      key: 0,
      value: 'Nenhum'
    },
    {
      key: 1,
      value: 'Pode cadastrar e editar registros'
    },
    {
      key: 3,
      value: 'Pode cadastrar, editar e excluir registros'
    }];

  permissoesAgendamento: any[] = [{
    key: 1,
    value: 'Agendar'
  }, {
    key: 2,
    value: 'Editar'
  }, {
    key: 4,
    value: 'Excluir'
  }, {
    key: 8,
    value: 'Executar'
  }];

  get nome() { return this.form.get('nome')!; }
  get acessoColaboradores() { return this.form.get('acessoColaboradores')!; }
  get acessoPerfisAcesso() { return this.form.get('acessoPerfisAcesso')!; }
  get acessoClientes() { return this.form.get('acessoClientes')!; }
  get acessoQuestionarios() { return this.form.get('acessoQuestionarios')!; }
  get acessoTiposAgendamento() { return this.form.get('acessoTiposAgendamento')!; }
  get acessoListas() { return this.form.get('acessoListas')!; }
  get acessoAgendamento() { return this.form.get('acessoAgendamento')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private perfilAcessoService: PerfilAcessoService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      nome: new FormControl(
        '', [
        Validators.required,
        Validators.minLength(4)
      ]),
      acessoColaboradores: new FormControl(0),
      acessoPerfisAcesso: new FormControl(0),
      acessoClientes: new FormControl(0),
      acessoQuestionarios: new FormControl(0),
      acessoTiposAgendamento: new FormControl(0),
      acessoListas: new FormControl(0),
      acessoAgendamento: new FormControl([]),
    });
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
    this.form.reset();
    this.form.setValue({
      id: registro.id,
      nome: registro.nome,
      acessoColaboradores: registro.acessoColaboradores.reduce((sum: any, current: any) => sum + current, 0),
      acessoPerfisAcesso: registro.acessoPerfisAcesso.reduce((sum: any, current: any) => sum + current, 0),
      acessoClientes: registro.acessoClientes.reduce((sum: any, current: any) => sum + current, 0),
      acessoQuestionarios: registro.acessoQuestionarios.reduce((sum: any, current: any) => sum + current, 0),
      acessoTiposAgendamento: registro.acessoTiposAgendamento.reduce((sum: any, current: any) => sum + current, 0),
      acessoListas: registro.acessoListas.reduce((sum: any, current: any) => sum + current, 0),
      acessoAgendamento: registro.acessoAgendamento,
    });
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    const registro = cloneDeep(this.form.value);
    registro.acessoAgendamento = registro.acessoAgendamento.reduce((sum: any, current: any) => sum + current, 0);

    this.perfilAcessoService
      .salvar(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.fechar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }
}

