import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PerguntaAssinaturaPopupComponent } from '../pergunta-assinatura-popup/pergunta-assinatura-popup.component';
var b64toBlob = require('b64-to-blob');

@Component({
  selector: 'app-pergunta-assinatura',
  templateUrl: './pergunta-assinatura.component.html',
  styleUrls: ['./pergunta-assinatura.component.scss']
})
export class PerguntaAssinaturaComponent {

  _pergunta: any = {};
  get pergunta(): any {
    return this._pergunta;
  }
  @Input()
  set pergunta(value: any) {
    if (value !== this._pergunta) {
      this._pergunta = value;
      if (this._pergunta.resposta.imagem){
        var blob = b64toBlob(this._pergunta.resposta.imagem, 'image/png');
        this.pergunta.resposta.imagemUrl = URL.createObjectURL(blob);
      } else{
        this.pergunta.resposta.imagemUrl = null;
      }
    }
  }

  @ViewChild('edicao') popupEdicao?: PerguntaAssinaturaPopupComponent;

  abrirPopupAssinatura() {
    if (!this.habilitado) {
      return;
    }

    this.popupEdicao?.abrir(this._pergunta);
  }

  _habilitado: boolean = false;
  get habilitado(): boolean {
    return this._habilitado;
  }
  @Input()
  set habilitado(value: boolean) {
    if (value !== this._habilitado) {
      this._habilitado = value;
    }
  }
  
  @Output()
  onAlteracao: EventEmitter<any> = new EventEmitter<any>();

  respostaAlterada() {
    debugger
    this.onAlteracao.next({
      id: this.pergunta.id,
      respostaValue: this.pergunta.resposta,
      respostaText: JSON.stringify(this.pergunta.resposta)
    });
  }
}
