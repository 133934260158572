import { Component, Output, EventEmitter } from '@angular/core';
import { NominatimService } from 'src/app/services/nominatim.service';
import * as L from "leaflet";
import { FormGroup } from '@angular/forms';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-busca-endereco',
  templateUrl: './busca-endereco.component.html',
  styleUrls: ['./busca-endereco.component.scss']
})

export class BuscaEnderecoComponent {
  suggestions: any[] = [];

  constructor(
    private nominatimService: NominatimService
  ) { }

  @Output()
  onEnderecoSelecionado: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onLimparBusca: EventEmitter<any> = new EventEmitter();

  onSelect(event: any) {
    this.onEnderecoSelecionado.next(event);
  }

  limpaBusca() {
    this.onLimparBusca.next(null)
  }

  search(event: AutoCompleteCompleteEvent) {
    const tiposIgnorar = ['city_district'];
    this.nominatimService.obterEnderecoPorDescricao(event.query)
      .subscribe(response => {
        var registros = response.filter(x => tiposIgnorar.indexOf(x.addresstype) == -1)
          .map((obj) => {
            let registro = {
              cidade: `${obj.address.city ?? obj.address.municipality}`,
              nomeRua: `${obj.address.road ?? ""}`,
              latitude: `${obj.lat ?? ""}`,
              longitude: `${obj.lon ?? ""}`,
              cep: `${obj.address.postcode ?? ""}`,
              estado: `${obj.address.state ?? ""}`,
              bairro: `${obj.address.quarter ?? obj.address.suburb ?? ""}`,
              complemento: `${obj.address.region ?? ""}`,
              displayAdress: ''
            };

            switch (obj.addresstype) {
              case 'hamlet':
              case 'municipality':
              case 'city':
              case 'town':
              case 'village':
              case 'city_district':
                registro.displayAdress = `${obj.address.hamlet ?? obj.address.village ?? obj.address.town ?? obj.address.city ?? obj.address.municipality} - ${obj.address.state}`;
                break;
              case 'country':
                registro.displayAdress = `${obj.address.country}`;
                break;
              case 'suburb':
                registro.displayAdress = `${obj.address.suburb}, ${obj.address.town ?? obj.address.city ?? obj.address.city_district ?? ""} - ${obj.address.state}`;
                break;
              case 'state':
                registro.displayAdress = `${obj.address.state} - ${obj.address.country}`;
                break;
              case 'road':
              case 'craft':
                registro.displayAdress = `${obj.address.road}, ${obj.address.suburb ?? obj.address.city ?? obj.address.city_district ?? obj.address.town ?? obj.address.village ?? ""} - ${obj.address.state}`;
                break;
              case 'shop':
                registro.displayAdress = `${obj.address.shop}, ${obj.address.road} ${obj.address.suburb} ${obj.address.city ?? obj.address.city_district} - ${obj.address.state}`;
                break;
              case 'amenity':
              case 'aeroway':
                registro.displayAdress = `${obj.address.amenity ?? obj.address.aeroway}, ${obj.address.road} ${obj.address.suburb ?? ""} ${obj.address.city ?? obj.address.city_district} - ${obj.address.state}`;
                break;
              case 'quarter':
                registro.displayAdress = `${obj.address.quarter}, ${obj.address.village} ${obj.address.city ?? obj.address.city_district} - ${obj.address.state}`;
                break;
              case 'residential':
                registro.displayAdress = `${obj.address.residential}, ${obj.address.city ?? obj.address.city_district} - ${obj.address.state}`;
                break;
              case 'building':
              case 'place':
                registro.displayAdress = `${obj.address.building ?? obj.address.place}.${obj.address.road ?? ""} ${obj.address.city ?? obj.address.city_district ?? obj.address.town} - ${obj.address.state}`;
                break;
              case 'neighbourhood':
              case 'retail':
              case 'commercial':
              case 'farm':
              case 'leisure':
              case 'square':
              case 'locality':
              case 'industrial':
              case 'city_block':
                registro.displayAdress = `${obj.address.retail ?? obj.address.neighbourhood ?? obj.address.commercial ?? obj.address.locality ?? obj.address.farm ?? obj.address.leisure ?? obj.address.square ?? 
                  obj.address.industrial ?? obj.address.city_block} ${obj.address.suburb ?? ""}, ${obj.address.town ?? obj.address.city ?? obj.address.city_district} - ${obj.address.state}`;
                break;
              default:
                break;
            }

            return registro;

          });

        this.suggestions = [...registros];
      });
  }
}
