import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BloqueioTelaComponent } from './bloqueio-tela.component';


@NgModule({
  declarations: [BloqueioTelaComponent],
  imports: [
    CommonModule,
    BlockUIModule,
    ProgressSpinnerModule
  ],
  exports: [BloqueioTelaComponent]
})
export class BloqueioTelaModule { }
