import { Component, Input } from '@angular/core';
import { DefaultTitleStrategy } from '@angular/router';

@Component({
  selector: 'app-tag-situacao-agendamento',
  templateUrl: './tag-situacao-agendamento.component.html',
  styleUrls: ['./tag-situacao-agendamento.component.scss']
})
export class TagSituacaoAgendamentoComponent {
  severity: string = 'info';

  _situacao: number = 0;
  get situacao(): number {
    return this._situacao;
  }
  @Input()
  set situacao(value: number) {
    if (value !== this._situacao) {
      this._situacao = value;
      this.setSeverity();
    }
  }

  _styleClass: string = '';
  get styleClass(): string {
    return this._styleClass;
  }
  @Input()
  set styleClass(value: string) {
    if (value !== this._styleClass) {
      this._styleClass = value;
    }
  }

  setSeverity() {
    switch (this.situacao) {
      case 1:
        this.severity = 'info';
        break;
      case 2:
        this.severity = 'primary';
        break;
      case 3:
        this.severity = 'success';
        break;
      case 4:
      case 6:
        this.severity = 'danger';
        break;
      case 5:
        this.severity = 'warning';
        break;
      default:
        this.severity = 'info';
        break;
    }
  }
}
