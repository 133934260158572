import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { QuestionarioService } from 'src/app/services/questionario.service';
import { TarefaService } from 'src/app/services/tarefa.service';

@Component({
  selector: 'app-tarefa-edicao',
  templateUrl: './tarefa-edicao.component.html',
  styleUrls: ['./tarefa-edicao.component.scss']
})
export class TarefaEdicaoComponent implements OnInit {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  questionariosDisponiveisExterno: any[] = [];
  questionariosSelecionadosExterno: any[] = [];
  questionariosDisponiveisInterno: any[] = [];
  questionariosSelecionadosInterno: any[] = [];
  tiposResponsavel: any[] = [{
    key: 1,
    value: 'Analista interno'
  }, {
    key: 2,
    value: 'Técnico'
  }];

  get ativo() { return this.form.get('ativo')!; }
  get nome() { return this.form.get('nome')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private tarefaService: TarefaService,
    private route: ActivatedRoute,
    private router: Router,
    private questionarioService: QuestionarioService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      ativo: new FormControl(true),
      nome: new FormControl(
        '', [
        Validators.required,
        Validators.minLength(4)
      ]),
    });

    if (this.route.snapshot.paramMap.get('id')) {
      this.tarefaService
        .obter(Number(this.route.snapshot.paramMap.get('id')))
        .subscribe(response => {
          this.abrir(response.data!);
        });
    } else {
      this.abrir({
        id: null,
        ativo: true,
        nome: '',
      });
    }
  }

  voltar() {
    this.router.navigate(['/cadastros/tarefas']);
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
    this.form.reset();
    this.form.setValue({
      id: registro.id,
      ativo: registro.ativo,
      nome: registro.nome,
    });

    this.questionarioService
      .listar({
        skip: 0,
        take: 100
      })
      .subscribe(response => {
        this.registro.questionarios = this.registro.questionarios || [];

        const externo = cloneDeep(response.data!.registros);
        const interno = cloneDeep(response.data!.registros);

        for (const q of this.registro.questionarios) {
          if (q.tipoResponsavel == 1) {
            const r = interno.filter((x: { id: any; }) => x.id == q.questionarioId)[0];
            r.selecionado = true;
            this.questionariosSelecionadosInterno = [...this.questionariosSelecionadosInterno, r];
          } else if (q.tipoResponsavel == 2) {
            const r = externo.filter((x: { id: any; }) => x.id == q.questionarioId)[0];
            r.selecionado = true;
            this.questionariosSelecionadosExterno = [...this.questionariosSelecionadosExterno, r];
          }
        }

        for (const r of externo) {
          if (!r.selecionado) {
            this.questionariosDisponiveisExterno = [...this.questionariosDisponiveisExterno, r];
          }
        }

        for (const r of interno) {
          if (!r.selecionado) {
            this.questionariosDisponiveisInterno = [...this.questionariosDisponiveisInterno, r];
          }
        }
      });
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    const registro = this.form.value;

    registro.questionarios = [];
    for (const questionario of this.questionariosSelecionadosExterno) {
      registro.questionarios.push({
        ordem: this.questionariosSelecionadosExterno.indexOf(questionario),
        questionarioId: questionario.id,
        tipoResponsavel: 2
      });
    }

    for (const questionario of this.questionariosSelecionadosInterno) {
      registro.questionarios.push({
        ordem: this.questionariosSelecionadosInterno.indexOf(questionario),
        questionarioId: questionario.id,
        tipoResponsavel: 1
      });
    }

    this.tarefaService
      .salvar(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.voltar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }
}
