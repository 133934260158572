<p-dialog #edicao [(visible)]="visivel" [style]="{width: '75vw'}" header="Perfil de acesso" [modal]="true"
    class="p-fluid">
    <ng-template pTemplate="content">
        <app-validacoes [mensagens]="validacoes"></app-validacoes>

        <form [formGroup]="form" autocomplete="off">
            <input autocomplete="false" name="hidden" type="text" class="hidden">

            <div class="field">
                <label for="nome">Nome</label>
                <input type="text" pInputText id="nome" formControlName="nome" autofocus />
                <small class="ng-dirty ng-invalid" *ngIf="nome.invalid && (nome.dirty || nome.touched)">
                    Nome deve ter pelo menos 4 caracteres</small>
            </div>

            <p-divider>
                <div class="inline-flex align-items-center">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'lock-open']"></fa-icon>
                    <b class="ml-2">Permissões de acesso</b>
                </div>
            </p-divider>

            <div class="p-fluid p-formgrid grid grid-permissoes">
                <div class="field col-4">
                    <label for="acessoColaboradores">Colaboradores:</label>
                    <p-dropdown id="acessoColaboradores" [options]="permissoesPadrao" optionValue="key"
                        optionLabel="value" formControlName="acessoColaboradores" appendTo="body"></p-dropdown>
                </div>
                <div class="field col-4">
                    <label for="acessoPerfisAcesso">Perfil de acesso:</label>
                    <p-dropdown id="acessoPerfisAcesso" [options]="permissoesPadrao" optionValue="key"
                        optionLabel="value" formControlName="acessoPerfisAcesso" appendTo="body"></p-dropdown>
                </div>
                <div class="field col-4">
                    <label for="acessoClientes">Clientes:</label>
                    <p-dropdown id="acessoClientes" [options]="permissoesPadrao" optionValue="key" optionLabel="value"
                        formControlName="acessoClientes" appendTo="body"></p-dropdown>
                </div>
                <div class="field col-4">
                    <label for="acessoQuestionarios">Questionários:</label>
                    <p-dropdown id="acessoQuestionarios" [options]="permissoesPadrao" optionValue="key"
                        optionLabel="value" formControlName="acessoQuestionarios" appendTo="body"></p-dropdown>
                </div>
                <div class="field col-4">
                    <label for="acessoTiposAgendamento">Tipos de agendamentos:</label>
                    <p-dropdown id="acessoTiposAgendamento" [options]="permissoesPadrao" optionValue="key"
                        optionLabel="value" formControlName="acessoTiposAgendamento" appendTo="body"></p-dropdown>
                </div>
                <div class="field col-4">
                    <label for="acessoListas">Listas:</label>
                    <p-dropdown id="acessoListas" [options]="permissoesPadrao" optionValue="key" optionLabel="value"
                        formControlName="acessoListas" appendTo="body"></p-dropdown>
                </div>
                <div class="field col-12">
                    <label for="acessoAgendamento">Agendamentos:</label>
                    <p-multiSelect id="acessoAgendamento" [options]="permissoesAgendamento" optionValue="key"
                        optionLabel="value" formControlName="acessoAgendamento" appendTo="body"></p-multiSelect>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-between flex-wrap">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-warning p-button-text"
                (click)="fechar()"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-primary p-button-text"
                (click)="confirmar()" [disabled]="!form.valid"></button>
        </div>
    </ng-template>
</p-dialog>