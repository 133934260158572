<div class="card py-3 px-3 overflow-auto">
	<div
		class="flex flex-column align-items-start md:flex-row md:align-items-center md:justify-content-between border-bottom-1 surface-border pb-5 min-w-max">
		<div class="flex flex-column">
			<div class="my-3 text-4xl font-bold text-900">Asmontec Technologies LTDA - ME</div>
			<span class="mb-2"><b>Telefone:</b> 19983950042</span>
			<span class="mb-2"><b>CNPJ:</b> 23.172.564/0001-07</span>
			<span class="mb-2"><b>Email:</b> suporte@asmontech.com.br</span>
			<span class="mb-2"><b>Endereço:</b> Rua Mafalda Maria Imperato Pinhata, nº 196, Bairro Nova Vinhedo Vinhedo - SP - CEP 13.284-076</span>
		</div>
		<div class="flex flex-column mt-5 md:mt-0">
			<div class="flex flex-column">
				<img [src]="'assets/layout/images/asmontech-logo.svg'" width="300" />
			</div>
		</div>
	</div>

	<div class="mt-5 mb-8 flex flex-column">
		<div class="mb-3 text-2xl font-medium">BILL TO</div>
		<span class="mb-2">Claire Williams, 148 Hope Lane</span>
		<span>Palo Alto, CA 94304.</span>
	</div>

	<div class="overflow-x-auto">
		<table class="w-full" style="border-collapse: collapse; table-layout: auto">
			<thead>
				<tr>
					<th class="text-left font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">
						Description</th>
					<th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">
						Quantity</th>
					<th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">
						Unit Price</th>
					<th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">Line
						Total</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="text-left py-3 border-bottom-1 surface-border white-space-nowrap">Green T-Shirt</td>
					<td class="text-right py-3 border-bottom-1 surface-border px-3">1</td>
					<td class="text-right py-3 border-bottom-1 surface-border px-3">$49.00</td>
					<td class="text-right py-3 border-bottom-1 surface-border">$49.00</td>
				</tr>
				<tr>
					<td class="text-left py-3 border-bottom-1 surface-border white-space-nowrap">Game Controller</td>
					<td class="text-right py-3 border-bottom-1 surface-border px-3">2</td>
					<td class="text-right py-3 border-bottom-1 surface-border px-3">$99.00</td>
					<td class="text-right py-3 border-bottom-1 surface-border">$198.00</td>
				</tr>
				<tr>
					<td class="text-left py-3 border-bottom-1 surface-border white-space-nowrap">Mini Speakers</td>
					<td class="text-right py-3 border-bottom-1 surface-border px-3">1</td>
					<td class="text-right py-3 border-bottom-1 surface-border px-3">$85.00</td>
					<td class="text-right py-3 border-bottom-1 surface-border">$85.00</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-8">
		<div class="font-semibold mb-3 md:mb-0">NOTES</div>
		<div class="flex flex-column">
			<div class="flex justify-content-between align-items-center mb-2">
				<span class="font-semibold mr-6">SUBTOTAL</span>
				<span>$332.00</span>
			</div>
			<div class="flex justify-content-between align-items-center mb-2">
				<span class="font-semibold mr-6">VAT #</span>
				<span>0</span>
			</div>
			<div class="flex justify-content-between align-items-center">
				<span class="font-semibold mr-6">TOTAL</span>
				<span>$332.00</span>
			</div>
		</div>
	</div>
</div>