<div class="grid">
    <div class="col-12">
        <div class="card px-5 py-5 card-tabela">
            <p-table #dt [value]="registros" [lazy]="true" (onLazyLoad)="carregarRegistros($event)"
                [totalRecords]="totalRegistros" [loading]="carregando" [globalFilterFields]="['nomeCompleto','email']"
                [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} de {totalRecords} registros" [(selection)]="selecionados"
                selectionMode="single" [rowHover]="true" dataKey="id" styleClass="p-datatable-sm" [scrollable]="true"
                scrollHeight="flex">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <div class="my-2">
                            <a (click)="forcarAtualizacao()" pButton class="p-button-outlined" icon="pi pi-refresh"
                                label="Atualizar"></a>
                        </div>
                        <div class="my-2">
                            <button (click)="abrirPopupNovo()" pButton class="p-button-outlined" icon="pi pi-plus"
                                label="Novo usuário"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr class="text-center">
                        <th class="text-center">Ativo</th>
                        <th class="text-center" style="width: 400px">Nome</th>
                        <th class="text-center">E-mail</th>
                        <th class="text-center">Perfil de Acesso</th>
                        <th class="text-center">Celular</th>
                        <th class="text-center" style="width: 180px">Tipo Usuário</th>
                        <th class="text-center">Obs.</th>
                        <th style="width: 120px"></th>
                    </tr>
                    <tr>
                        <th class="text-center">
                            <p-columnFilter type="boolean" field="ativo" matchMode="equals" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="nomeCompleto" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="email" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="fornecedorDescricao" matchMode="contains"
                                [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="celular" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="int" field="tipoUsuario" matchMode="equals" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="tipoUsuario" optionValue="id" optionLabel="nome"
                                        (onChange)="filter($event.value)">
                                    </p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th colspan="2">
                            <p-columnFilter type="text" field="observacoes" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registro>
                    <tr>
                        <td class="text-center">
                            <span class="p-column-title">Ativo</span>
                            <p-inputSwitch [(ngModel)]="registro.ativo" [readonly]="true"></p-inputSwitch>
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Nome</span>
                            {{registro.nomeCompleto}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">E-mail</span>
                            {{registro.email}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Perfil</span>
                            {{registro.perfilAcessoNome}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Celular</span>
                            {{registro.celular}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Tipo Usuário</span>
                            {{registro.tipo | usuarioTipo}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Obs.</span>
                            {{registro.observacoes}}
                        </td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mr-2 p-button-sm"
                                    (click)="abrirPopupEdicao(registro)"></button>
                                <button pButton pRipple icon="pi pi-key"
                                    class="p-button-rounded p-button-warning mr-2 p-button-sm"
                                    (click)="resetarSenha($event, registro)"></button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger p-button-sm"
                                    (click)="abrirPopupExclusao(registro)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-usuario-edicao #edicao (onSucesso)="registroAlterado($event)"></app-usuario-edicao>
<app-usuario-exclusao #exclusao (onSucesso)="registroAlterado($event)"></app-usuario-exclusao>