<div style="display: flex">
    <div>
        <p-listbox [options]="tecnicos" [(ngModel)]="tecnicosSelecionados" optionLabel="nome" optionValue="id"
            [style]="{ width: '15rem' }" [filter]="true" [multiple]="true" [checkbox]="true" [showToggleAll]="true"
            [metaKeySelection]="false" [listStyle]="{ 'max-height': '720px' }" (onChange)="onTecnicosChange($event)">
            <ng-template let-tecnico pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div style="width:18px;height:18px;" [ngStyle]="{'background-color': tecnico.cor}">&nbsp;</div>
                    <div style="width:100px">{{ tecnico.nome }}</div>
                </div>
            </ng-template>
        </p-listbox>
    </div>

    <div style="flex-grow: 1; margin-left: 10px" *ngIf="configurado">
        <full-calendar #fc [options]="calendarOptions"></full-calendar>
    </div>
</div>

<p-contextMenu #cm [model]="items">
</p-contextMenu>

<app-agendamento-novo #popupNovo (onSucesso)="onNovo($event)"></app-agendamento-novo>