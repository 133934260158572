import { Component, OnInit, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { TarefaService } from 'src/app/services/tarefa.service';
import { TarefaEdicaoComponent } from '../tarefa-edicao/tarefa-edicao.component';
import { TarefaExclusaoComponent } from '../tarefa-exclusao/tarefa-exclusao.component';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-tarefa-view',
  templateUrl: './tarefa-view.component.html',
  styleUrls: ['./tarefa-view.component.scss']
})
export class TarefaViewComponent implements OnInit {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;

  @ViewChild(Table) dt?: Table;
  @ViewChild('exclusao') popupExclusao?: TarefaExclusaoComponent;

  constructor(
    private app: AppComponent,
    private tarefaService: TarefaService) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.tarefaService
      .listar({
        skip: event.first,
        take: event.rows,
        filtro: event.globalFilter,
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  imprimir() {
  }

  onPesquisar(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupExclusao(registro: any) {
    this.popupExclusao?.abrir(registro);
  }
}
