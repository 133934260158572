import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './layout/service/app.layout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    private timeoutTelaBloqueadaMs = 30000;

    telaBloqueada: boolean = false;
    timeout?: ReturnType<typeof setTimeout> = undefined;

    constructor(
        private primengConfig: PrimeNGConfig,
        private layoutService: LayoutService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;

        this.layoutService.config = {
            ripple: false,                      //toggles ripple on and off
            inputStyle: 'outlined',             //default style for input elements
            menuMode: 'horizontal',                 //layout mode of the menu, valid values are "static", "overlay", "slim", "horizontal", "reveal" and "drawer" 
            colorScheme: 'dim',               //color scheme of the template, valid values are "light", "dim" and "dark"
            theme: 'blue',                    //default component theme for PrimeNG
            menuTheme: "colorScheme",           //theme of the menu, valid values are "colorScheme", "primaryColor" and "transparent"
            scale: 14                           //size of the body font size to scale the whole application
        };

        this.primengConfig.setTranslation({
            "startsWith": "Starts with",
            "contains": "Contains",
            "notContains": "Not contains",
            "endsWith": "Ends with",
            "equals": "Equals",
            "notEquals": "Not equals",
            "noFilter": "No Filter",
            "lt": "Less than",
            "lte": "Less than or equal to",
            "gt": "Greater than",
            "gte": "Greater than or equal to",
            "is": "Is",
            "isNot": "Is not",
            "before": "Before",
            "after": "After",
            "dateIs": "Date is",
            "dateIsNot": "Date is not",
            "dateBefore": "Date is before",
            "dateAfter": "Date is after",
            "clear": "Clear",
            "apply": "Apply",
            "matchAll": "Match All",
            "matchAny": "Match Any",
            "addRule": "Add Rule",
            "removeRule": "Remove Rule",
            "accept": "Yes",
            "reject": "No",
            "choose": "Choose",
            "upload": "Upload",
            "cancel": "Cancel",
            "dayNames": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            "dayNamesMin": ["Do", "Se", "Te", "Qua", "Qui", "Se", "Sá"],
            "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            "dateFormat": "dd/mm/yy",
            "firstDayOfWeek": 0,
            "today": "Hoje",
            "weekHeader": "Sem",
            "weak": "Weak",
            "medium": "Medium",
            "strong": "Strong",
            "passwordPrompt": "Enter a password",
            "emptyMessage": "No results found",
            "emptyFilterMessage": "No results found"
        });
    }

    bloquearTela() {
        this.telaBloqueada = true;

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }

        this.timeout = setTimeout(() => {
            if (this.telaBloqueada) {
                this.telaBloqueada = false;
            }
        }, this.timeoutTelaBloqueadaMs);
    }

    desbloquearTela() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }

        if (this.telaBloqueada) {
            this.telaBloqueada = false;
        }
    }

    notificacaoSucesso(mensagem: string) {
        this.messageService.add({
            key: 'notificacao',
            severity: 'success',
            summary: mensagem
        });
    }
}
