<p-dialog [(visible)]="visivel" header="Exclusão" [modal]="true" >
    <app-validacoes [mensagens]="validacoes"></app-validacoes>

    <div class="flex align-items-center justify-content-center">

        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>Deseja realmente excluir o técnico <b>{{registro?.nomeCompleto}}</b>?</span>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-between flex-wrap">
            <button pButton pRipple icon="pi pi-times" class="p-button-text" label="Não" (click)="fechar()"></button>
            <button pButton pRipple icon="pi pi-check" class="p-button-danger p-button-text" label="Sim" (click)="confirmar()"></button>
        </div>
    </ng-template>
</p-dialog>