import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usuarioTipo'
})
export class UsuarioTipoPipe implements PipeTransform {

  transform(value: Number, ...args: unknown[]): string {
    switch (value) {
      case 1:
        return 'Analista';
      case 2:
        return 'Técnico';
      case 3:
        return 'Marcopolo';
    }
    
    return '';
  }

}
