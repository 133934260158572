import { Component, OnInit, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { QuestionarioService } from 'src/app/services/questionario.service';
import { QuestionarioEdicaoComponent } from '../questionario-edicao/questionario-edicao.component';
import { QuestionarioExclusaoComponent } from '../questionario-exclusao/questionario-exclusao.component';

@Component({
  selector: 'app-questionario-view',
  templateUrl: './questionario-view.component.html',
  styleUrls: ['./questionario-view.component.scss']
})
export class QuestionarioViewComponent implements OnInit {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;

  @ViewChild(Table) dt?: Table;
  @ViewChild('edicao') popupEdicao?: QuestionarioEdicaoComponent;
  @ViewChild('exclusao') popupExclusao?: QuestionarioExclusaoComponent;

  constructor(
    private questionarioService: QuestionarioService) {
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.questionarioService
      .listar({
        skip: event.first,
        take: event.rows,
        filtro: event.globalFilter,
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  imprimir() {
  }

  onPesquisar(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupExclusao(registro: any) {
     this.popupExclusao?.abrir(registro);
  }
}
