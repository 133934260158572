<div class="grid">
    <div class="col-12">
        <div class="card px-5 py-5">
            <p-table #dt [value]="registros" [lazy]="true" (onLazyLoad)="carregarRegistros($event)"
                [totalRecords]="totalRegistros" [loading]="carregando" responsiveLayout="scroll"
                [globalFilterFields]="['nomeCompleto','email']" [rows]="10" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} de {totalRecords} registros" [(selection)]="selecionados"
                selectionMode="single" [rowHover]="true" dataKey="id" styleClass="p-datatable-sm">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="onPesquisar(dt, $event)" placeholder="Pesquisar..."
                                class="w-full sm:w-auto" />
                        </span>
                        <div class="my-2">
                            <button [routerLink]="['/cadastros/clientes/novo']" pButton
                                class="p-button-outlined " icon="pi pi-plus" label="Novo cliente"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 100px" class="text-center">Ativo?</th>
                        <th>Razão social</th>
                        <th>Nome fantasia</th>
                        <th>Endereço</th>
                        <th>Responsáveis</th>
                        <th>Telefones</th>
                        <th>E-mails</th>
                        <th style="width: 120px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registro>
                    <tr>
                        <td class="text-center">
                            <span class="p-column-title " >Ativo</span>
                            <p-inputSwitch [(ngModel)]="registro.ativo" [disabled]="true"></p-inputSwitch>
                        </td>
                        <td>
                            <span class="p-column-title">Razão social</span>
                            {{registro.razaoSocial}}
                        </td>
                        <td>
                            <span class="p-column-title">Nome</span>
                            {{registro.nomeFantasia}}
                        </td>
                        <td>
                            <span class="p-column-title">Endereço</span>
                            {{registro.enderecoDescricao}}
                        </td>
                        <td>
                            <span class="p-column-title">Responsáveis</span>
                            {{registro.responsaveis}}
                        </td>
                        <td>
                            <span class="p-column-title">Telefones</span>
                            {{registro.telefones}}
                        </td>
                        <td>
                            <span class="p-column-title">E-mails</span>
                            {{registro.emails}}
                        </td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-sm p-button-primary mr-2"
                                    [routerLink]="['/cadastros/clientes/', registro.id]"></button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-sm p-button-danger "
                                    (click)="abrirPopupExclusao(registro)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-cliente-exclusao #exclusao (onSucesso)="registroAlterado($event)"></app-cliente-exclusao>