import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidacoesComponent } from './validacoes.component';
import { MessagesModule } from 'primeng/messages';



@NgModule({
  declarations: [
    ValidacoesComponent
  ],
  imports: [
    CommonModule,
    MessagesModule
  ],
  exports: [
    ValidacoesComponent
  ]
})
export class ValidacoesModule { }
