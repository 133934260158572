<p-dialog #edicao [(visible)]="visivel" header="Assinatura" [modal]="true" class="p-fluid" (onShow)="edicao.maximize()"
    [closable]="false" styleClass="modal-assinatura" (window:resize)="onResize($event)">
    <ng-template pTemplate="header">
    </ng-template>
    <ng-template pTemplate="content">
        <signature-pad #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)"
            (drawEnd)="drawComplete($event)"></signature-pad>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-center flex-wrap">
            <button pButton pRipple label="Fechar" icon="pi pi-times" class="p-button-danger p-button-text"
                (click)="fechar()"></button>
            <button pButton pRipple label="Limpar" icon="pi pi-eraser" class="p-button-warning p-button-text"
                (click)="limpar()"></button>
            <div class="flex justify-content-end flex-wrap">
                <button pButton pRipple label="Confirmar" icon="pi pi-check" class="p-button-primary p-button-text"
                    (click)="confirmar()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>