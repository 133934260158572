<div class="ml-3 mr-3 mt-3">
    <div class="p-fluid p-formgrid grid field-sm">
        <div class="field col-6">
            <label>{{pergunta.detalhesTitulo}} - Nome responsável</label>

            <input type="text" pInputText [(ngModel)]="pergunta.resposta.nome" [disabled]="!habilitado"
                (ngModelChange)="respostaAlterada()" />
        </div>

        <div class="field col-6">
            <label>{{pergunta.detalhesTitulo}} - Documento responsável</label>

            <input type="text" pInputText [(ngModel)]="pergunta.resposta.documento" [disabled]="!habilitado"
                (ngModelChange)="respostaAlterada()" />
        </div>
    </div>

    <div class="div-assinatura mb-2">
        <p-button (click)="abrirPopupAssinatura()" [disabled]="!habilitado">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'signature']" class="mr-2"></fa-icon>
            <span>{{pergunta.detalhesTitulo}}</span>
        </p-button>

    </div>

    <div class="mt-2">
        <img *ngIf="pergunta.resposta" style="background-color: white; border: black solid 2px; max-width: 400px"
            [src]="pergunta.resposta.imagemUrl" />
    </div>
</div>

<app-pergunta-assinatura-popup #edicao (onSucesso)="respostaAlterada()"></app-pergunta-assinatura-popup>