import { Component, OnInit, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { AppComponent } from 'src/app/app.component';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { AgendamentoExclusaoComponent } from '../agendamento-exclusao/agendamento-exclusao.component';
import { TokenStorageService } from 'src/app/services/autorizacao/token-storage.service';
import { environment } from 'src/environments/environment';
import { FilterMetadata } from 'primeng/api';

@Component({
  selector: 'app-agendamento-view',
  templateUrl: './agendamento-view.component.html',
  styleUrls: ['./agendamento-view.component.scss']
})
export class AgendamentoViewComponent implements OnInit {
  registros: any[] = [];
  selecionados: any[] = [];
  totalRegistros: number = 0;
  carregando: boolean = false;
  usuarioTipo: number;
  exportacaoUrl: string = '';
  situacoes: any[] = [{
    id: 0,
    nome: ''
  }, {
    id: 1,
    nome: 'Pendente'
  }, {
    id: 2,
    nome: 'Em Andamento'
  }, {
    id: 3,
    nome: 'Agendado'
  }, {
    id: 4,
    nome: 'No-Show Técnico'
  }, {
    id: 5,
    nome: 'Aguardando Validação'
  }, {
    id: 6,
    nome: 'Cancelado'
  }, {
    id: 7,
    nome: 'No-Show Cliente'
  }, {
    id: 8,
    nome: 'Serviço Finalizado'
  }, {
    id: 9,
    nome: 'Finalizado'
  }];
  
  tiposAgendamentos: any[] = [{
    id: 0,
    nome: ''
  },{
    id: 1,
    nome: 'Instalação'
  },{
    id: 2,
    nome: 'Manutenção'
  },{
    id: 3,
    nome: 'Desinstalação'
  }];


  @ViewChild(Table) dt?: Table;
  @ViewChild('exclusao') popupExclusao?: AgendamentoExclusaoComponent;

  constructor(
    private agendamentoService: AgendamentoService,
    private tokenStorage: TokenStorageService) {
    this.usuarioTipo = this.tokenStorage.getTokenDetalhes()?.usuarioTipo;
    this.exportacaoUrl = `${environment.apiUrl}/movimentacao/agendamento/exportar/${this.tokenStorage.getBearerToken()}`;
  }

  ngOnInit(): void {
    this.carregando = false;
    this.totalRegistros = 0;
  }

  carregarRegistros(event: TableLazyLoadEvent) {
    this.carregando = true;

    this.agendamentoService
      .listar({
        skip: event.first,
        take: event.rows,
        filtros: JSON.parse(JSON.stringify(event?.filters || this.dt?.filters || '{}'))
      })
      .subscribe(response => {
        this.totalRegistros = response.data.totalRegistros!;
        this.registros = response.data.registros!;
        this.carregando = false;
      });
  }

  registroAlterado(registro: any) {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }

  abrirPopupExclusao(registro: any) {
    this.popupExclusao?.abrir(registro);
  }

  obterUrlRelatorio(registro: any) {
    return `${environment.apiUrl}/movimentacao/agendamento/imprimir/${registro.token}/${registro.id}/Agendamento ${registro.id}`;
  }

  oldValue: any = null;
  setup(value: any) {
    if (value != this.oldValue) {
      this.oldValue = value;

      if (this.oldValue) {
        this.dt?.filter(value, 'dataHora', 'dateIs');
      } else {
        (this.dt!.filters['dataHora'] as FilterMetadata).value = null;
        this.carregarRegistros(this.dt?.createLazyLoadMetadata());
      }
    }
  }

  forcarAtualizacao() {
    this.carregarRegistros(this.dt?.createLazyLoadMetadata());
  }
}