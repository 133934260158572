<div class="grid">
    <div class="col-12">
        <div class="card px-5 py-5 card-tabela">
            <p-table #dt [value]="registros" [lazy]="true" (onLazyLoad)="carregarRegistros($event)"
                [totalRecords]="totalRegistros" [loading]="carregando" [globalFilterFields]="['nomeCompleto','email']"
                [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} até {last} de {totalRecords} registros" [(selection)]="selecionados"
                selectionMode="single" [rowHover]="true" dataKey="id" styleClass="p-datatable-sm" [scrollable]="true"
                scrollHeight="flex">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <div class="my-2">
                            <a (click)="forcarAtualizacao()" pButton class="p-button-outlined" icon="pi pi-refresh"
                                label="Atualizar"></a>
                        </div>
                        <div class="my-2" *ngIf="usuarioTipo==1">
                            <a target="_blank" [href]="exportacaoUrl" pButton class="p-button" icon="pi pi-file-excel"
                                label="Exportar"></a>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center" style="width: 100px">Cód.</th>
                        <th class="text-center" style="width: 120px">Status</th>
                        <th style="width: 120px"></th>
                        <th class="text-center">Cliente</th>
                        <th style="width: 120px"></th>
                        <th style="width: 120px"></th>
                        <th class="text-center" *ngIf="usuarioTipo!=2">Responsável</th>
                        <th style="width: 120px"></th>
                        <th style="width: 100px"></th>
                        <th class="text-center">Tipo Agendamento</th>
                        <th style="width: 120px"></th>
                        <th style="width: 80px"></th>
                        <th class="text-center">Data</th>
                        <th style="width: 120px"></th>
                        <th style="width: 80px"></th>
                        <th class="text-center">Obs.</th>
                        <th style="width: 120px"></th>
                        <th style="width: 200px"></th>
                        <th class="text-center">Endereço Atendimento</th>
                        <th style="width: 120px"></th>
                        <th style="width: 200px"></th>
                        <th class="text-center">Responsável Local</th>
                        <th style="width: 180px"></th>
                        <th style="width: 10px"></th>
                        <th class="text-center">Placa</th>
                        <th style="width: 120px"></th>
                        <th class="text-center">Sintoma</th>
                        <th style="width: 120px"></th>
                        <th class="text-center">Chamado</th>
                    </tr>
                    <tr>
                        <th>
                            <p-columnFilter type="text" field="id" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="1">
                            <p-columnFilter type="numeric" field="status" matchMode="equals" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="situacoes" optionValue="id" optionLabel="nome"
                                        appendTo="body" [autoDisplayFirst]="true" [filter]="false"
                                        (onChange)="filter($event.value)"></p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th colspan="3">
                            <p-columnFilter type="text" field="clienteDescricao" matchMode="contains"
                                [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="3">
                            <p-columnFilter type="text" field="responsavelNomeCompleto" matchMode="contains"
                                [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="3">
                            <p-columnFilter type="numeric" field="tipo" matchMode="equals" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="tiposAgendamentos" optionValue="id" optionLabel="nome"
                                        appendTo="body" [autoDisplayFirst]="true" [filter]="false"
                                        (onChange)="filter($event.value)"></p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th colspan="3">
                            <p-columnFilter type="date" field="dataHora" matchMode="dateIs" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-calendar #calendar [ngModel]="value" (onSelect)="filter(calendar.value)"
                                        (onInput)="setup(calendar.value)" dateFormat="dd/mm/yy"
                                        appendTo="body"></p-calendar>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th colspan="3">
                            <p-columnFilter type="text" field="descricao" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="4">
                            <p-columnFilter type="text" field="enderecoAtendimento" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="3">
                            <p-columnFilter type="text" field="responsavelLocal" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="2">
                            <p-columnFilter type="text" field="placa" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="2">
                            <p-columnFilter type="text" field="sintoma" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                        <th colspan="2">
                            <p-columnFilter type="text" field="chamado" matchMode="contains" [showMenu]="false">
                            </p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-registro>
                    <tr>
                        <td class="text-center">
                            <span class="p-column-title">Cód</span>
                            {{registro.id}}
                        </td>
                        <td class="text-center">
                            <span class="p-column-title">Status</span>
                            <app-tag-situacao-agendamento [situacao]="registro.statusAgendamento"></app-tag-situacao-agendamento>
                        </td>
                        <td class="text-center" colspan="3">
                            <span class="p-column-title">Cliente</span>
                            {{registro.clienteDescricao}}
                        </td>
                        <td class="text-center" *ngIf="usuarioTipo!=2" colspan="3">
                            <span class="p-column-title">Responsável</span>
                            {{registro.responsavelNomeCompleto}}
                        </td>
                        <td class="text-center" colspan="3">
                            <span class="p-column-title">Tipo Agendamento</span>
                            {{registro.tipoDescricao}}
                        </td>
                        <td class="text-center" colspan="3">
                            <span class="p-column-title">Data</span>
                            {{registro.dataHora | date:'short'}}
                        </td>
                        <td class="text-center" colspan="3">
                            <span class="p-column-title">Obs.</span>
                            {{registro.descricao}}
                        </td>
                        <td class="text-center" colspan="4">
                            <span class="p-column-title">Endereço Atendimento</span>
                            {{registro.enderecoAtendimento}}
                        </td>
                        <td class="text-center" colspan="3">
                            <span class="p-column-title">Responsável Local</span>
                            {{registro.responsavelLocal}}
                        </td>
                        <td class="text-center" colspan="2">
                            <span class="p-column-title">Placa</span>
                            {{registro.placa}}
                        </td>
                        <td class="text-center" colspan="2">
                            <span class="p-column-title">Sintoma</span>
                            {{registro.sintoma}}
                        </td>
                        <td class="text-center" colspan="2">
                            <span class="p-column-title">Chamado</span>
                            {{registro.chamado}}
                        </td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mr-2 p-button-sm"
                                    [routerLink]="['/agendamentos/', registro.id]"></button>
                                <a pButton pRipple icon="pi pi-print"
                                    class="p-button-rounded p-button-secondary p-button-sm"
                                    [href]="obterUrlRelatorio(registro)" target="_blank"></a>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-agendamento-exclusao #exclusao (onSucesso)="registroAlterado($event)"></app-agendamento-exclusao>