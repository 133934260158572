import { Component, OnInit } from '@angular/core';
import { RedeAutorizadaService } from 'src/app/services/rede-autorizada.service';
import { AppComponent } from 'src/app/app.component';
import * as L from "leaflet";
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { NominatimService } from 'src/app/services/nominatim.service';

@Component({
  selector: 'app-rede-autorizada',
  templateUrl: './rede-autorizada.component.html',
  styleUrls: ['./rede-autorizada.component.scss'],
})
export class RedeAutorizadaComponent {
  tecnicos: any[] = [];
  options: any = {};
  latlngs: any[] = [];
  markers: any[] = [];
  mapaTecnicos: L.Map | undefined;
  suggestions: any[] = [];
  latLongs: any = [];

  constructor(
    private app: AppComponent,
    private redeAutorizadaService: RedeAutorizadaService,
    private nominatimService: NominatimService,) { }

  ngOnInit(): void {
    this.app.bloquearTela();

    this.options = {
      layers: [
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: '...'
        })
      ],
      zoom: 8,
      center: L.latLng(-12, -50)
    }

    this.redeAutorizadaService
      .listartecnicos({
        skip: 0,
        take: 1000
      })
      .subscribe(response => {
        this.configurarTecnicos(response.data!.registros!);
      });

  }

  configurarTecnicos(registros: any[]) {
    setTimeout(() => {
      for (let tecnico of registros) {

        if (tecnico.enderecoPrincipal != null && tecnico.enderecoPrincipal.latitude != null && tecnico.enderecoPrincipal.longitude != null) {
          this.markers.push(L.marker([tecnico.enderecoPrincipal.latitude, tecnico.enderecoPrincipal.longitude], {
            icon: L.icon({
              ...L.Icon.Default.prototype.options,
              iconUrl: 'assets/marker-icon.png',
              iconRetinaUrl: 'assets/marker-icon-2x.png',
              shadowUrl: 'assets/marker-shadow.png'
            })
          }).bindPopup(`<strong>Técnico:</strong> ${tecnico.razaoSocial}<br/>
            <strong>Endereço Cadastro:</strong> ${tecnico.enderecoPrincipal.logradouro ?? ""} ${tecnico.enderecoPrincipal.cidade} - ${tecnico.enderecoPrincipal.estado}<br/>
            <strong>Endereço Entrega:</strong> ${tecnico.enderecoEntrega.logradouro ?? ""} ${tecnico.enderecoEntrega.cidade} - ${tecnico.enderecoEntrega.estado}<br/>
            <strong>Celular:</strong> ${tecnico.celular}<br/>
            <strong>Observação:</strong> ${tecnico.observacoes}
            <p-speedDial [model]="items" direction="up"></p-speedDial>`),
            L.circle([tecnico.enderecoPrincipal.latitude, tecnico.enderecoPrincipal.longitude], { radius: tecnico.raioAtuacao * 1000 })
          )
        }

        if (tecnico.raioAtuacao && tecnico.raioAtuacao > 0) {
          if (tecnico.enderecoPrincipal != null && tecnico.enderecoPrincipal.latitude != null && tecnico.enderecoPrincipal.longitude != null) {
            this.markers.push(L.circle([tecnico.enderecoPrincipal.latitude, tecnico.enderecoPrincipal.longitude], { radius: tecnico.raioAtuacao * 1000 }));
          }
        }
      }

      for (let marker of this.markers) {
        this.latLongs.push(marker._latlng);
      }

      this.mapaTecnicos!.fitBounds(this.latLongs, { maxZoom: 10 });
    }, 1000);

    this.app.desbloquearTela();

  }

  onMapaPronto(map: L.Map) {
    this.mapaTecnicos = map;
  }

  onSelecao(event: any) {
    this.mapaTecnicos!.flyTo([event.latitude, event.longitude], 10, { duration: 1 });
  }

  limpaBusca() {
    this.mapaTecnicos!.fitBounds(this.latLongs, { maxZoom: 10 });
  }

  centralizar(){
    this.mapaTecnicos!.fitBounds(this.latLongs, { maxZoom: 10 })
  }

}
