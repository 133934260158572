import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { bo } from '@fullcalendar/core/internal-common';
import { cloneDeep } from 'lodash';
import { OrderList } from 'primeng/orderlist';
import { AppComponent } from 'src/app/app.component';
import { QuestionarioService } from 'src/app/services/questionario.service';

@Component({
  selector: 'app-questionario-edicao',
  templateUrl: './questionario-edicao.component.html',
  styleUrls: ['./questionario-edicao.component.scss']
})
export class QuestionarioEdicaoComponent implements OnInit {
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  formCarregado: boolean = false;

  tiposResposta: any[] = [{
    key: 1,
    value: 'Texto'
  }, {
    key: 2,
    value: 'Númerico'
  }, {
    key: 3,
    value: 'Verdadeiro/Falso'
  }, {
    key: 4,
    value: 'Assinatura'
  }, {
    key: 5,
    value: 'Foto'
  }, {
    key: 6,
    value: 'Data'
  }, {
    key: 7,
    value: 'Hora'
  }, {
    key: 8,
    value: 'Data e hora'
  }, {
    key: 9,
    value: 'Lista de opções (escolha única)'
  }, {
    key: 10,
    value: 'Lista de opções (míltipla escolha)'
  }].sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

  @ViewChild('listaPerguntas') listaPerguntas?: OrderList;

  get id() { return this.form.get('id')!; }
  get ativo() { return this.form.get('ativo')!; }
  get nome() { return this.form.get('nome')!; }
  get mensagemRodape() { return this.form.get('mensagemRodape')!; }
  get mensagemCabecalho() { return this.form.get('mensagemCabecalho')!; }
  get incluirRodape() { return this.form.get('incluirRodape')!; }
  get incluirCabecalho() { return this.form.get('incluirCabecalho')!; }
  get exibirRelatorioCliente() { return this.form.get('exibirRelatorioCliente')!; }
  get perguntas() { return this.form.get('perguntas')! as FormArray; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent,
    private questionarioService: QuestionarioService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      ativo: new FormControl(true),
      nome: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      mensagemRodape: new FormControl(),
      mensagemCabecalho: new FormControl(),
      incluirRodape: new FormControl(),
      incluirCabecalho: new FormControl(),
      exibirRelatorioCliente: new FormControl(),
      perguntas: new FormArray([])
    });

    if (this.route.snapshot.paramMap.get('id')) {
      this.questionarioService
        .obter(Number(this.route.snapshot.paramMap.get('id')))
        .subscribe(response => {
          this.abrir(response.data!);
        });
    } else {
      this.abrir({
        id: null,
        ativo: true,
        nome: '',
        mensagemRodape: null,
        mensagemCabecalho: null,
        incluirRodape: false,
        incluirCabecalho: false,
        exibirRelatorioCliente: true,
        opcoes: null,
        perguntas: []
      });
    }
  }

  voltar() {
    this.router.navigate(['/cadastros/questionarios']);
  }

  abrir(registro: any) {
    this.registro = registro;
    this.form.reset();

    this.form.patchValue({
      id: registro.id,
      ativo: registro.ativo,
      nome: registro.nome,
      mensagemRodape: registro.mensagemRodape,
      mensagemCabecalho: registro.mensagemCabecalho,
      incluirRodape: registro.incluirRodape,
      incluirCabecalho: registro.incluirCabecalho,
      exibirRelatorioCliente: registro.exibirRelatorioCliente,
    });

    for (const pergunta of registro.perguntas) {
      this.perguntas.push(new FormGroup({
        id: new FormControl(pergunta.id),
        descricao: new FormControl(pergunta.descricao),
        obrigatorio: new FormControl(pergunta.obrigatorio),
        tipo: new FormControl(pergunta.tipo),
        titulo: new FormControl(pergunta.titulo),
        mostrarDescricao: new FormControl(pergunta.mostrarDescricao),
        opcoes: new FormControl(pergunta.opcoes),
      }));
    }

    this.formCarregado = true;
  }

  confirmar() {
    this.app.bloquearTela();
    this.validacoes = [];

    const registro = cloneDeep(this.form.value);

    for (const pergunta of registro.perguntas) {
      pergunta.ordem = registro.perguntas.indexOf(pergunta);
    }

    this.questionarioService
      .salvar(registro)
      .subscribe(response => {
        this.app.desbloquearTela();

        if (response.sucesso) {
          this.voltar();
          this.onSucesso.emit(response.data);
        } else {
          this.validacoes = response.mensagens!;
        }
      });
  }

  adicionarPergunta() {
    this.perguntas.push(new FormGroup({
      id: new FormControl(),
      descricao: new FormControl(),
      obrigatorio: new FormControl(false),
      tipo: new FormControl(1),
      titulo: new FormControl(),
      mostrarDescricao: new FormControl(false),
    }));
  }

  removerPergunta(index: number) {
    this.perguntas.removeAt(index);
  }

  onSelectionChange(event: any) {
    this.listaPerguntas!.selection = []
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.perguntas.controls, event.previousIndex, event.currentIndex);
  }
}
