import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-cliente-edicao-contato',
  templateUrl: './cliente-edicao-contato.component.html',
  styleUrls: ['./cliente-edicao-contato.component.scss']
})
export class ClienteEdicaoContatoComponent implements OnInit {
  visivel: boolean = false;
  registro: any = {};
  validacoes: string[] = [];
  form!: FormGroup;
  perfisAcesso: any[] = [];

  get id() { return this.form.get('id')!; }
  get nome() { return this.form.get('nome')!; }
  get email() { return this.form.get('email')!; }
  get telefone() { return this.form.get('telefone')!; }
  get cargo() { return this.form.get('cargo')!; }

  @Output()
  onSucesso: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private app: AppComponent) {
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      nome: new FormControl(
        '', [
        Validators.required,
        Validators.minLength(4)
      ]),
      email: new FormControl('', [
        Validators.email
      ]),
      telefone: new FormControl(),
      cargo: new FormControl(),
    });
  }

  fechar() {
    this.visivel = false;
    this.registro = {};
    this.validacoes = [];
    this.form.reset();
  }

  abrir(registro: any) {
    this.visivel = true;
    this.registro = registro;
    this.form.reset();
    this.form.setValue(registro);
  }

  confirmar() {
    Object.assign(this.registro, this.form.value);
    this.onSucesso.emit(this.registro);
    this.fechar();
  }
}
