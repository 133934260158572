<ng-container>
    <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text">
        <a (click)="itemClick($event)" [ngClass]="item.class" [routerLink]="item.routerLink" [fragment]="item.fragment"
            [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment"
            [skipLocationChange]="item.skipLocationChange" [replaceUrl]="item.replaceUrl" [state]="item.state"
            [queryParams]="item.queryParams" [attr.target]="item.target" tabindex="0" pRipple [pTooltip]="item.label"
            [tooltipDisabled]="!(isSlim && root)" style="color:white">
            <fa-icon [fixedWidth]="true" [icon]="item.icon" class="layout-menuitem-icon"></fa-icon>
            <span class="layout-menuitem-text ml-2">{{ item.label }}</span>
        </a>
    </div>
    <a *ngIf="(!item.routerLink || item.items) && item.visible !== false" [attr.href]="item.url"
        (click)="itemClick($event)" (mouseenter)="onMouseEnter()" [ngClass]="item.class" [attr.target]="item.target"
        tabindex="0" pRipple [pTooltip]="item.label" [tooltipDisabled]="!(isSlim && root && !active)">
        <fa-icon [fixedWidth]="true" [icon]="item.icon" class="layout-menuitem-icon"></fa-icon>
        <span class="layout-menuitem-text">{{ item.label }}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    </a>
    <a *ngIf="item.routerLink && !item.items && item.visible !== false" (click)="itemClick($event)"
        (mouseenter)="onMouseEnter()" [ngClass]="item.class" [routerLink]="item.routerLink"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
        [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment" [skipLocationChange]="item.skipLocationChange"
        [replaceUrl]="item.replaceUrl" [state]="item.state" [queryParams]="item.queryParams" [attr.target]="item.target"
        tabindex="0" pRipple [pTooltip]="item.label" [tooltipDisabled]="!(isSlim && root)">
        <fa-icon [fixedWidth]="true" [icon]="item.icon" class="layout-menuitem-icon"></fa-icon>
        <span class="layout-menuitem-text">{{ item.label }}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    </a>

    <ul #submenu *ngIf="item.items && item.visible !== false" [@children]="submenuAnimation"
        (@children.done)="onSubmenuAnimated($event)">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
    </ul>
</ng-container>