import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { RedirecionarNaoAutorizadoService } from 'src/app/services/autorizacao/redirecionar-nao-autorizado.service';
import { PerfilAcessoViewComponent } from './components/configuracoes/perfil-acesso/perfil-acesso-view/perfil-acesso-view.component';
import { AgendamentosDashboardViewComponent } from './components/agendamentos/agendamentos-dashboard-view/agendamentos-dashboard-view.component';
import { UsuarioViewComponent } from './components/configuracoes/usuario/usuario-view/usuario-view.component';
import { ClienteViewComponent } from './components/cadastros/cliente/cliente-view/cliente-view.component';
import { ClienteEdicaoComponent } from './components/cadastros/cliente/cliente-edicao/cliente-edicao.component';
import { QuestionarioViewComponent } from './components/cadastros/questionario/questionario-view/questionario-view.component';
import { QuestionarioEdicaoComponent } from './components/cadastros/questionario/questionario-edicao/questionario-edicao.component';
import { TarefaViewComponent } from './components/cadastros/tarefas/tarefa-view/tarefa-view.component';
import { TarefaEdicaoComponent } from './components/cadastros/tarefas/tarefa-edicao/tarefa-edicao.component';
import { AgendamentoViewComponent } from './components/agendamentos/agendamento-view/agendamento-view.component';
import { AgendamentoEdicaoComponent } from './components/agendamentos/agendamento-edicao/agendamento-edicao.component';
import { AgendamentoRptClienteComponent } from './components/agendamentos/agendamento-rpt-cliente/agendamento-rpt-cliente.component';
import { TecnicoViewComponent } from './components/cadastros/tecnicos/tecnico-view/tecnico-view.component';
import { TecnicoEditComponent } from './components/cadastros/tecnicos/tecnico-edit/tecnico-edit.component';
import { RedeAutorizadaComponent } from './components/rede-autorizada/rede-autorizada.component';
import { ServicoEditComponent } from './components/cadastros/servicos/servico-edit/servico-edit.component';
import { ServicoViewComponent } from './components/cadastros/servicos/servico-view/servico-view.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        resolve: [RedirecionarNaoAutorizadoService],
        children: [
            { path: '', data: { breadcrumb: 'Agenda' }, component: AgendamentosDashboardViewComponent },
            { path: 'configuracoes/perfil-acesso', data: { breadcrumb: 'Perfis de acesso' }, component: PerfilAcessoViewComponent },
            { path: 'configuracoes/usuarios', data: { breadcrumb: 'Usuários' }, component: UsuarioViewComponent },
            { path: 'cadastros/clientes', data: { breadcrumb: 'Clientes' }, component: ClienteViewComponent },
            { path: 'cadastros/clientes/novo', data: { breadcrumb: 'Clientes' }, component: ClienteEdicaoComponent },
            { path: 'cadastros/clientes/:id', data: { breadcrumb: 'Clientes' }, component: ClienteEdicaoComponent },
            { path: 'cadastros/questionarios', data: { breadcrumb: 'Questionários' }, component: QuestionarioViewComponent },
            { path: 'cadastros/questionarios/novo', data: { breadcrumb: 'Questionários' }, component: QuestionarioEdicaoComponent },
            { path: 'cadastros/questionarios/:id', data: { breadcrumb: 'Questionários' }, component: QuestionarioEdicaoComponent },
            { path: 'cadastros/tarefas', data: { breadcrumb: 'Tarefas' }, component: TarefaViewComponent },
            { path: 'cadastros/tarefas/novo', data: { breadcrumb: 'Tarefas' }, component: TarefaEdicaoComponent },
            { path: 'cadastros/tarefas/:id', data: { breadcrumb: 'Tarefas' }, component: TarefaEdicaoComponent },
            { path: 'agendamentos', data: { breadcrumb: 'Agendamentos' }, component: AgendamentoViewComponent },
            { path: 'agendamentos/:id', data: { breadcrumb: 'Agendamentos' }, component: AgendamentoEdicaoComponent },
            { path: 'agendamentos/imprimir/:token/:id', data: { breadcrumb: 'Agendamentos' }, component: AgendamentoRptClienteComponent },
            { path: 'cadastros/tecnicos', data: { breadcrumb: 'Técnicos' }, component: TecnicoViewComponent },
            { path: 'cadastros/tecnicos/novo', data: { breadcrumb: 'Técnicos' }, component: TecnicoEditComponent },
            { path: 'cadastros/tecnicos/:id', data: { breadcrumb: 'Técnicos' }, component: TecnicoEditComponent },
            { path: 'redeautorizada', data: { breadcrumb: 'Rede Autorizada' }, component: RedeAutorizadaComponent },
            { path: 'cadastros/servicos', data: { breadcrumb: 'Serviços' }, component: ServicoViewComponent },
            { path: 'cadastros/servicos/novo', data: { breadcrumb: 'Serviços' }, component: ServicoEditComponent },
            { path: 'cadastros/servicos/:id', data: { breadcrumb: 'Serviços' }, component: ServicoEditComponent },

            { path: 'uikit', data: { breadcrumb: 'UI Kit' }, loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
            { path: 'utilities', data: { breadcrumb: 'Utilities' }, loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
            { path: 'pages', data: { breadcrumb: 'Pages' }, loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) },
            { path: 'profile', data: { breadcrumb: 'User Management' }, loadChildren: () => import('./demo/components/profile/profile.module').then(m => m.ProfileModule) },
            { path: 'documentation', data: { breadcrumb: 'Documentation' }, loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
            { path: 'blocks', data: { breadcrumb: 'Prime Blocks' }, loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
            { path: 'ecommerce', data: { breadcrumb: 'E-Commerce' }, loadChildren: () => import('./demo/components/ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
            { path: 'apps', data: { breadcrumb: 'Apps' }, loadChildren: () => import('./demo/components/apps/apps.module').then(m => m.AppsModule) }
        ]
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
