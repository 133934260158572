import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
  selector: 'app-agendamento-rpt-cliente',
  templateUrl: './agendamento-rpt-cliente.component.html',
  styleUrls: ['./agendamento-rpt-cliente.component.scss']
})
export class AgendamentoRptClienteComponent implements OnInit {
  constructor(private layoutService: LayoutService) {
  }

  ngOnInit(): void {
    this.layoutService.esconderTodosMenus = true;
  }

}
