import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TokenStorageService } from '../services/autorizacao/token-storage.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(private tokenStorage: TokenStorageService) {
    }

    ngOnInit() {
        const detalhes = this.tokenStorage.getTokenDetalhes();

        this.model = [
            {
                label: 'Agenda',
                icon: ['fas', 'calendar-days'],
                routerLink: ['']
            }, {
                label: 'Agendamentos',
                icon: ['fas', 'calendar-check'],
                routerLink: ['agendamentos']
            }, {
                label: 'Rede Autorizada',
                icon: ['fas', 'map'],
                routerLink: ['redeautorizada']
            }, {
                label: 'Cadastros',
                icon: ['fas', 'file-pen'],
                items: [
                    {
                        label: 'Clientes',
                        icon: ['fas', 'building-user'],
                        routerLink: ['cadastros/clientes']
                    },
                    {
                        label: 'Questionários',
                        icon: ['fas', 'clipboard-question'],
                        routerLink: ['cadastros/questionarios']
                    },
                    {
                        label: 'Tarefas',
                        icon: ['fas', 'calendar-days'],
                        routerLink: ['cadastros/tarefas']
                    },
                    {
                        label: 'Técnicos',
                        icon: ['fas', 'briefcase'],
                        routerLink: ['cadastros/tecnicos']
                    },
                    {
                        label: 'Serviços',
                        icon: ['fas', 'wrench'],
                        routerLink: ['cadastros/servicos']
                    }
                ],
                visible: detalhes && detalhes.usuarioTipo == 1
            }, {
                label: 'Configurações de acesso',
                icon: ['fas', 'user-gear'],
                items: [
                    {
                        label: 'Perfis de acesso',
                        icon: ['fas', 'user-shield'],
                        routerLink: ['configuracoes/perfil-acesso']
                    },
                    {
                        label: 'Usuários',
                        icon: ['fas', 'id-badge'],
                        routerLink: ['configuracoes/usuarios']
                    }
                ],
                visible: detalhes && detalhes.usuarioTipo == 1
            }
        ];
    }
}
