<p-dialog #edicao [(visible)]="visivel" [style]="{width: '50vw'}" header="Contato" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <app-validacoes [mensagens]="validacoes"></app-validacoes>

        <form [formGroup]="form" autocomplete="off" class="mt-2">
            <input autocomplete="false" name="hidden" type="text" class="hidden">

            <div class="p-fluid p-formgrid grid field-sm">

                <div class="field col-6">
                    <label for="nome">Nome</label>
                    <input type="text" pInputText id="nome" formControlName="nome" autofocus />
                    <small class="ng-dirty ng-invalid" *ngIf="nome.invalid && (nome.dirty || nome.touched)">
                        Nome deve ter pelo menos 4 caracteres</small>
                </div>

                <div class="field col-6">
                    <label for="email">E-mail</label>
                    <input type="email" pInputText id="email" formControlName="email">
                </div>

                <div class="field col-6">
                    <label for="telefone">Telefone</label>
                    <input type="text" pInputText id="telefone" formControlName="telefone">
                </div>

                <div class="field col-6">
                    <label for="cargo">Cargo</label>
                    <input type="text" pInputText id="cargo" formControlName="cargo">
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-between flex-wrap">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-warning p-button-text"
                (click)="fechar()"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-primary p-button-text"
                (click)="confirmar()" [disabled]="!form.valid"></button>
        </div>
    </ng-template>
</p-dialog>